import { FeatureSet, NetworkEntity, TFeatureSets, TNetworkEntity } from '../../shared/typings/interfaces/network-sidenav.interface';
import { PartnerTypes, TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { INavItem } from './../../shell/side-nav/side-nav.config';
import { ISidebarItem } from './../../../nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
import { ActivatedRoute } from '@angular/router';
import { TValidSchoolTypeCats } from 'Src/ng2/shared/typings/interfaces/school.interface';

export interface ISmallMenuNavItem {
  key: string;
  humanName: string;
  stateName?: string;
}

/*
  schoolTypes and districts are set to empty arrays because they are not being used for the Network side nav.
  Instead, we are using a 'feature_set' value sent by Data to determine which features a user has access to
*/
export const TopSectionSidebarOptions: INavItem[] = [
  {
    key: 'NETWORK_DASHBOARD',
    humanName: 'Home',
    url: '../dashboard',
    order: 1,
    isActive: true,
    schoolTypes: [],
    districts: [],
    featureSet: [FeatureSet.FULL_SET, FeatureSet.PARTIAL_1, FeatureSet.PARTIAL_2, FeatureSet.PARTIAL_3],
    leftDefaultIcon: 'home-default',
    leftSelectedIcon: 'home-selected',
  },
  {
    key: 'NETWORK_GRID',
    humanName: 'Data Grid',
    url: '../grid',
    order: 1,
    isActive: true,
    schoolTypes: [],
    districts: [],
    featureSet: [FeatureSet.FULL_SET, FeatureSet.PARTIAL_1, FeatureSet.PARTIAL_2],
    leftDefaultIcon: 'data-grid-default',
    leftSelectedIcon: 'data-grid-selected',
  },
];

export const MiddleSectionSidebarOptions: INavItem[] = [
  {
    key: 'ACADEMICS',
    humanName: 'Academics',
    url: '../midlevel',
    order: 0,
    isActive: true,
    schoolTypes: ['all'],
    districts: [],
    entityType: [NetworkEntity.NYC_SCHOOL],
    queryParams: {
      selectedCategory: 'ACADEMICS',
    },
  },
  {
    key: 'ECFIK_ELIGIBLE_STUDENTS',
    humanName: 'Eligible Students',
    url: '../midlevel',
    order: 0,
    isActive: true,
    schoolTypes: ['all'],
    districts: [],
    entityType: [NetworkEntity.ECFIK],
    queryParams: {
      selectedCategory: 'ECFIK_ELIGIBLE_STUDENTS',
    },
  },
  {
    key: 'ATTENDANCE',
    humanName: 'Attendance',
    order: 1,
    isActive: true,
    schoolTypes: ['all'],
    districts: [],
    entityType: [NetworkEntity.NYC_SCHOOL, NetworkEntity.ECFIK, NetworkEntity.LANSING, NetworkEntity.NYC_SHELTER],
    children: [
      {
        key: 'ATTENDANCE_BREAKDOWN',
        humanName: 'Breakdown',
        url: '../midlevel',
        order: 0,
        isActive: true,
        schoolTypes: ['all'],
        districts: [],
        entityType: [NetworkEntity.NYC_SCHOOL, NetworkEntity.ECFIK, NetworkEntity.LANSING, NetworkEntity.NYC_SHELTER],
        queryParams: {
          selectedCategory: 'ATTENDANCE',
        },
      },
      {
        key: 'ATTENDANCE_TRENDS',
        humanName: 'Attendance Trends',
        order: 1,
        isActive: true,
        schoolTypes: [],
        districts: [],
        featureSet: [FeatureSet.FULL_SET, FeatureSet.PARTIAL_2],
        url: '../attendance',
        isExternalUrl: false,
      },
    ],
  },
  {
    key: 'ECFIK_PROGRAM_MONITORING',
    humanName: 'Program Monitoring',
    url: '../midlevel',
    order: 2,
    isActive: true,
    schoolTypes: ['all'],
    districts: [],
    entityType: [NetworkEntity.ECFIK],
    queryParams: {
      selectedCategory: 'ECFIK_PROGRAM_MONITORING',
    },
  },
  {
    key: 'CREDITS',
    humanName: 'Credits',
    url: '../midlevel',
    order: 2,
    isActive: true,
    schoolTypes: ['hs'],
    districts: [],
    entityType: [NetworkEntity.NYC_SCHOOL, NetworkEntity.LANSING],
    queryParams: {
      selectedCategory: 'CREDITS',
    },
  },
  {
    key: 'GRADUATION',
    humanName: 'Graduation',
    url: '../midlevel',
    order: 3,
    isActive: true,
    schoolTypes: ['hs'],
    districts: [],
    entityType: [NetworkEntity.NYC_SCHOOL],
    queryParams: {
      selectedCategory: 'GRADUATION',
    },
  },
  {
    key: 'REGENTS',
    humanName: 'Regents',
    url: '../midlevel',
    order: 4,
    isActive: true,
    schoolTypes: ['hs'],
    districts: [],
    entityType: [NetworkEntity.NYC_SCHOOL],
    queryParams: {
      selectedCategory: 'REGENTS',
    },
  },
  {
    key: 'SCREENERS',
    humanName: 'Screeners',
    url: '../midlevel',
    order: 5,
    isActive: true,
    schoolTypes: ['all'],
    districts: [],
    entityType: [NetworkEntity.NYC_SCHOOL, NetworkEntity.LANSING],
    queryParams: {
      selectedCategory: 'SCREENERS',
    },
  },
  {
    key: 'STUDENTS',
    humanName: 'Students',
    url: '../midlevel',
    order: 6,
    isActive: true,
    schoolTypes: ['all'],
    districts: [],
    entityType: [NetworkEntity.NYC_SCHOOL, NetworkEntity.LANSING, NetworkEntity.NYC_SHELTER],
    queryParams: {
      selectedCategory: 'STUDENTS',
    },
  },
];

export const UserMenuOptions: ISmallMenuNavItem[] = [
  {
    key: 'getHelp',
    humanName: 'Get Help',
    stateName: null,
  },
  {
    key: 'privacyAndTerms',
    humanName: 'Privacy & Terms',
    stateName: 'privacy-and-terms',
  },
];

export const getUserMenuOptions = (canViewNetworkSettings: boolean): ISmallMenuNavItem[] => {
  const allowedUserMenuOptions = [...UserMenuOptions];
  if (canViewNetworkSettings) {
    allowedUserMenuOptions.unshift({
      key: 'networkSettings',
      humanName: 'User Management',
      stateName: null,
    });
  }
  return allowedUserMenuOptions;
};

/**
 * A function that filter Network features based on feature set field provided by data team
 * @param featureSet TFeatureSets
 * @param clusterId string
 * @param route ActivatedRoute
 * @returns ISidebarItem[] to be used for nv-sidebar-list
 */
export const getTopSectionSidebarOptions = (
  featureSet: TFeatureSets,
  clusterId: string,
  route: ActivatedRoute,
): ISidebarItem[] => {
  const optionsCopy = [...TopSectionSidebarOptions];
  const filteredOptions = validate(optionsCopy, { featureSet });

  return shapeNavItems(filteredOptions, {
    contextPartnerId: clusterId,
    contextPartnerType: PartnerTypes.SCHOOL_NETWORK,
    route,
  });
};

/**
 * A function that filter content area options based on the user's entity type
 * and school types available in the user's portfolio
 * @param featureSet TFeatureSets
 * @param userSchoolTypes TValidSchoolTypeCats
 * @param clusterType TNetworkEntity
 * @param clusterId string
 * @param route ActivatedRoute
 * @returns ISidebarItem[] to be used for nv-sidebar-list
 */
export const getMiddleSectionSidebarOptions = (
  featureSet: TFeatureSets,
  userSchoolTypes: TValidSchoolTypeCats[],
  clusterType: TNetworkEntity,
  clusterId: string,
  route: ActivatedRoute,
): ISidebarItem[] => {
  const optionsCopy = [...MiddleSectionSidebarOptions];
  const filteredOptions = validate(optionsCopy, { userSchoolTypes, clusterType, featureSet });

  return shapeNavItems(filteredOptions, {
    contextPartnerId: clusterId,
    contextPartnerType: PartnerTypes.SCHOOL_NETWORK,
    route,
  });
};

// helper functions
const isIncludedInFeatureSet = (navItem: INavItem, featureSet: TFeatureSets): boolean => {
  const hasNoFeatureSet = !navItem?.featureSet;
  return !featureSet || hasNoFeatureSet || navItem.featureSet.includes(featureSet);
};

const hasCorrectSchoolType = (navItem: INavItem, userSchoolTypes: TValidSchoolTypeCats[] = []): boolean => {
  const { schoolTypes } = navItem;
  const hasNoSchoolTypes = !schoolTypes || schoolTypes.length === 0;
  const isAllSchoolType = navItem.schoolTypes.includes('all');
  const hasPermittedSchoolTypes = userSchoolTypes.some(schoolType => {
    return navItem.schoolTypes.includes(schoolType.toLowerCase() as TValidSchoolTypeCats);
  });
  return hasNoSchoolTypes || isAllSchoolType || hasPermittedSchoolTypes;
};

const isAllowedForClusterType = (navItem: INavItem, clusterType: TNetworkEntity): boolean => {
  const hasNoEntityType = !navItem?.entityType || navItem?.entityType.length === 0;
  return !clusterType || hasNoEntityType || navItem?.entityType.includes(clusterType);
};

/**
 * Validates and filters the given NavItem options by checking a set of conditionals
 * @param options INavItem[]
 * @param opts contains various data that applies only if included within this object
 * @returns a filtered INavItem[]
 */
const validate = (
  options: INavItem[],
  opts: {
    userSchoolTypes?: TValidSchoolTypeCats[],
    featureSet?: TFeatureSets,
    clusterType?: TNetworkEntity,
  },
): INavItem[] => {
  const { userSchoolTypes, featureSet, clusterType } = opts;
  const validOptions = options.filter((option: INavItem) => {
    if (option.children) {
      const validChildren = validate(option.children, opts);
      option.children = validChildren;
    }
    return (
      isIncludedInFeatureSet(option, featureSet) &&
      isAllowedForClusterType(option, clusterType) &&
      hasCorrectSchoolType(option, userSchoolTypes)
    );
  });
  return validOptions;
};

/**
 * A function that flattens a navItem with only 1 child unless skipUnwrap is set to true
 * @param item ISidebarItem
 * @returns ISidebarItem
 */
const unwrapSingleChildren = (item: ISidebarItem): ISidebarItem => {
  const { children, human, key, leftDefaultIcon, leftSelectedIcon, skipUnwrap } = item;
  if (skipUnwrap) return item;
  if (children?.length === 1) {
    return { ...children[0], human, key, leftDefaultIcon, leftSelectedIcon };
  } else {
    return item;
  }
};

export const shapeNavItems = (
  configs: INavItem[],
  opts: { contextPartnerId: string, contextPartnerType: TValidPartnerTypes, route: ActivatedRoute },
): ISidebarItem[] => {
  const { contextPartnerId, contextPartnerType, route } = opts;
  const shapedItems = configs.map(item => {
    const { humanName, key, url, queryParams, children, hasBetaFlag, leftDefaultIcon, leftSelectedIcon, queryParamsToHash } = item;
    const formattedChildren = children
      ? shapeNavItems(children, { contextPartnerId, contextPartnerType, route })
      : null;
    const expandAs = children ? ('accordion' as const) : null;
    const calculatedUrl = `${url}` || null;
    const calculatedQueryParams = typeof queryParams === 'function' ? queryParams(contextPartnerType, route.snapshot.queryParams) : queryParams;
    return {
      human: humanName,
      key: key || humanName,
      expandAs,
      children: formattedChildren,
      url: calculatedUrl,
      hasBetaFlag: hasBetaFlag || false,
      leftDefaultIcon,
      leftSelectedIcon,
      queryParams: calculatedQueryParams,
      queryParamsToHash,
      skipUnwrap: item.skipUnwrap,
    };
  });
  const unwrappedItems: ISidebarItem[] = shapedItems?.map(unwrapSingleChildren.bind(this));
  return unwrappedItems || [];
};
