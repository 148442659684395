<div class="panel-body student-tasks-notes-wrapper">
  <div class="beta-message-banner" *ngIf="betaMessageData && isSchoolStudent">
      <span>
        <span class="bold-feature-name">{{betaMessageData.betaFeatureName}}</span> is in Beta.
      </span>
      <a *ngIf="betaMessageData.betaLearnMoreUrl" class="beta-message-link" href="{{betaMessageData.betaLearnMoreUrl}}" target="_blank">Learn More</a> or
      <span *ngIf="betaMessageData.betaShareFeedback" class="task-intercom-feedback">Share Your Feedback</span>
  </div>
  <!-- TOGGLE - FILTER - ADD BUTTONS -->
  <div class="panel-toolbar-container">
      <div class="segmented-control-filter-container">
          <nv-segmented-control
              [index]="segmentedControlIndex"
              [options]="isShelterStudent ? shelterSegmentedControlOptions : segmentedControlOptions"
              (clicked)="onListToggle($event)"
          ></nv-segmented-control>
      </div>

      <div class="align-rigth">
          <nv-button
              *ngIf="isShelterStudent ? segmentedControlIndex === 0 : segmentedControlIndex === 1"
              [icon]="'note-add-small-blue'"
              size="small"
              type="secondary"
              [disabled]="false"
              (click)="onAddNote()"
          >Add note
         </nv-button>

         <nv-button
              *ngIf="segmentedControlIndex === 0 && isSchoolStudent"
              icon="task-create-large-blue"
              size="small"
              type="secondary"
              [disabled]="!userCanCreateTasks"
              (click)="onAddTask()"
          >Create task
          </nv-button>
      </div>
  </div>
  <!--TABLE-->
  <ag-grid-angular
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      [modules]="modules"
      (gridReady)="onGridReady($event)"
      (cellClicked)="onCellClicked($event)"
      [rowData]="rowData"
  >
  </ag-grid-angular>

  <!-- CUSTOM PAGINATOR -->
  <paginator-controls
      *ngIf="rowData?.length > 0"
      [currentPage]="currentPage"
      [totalPages]="totalPages"
      [totalRows]="totalRows"
      [pageSize]="pageSize"
      (onBtNext)="onBtNext($event)"
      (onBtPrevious)="onBtPrevious($event)"
  ></paginator-controls>
</div>
