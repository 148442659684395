import { Component, EventEmitter, Inject, Output, ViewEncapsulation } from '@angular/core';
import { TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import { ISchool } from '../../typings/interfaces/school.interface';
import { IUser } from '../../typings/interfaces/user.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { THistoryLogsComponent } from '../history-all/history-all-modal.component';
import { TASK_NOTES_ACTIVITY_TOGGLE_INDEX, TasksNotesActivityTableStudentData } from '../../components/tasks-notes-activity-table/tasks-notes-activity-table.component';

@Component({
  selector: 'tasks-notes-activity-modal',
  templateUrl: './tasks-notes-activity-modal.component.html',
  styleUrls: ['./tasks-notes-activity-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TasksNotesActivityModalComponent {
  @Output() saveStudent: EventEmitter<any> = new EventEmitter();

  public partnerType: TValidPartnerTypes;
  public currentSchool: ISchool;
  public currentUser: IUser;
  public studentData: TasksNotesActivityTableStudentData;
  public segmentedControlIndex: number;
  public listType: string;
  public studentName: string;
  public studentId: string;
  public modalTitle: string;

  constructor (
    public dialogRef: MatDialogRef<TasksNotesActivityModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}

  public ngOnInit () {
    const { partnerType, currentSchool, studentData, currentUser, segmentedControlIndex, listType } = this.data;
    this.partnerType = partnerType || 'school';
    this.currentSchool = currentSchool;
    this.currentUser = currentUser;
    this.segmentedControlIndex = segmentedControlIndex;
    this.listType = listType;
    this.setStudentData(studentData, listType);
    this.modalTitle = this._getModalTitle(this.studentName, this.segmentedControlIndex);
  }

  public updateModalTitle (index: number) {
    this.modalTitle = this._getModalTitle(this.studentName, index);
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  private setStudentData (data: any, listType: THistoryLogsComponent): void {
    switch (listType) {
      case ('SCHOOL_DATA_GRID'):
        this.studentId = `${data.OSIS_NUMBER}${this.currentSchool._id}`;
        this.studentName = data.STUDENT_NAME;
        break;
      case ('SCHOOL_LIST'):
        this.studentId = data.studentId;
        this.studentName = data.studentName;
        break;
      default:
    }

    this.studentData = {
      studentId: this.studentId,
      studentName: this.studentName,
    };
  }

  private _getFormattedStudentName (name: string): string {
    const lastFirstNameArr = name.split(',');
    return `${lastFirstNameArr[1].trim()} ${lastFirstNameArr[0]}`;
  }

  private _getModalTitle (studentName: string, segmentedControlIndex: number): string {
    let view: string;
    const formattedName = this._getFormattedStudentName(studentName);
    if (segmentedControlIndex === TASK_NOTES_ACTIVITY_TOGGLE_INDEX.TASKS) view = 'Tasks';
    if (segmentedControlIndex === TASK_NOTES_ACTIVITY_TOGGLE_INDEX.NOTES) view = 'Notes';
    if (segmentedControlIndex === TASK_NOTES_ACTIVITY_TOGGLE_INDEX.ACTIVITY) view = 'Activity';

    return `${view} for ${formattedName}`;
  }
}
