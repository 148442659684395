<nv-form-field [label]="config.human" [labelIcon]="labelIcon" [labelIconTooltip]="config.meta.labelIconTooltip">
  <ng-container [ngSwitch]="config.meta.controlType">
    <nv-textbox
      *ngSwitchCase="'textbox'"
      [textControl]="form"
      [icon]="icon"
      [placeholder]="config.meta.placeholder"
      restrictToOptions="true"
      [autocompleteOptions]="options"
      hasClearBtn="true"
      returnObject="true"
      (selectOption)="onSelectOption($event)"
      (clearValue)="clearTextBoxValue()"
      [showInvalid]="!form.valid"
    ></nv-textbox>

    <form-item-date-picker
      *ngSwitchCase="'date-picker'"
      [dateRangeForm]="form"
    ></form-item-date-picker>

    <nv-textarea
      *ngSwitchCase="'textarea'"
      [formControl]="form"
      [placeholder]="config.meta.placeholder"
      maxCharacters="150">
    </nv-textarea>

    <nv-numbox
      *ngSwitchCase="'numbox'"
      [numControl]="form"
      [placeholder]="config.meta.placeholder"
      [icon]="icon"
      [showInvalid]="!form.valid"
    ></nv-numbox>

    <mat-checkbox
      *ngSwitchCase="'checkbox'"
      [formControl]="form"
      class="nv-mat-checkbox"
      name="config.key"
    >
      {{ config.meta.placeholder }}
    </mat-checkbox>
  </ng-container>

  <ng-container *ngIf="!form.valid">
    <nv-note priority="danger">
      {{ config.meta.validationMessage }}
    </nv-note>
  </ng-container>
</nv-form-field>