import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvNoteModule } from '../../../../../../nvps-libraries/design/nv-note/nv-note.module';
import { SyncSummaryComponent } from './sync-summary.component';

@NgModule({
  declarations: [SyncSummaryComponent],
  imports: [
    CommonModule,
    NvButtonModule,
    NvNoteModule,
  ],
  exports: [SyncSummaryComponent],
  providers: [],
})
export class SyncSummaryModule {}
