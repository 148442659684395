<nv-form-field 
  [label]="label" 
  labelIcon="info-small-hover" 
  [labelIconTooltip]="tooltip">
  <div class="dropdown-row">
    <div nvTooltip [tooltipData]="isDisabled ? disabledDropdownTooltip : null">
      <nv-dropdown 
        [ngClass]="{ 'disabled-dropdown': isDisabled  }"
        [selected]="selected" 
        [options]="options"
        (selectOption)="onSelectOption($event)">
      </nv-dropdown>
    </div>
    
    <nv-button 
      *ngIf="!isHescButtonHidden" 
      type="tertiary" 
      size="small" 
      icon="reset-small-blue" 
      class="reset-button"
      (click)="resetToRealStatus()"
      nvTooltip
      [tooltipData]="resetButtonTooltip">
      Reset to HESC
    </nv-button>
  </div>
</nv-form-field>