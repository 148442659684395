import { EM_DASH } from 'Src/ng2/shared/constants/em-dash.constant';
import { StudentOtherSchoolType, TSingleStudentViewOtherSchoolTypes } from './../../shared/typings/interfaces/student.interface';
import { District } from 'Src/ng2/shared/typings/interfaces/district.interface';
import { PartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
const ALL_SCHOOL_TYPES = ['ES', 'MS', 'HS'];

export type TValidStudentTypes = 'ES' | 'MS' | 'HS';

export interface IStudentPanel {
  id: string;
  human: string;
  studentTypes: TValidStudentTypes[];
  leftDefaultIcon?: string,
  leftSelectedIcon?: string,
  otherSchoolTypes?: TSingleStudentViewOtherSchoolTypes[];
  districts?: string[];
  toggles?: string[];
  contextPartnerType?: string[];
}
export interface IStudentPanels {
  [propName: string]: IStudentPanel;
}

export const ShelterProfileHeaderConfig = [
  {
    key: 'SHELTER_OSIS_NUMBER',
    human: 'OSIS',
    path: 'SHELTER_OSIS_NUMBER',
    isHS: [true, false],
    district: [District.NYC],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data,
  },
  {
    key: 'CARES_ID',
    human: 'Cares ID',
    path: 'CARES_ID',
    isHS: [true, false],
    district: [District.NYC],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data,
  },
  {
    key: 'SHELTER_SCHOOL_DBN',
    human: 'School',
    path: 'SHELTER_SCHOOL_DBN',
    isHS: [true, false],
    district: [District.NYC],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data,
  },
  {
    key: 'SHELTER_UNIT',
    human: 'Unit',
    path: 'SHELTER_UNIT',
    isHS: [true, false],
    district: [District.NYC],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data,
  },
  {
    key: 'SHELTER_GRADE',
    human: 'Grade',
    path: 'SHELTER_GRADE',
    isHS: [true, false],
    district: [District.NYC],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data,
  },
  {
    key: 'SHELTER_AGE',
    human: 'Age',
    path: 'SHELTER_AGE',
    isHS: [true, false],
    district: [District.NYC],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data,
  },
  {
    key: 'IS_SPED',
    human: 'SWD',
    path: 'IS_SPED',
    isHS: [true, false],
    district: [District.NYC],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data ? 'Yes' : 'No',
  },
  {
    key: 'IS_ELL',
    human: 'ML',
    path: 'IS_ELL',
    isHS: [true, false],
    district: [District.NYC],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data ? 'Yes' : 'No',
  },
];

export const ProfileHeaderConfig = [
  {
    key: 'ID',
    human: 'ID',
    path: 'OSIS_NUMBER',
    isHS: [true, false],
    district: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    isCUNY: [true, false],
    customFormat: (data) => data,
  },
  {
    key: 'SCHOOL_DBN',
    human: 'SCHOOL_DBN',
    path: 'SCHOOL_DBN',
    isHS: [true, false],
    district: [District.NYC],
    isSummerSchoolStudent: [false, false],
    isUftDoeAdvisingStudent: [false, false],
    isCUNY: [true],
    customFormat: (data) => data,
  },
  {
    key: 'SCHOOL_NAME',
    human: 'SCHOOL_NAME',
    path: 'SCHOOL_NAME',
    isHS: [true, false],
    district: [District.NYC],
    isSummerSchoolStudent: [false, false],
    isUftDoeAdvisingStudent: [false, false],
    isCUNY: [true],
    customFormat: (data) => data,
  },
  {
    key: 'OFC',
    human: 'OFC',
    path: 'OFFICIAL_CLASS',
    isHS: [true, false],
    district: [District.NYC, District.SCHENECTADY, District.UNIONDALE],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    isCUNY: [true, false],
    customFormat: (data) => data,
  },
  {
    key: 'CLASS',
    human: 'Class',
    path: 'CLASS',
    isHS: [true],
    district: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    isCUNY: [true, false],
    customFormat: (data) => data,
  },
  {
    key: 'GRADE',
    human: 'Grade',
    path: 'GRADE',
    isHS: [true, false],
    district: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    isCUNY: [true, false],
    customFormat: (data) => data,
  },
  {
    key: 'SWD',
    human: 'SWD',
    path: 'IS_SPED',
    isHS: [true, false],
    district: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    isCUNY: [true, false],
    customFormat: (data) => data ? 'Yes' : 'No',
  },
  {
    key: 'ML',
    human: 'ML',
    path: 'IS_ELL',
    isHS: [true, false],
    district: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    isCUNY: [true, false],
    customFormat: (data) => data ? 'Yes' : 'No',
  },
  {
    key: 'CURR_YTD_ATT',
    path: 'CURR_YTD_ATT',
    human: 'YTD Attendance',
    isHS: [true, false],
    district: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    isCUNY: [true, false],
    customFormat: data => (data === null ? EM_DASH : `${data}%`),
  },
  // Learning Preference removed per POD-273
  // {
  //   key: 'LEARNING PREFERENCE',
  //   human: 'LEARNING PREFERENCE',
  //   path: 'learningPreference',
  //   isHS: [true, false],
  //   district: ['NYC'],
  //   isSummerSchoolStudent: [true, false],
  //   isUftDoeAdvisingStudent: [true, false],
  // }
];

/**
 * NOTE: Each panel has a config file in its directory that contains the panel title and paths.
 * The title there MUST match the human name here. TODO: Tighten up. - JYR.
 */
export const StudentPanelsConfig = {
  SNAPSHOT: {
    id: 'student-snapshot-panel',
    human: 'Snapshot',
    leftDefaultIcon: 'snapshot-default',
    leftSelectedIcon: 'snapshot-selected',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL, PartnerTypes.SHELTER],
  },
  DETAILS: {
    id: 'student-details-panel',
    human: 'Details',
    leftDefaultIcon: 'details-default',
    leftSelectedIcon: 'details-selected',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL, PartnerTypes.SHELTER],
  },
  BASIC_INFO: {
    id: 'student-basic-info-panel',
    human: 'Basic Information',
    leftDefaultIcon: 'details-default',
    leftSelectedIcon: 'details-selected',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL, PartnerTypes.SHELTER],
  },
  TASKS_NOTES: {
    id: 'student-tasks-notes-panel',
    human: 'Tasks and Notes',
    leftDefaultIcon: 'tasks-default',
    leftSelectedIcon: 'tasks-selected',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  SHELTER_NOTES: {
    id: 'student-notes-panel',
    human: 'Notes',
    leftDefaultIcon: 'notes-default',
    leftSelectedIcon: 'notes-selected',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [],
    districts: [
      'NYC',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SHELTER],
  },
  CONTACT_INFO: {
    id: 'student-contact-info-panel',
    human: 'Contact Information',
    leftDefaultIcon: 'details-default',
    leftSelectedIcon: 'details-selected',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL, PartnerTypes.SHELTER],
  },
  ATTENDANCE: {
    id: 'student-attendance-panel',
    human: 'Attendance',
    leftDefaultIcon: 'attendance-default',
    leftSelectedIcon: 'attendance-selected',
    studentTypes: ALL_SCHOOL_TYPES,
    hideForSummerSchoolStudent: false,
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  SHELTER_ATTENDANCE: {
    id: 'attendance-panel',
    human: 'Attendance',
    leftDefaultIcon: 'attendance-default',
    leftSelectedIcon: 'attendance-selected',
    studentTypes: ALL_SCHOOL_TYPES,
    hideForSummerSchoolStudent: false,
    otherSchoolTypes: [],
    districts: [
      'NYC',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SHELTER],
  },
  SCREENERS: {
    id: 'student-screeners-panel',
    human: 'Screeners',
    leftDefaultIcon: 'screeners-default',
    leftSelectedIcon: 'screeners-selected',
    studentTypes: ['ES', 'MS', 'HS'],
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Lansing',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  FORM_ASSMNTS: {
    id: 'student-fa-panel',
    human: 'Assessments',
    leftDefaultIcon: 'regents-default',
    leftSelectedIcon: 'regents-selected',
    studentTypes: ['ES', 'MS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER],
    districts: [
      'NYC',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  ASSIGNED_SUPPORTS: {
    id: 'student-supports-panel',
    human: 'Supports',
    leftDefaultIcon: 'supports-default',
    leftSelectedIcon: 'supports-selected',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  ACADEMIC: {
    id: 'student-academic-panel',
    human: 'Academics',
    leftDefaultIcon: 'academics-default',
    leftSelectedIcon: 'academics-selected',
    studentTypes: ['ES', 'MS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  GRAD_PLANNING: {
    id: 'student-grad-panel-v3',
    human: 'Graduation',
    leftDefaultIcon: 'graduation-default',
    leftSelectedIcon: 'graduation-selected',
    studentTypes: ['HS'],
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  REMOTE_LEARNING: {
    id: 'remote-learning-panel',
    human: 'Remote learning',
    leftDefaultIcon: '',
    leftSelectedIcon: '',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  CURRENT_PROGRAM: {
    id: 'student-current-program-panel',
    human: 'Academics',
    leftDefaultIcon: 'academics-default',
    leftSelectedIcon: 'academics-selected',
    studentTypes: ['HS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR, StudentOtherSchoolType.CUNY],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL, PartnerTypes.CUNY],
  },
  CREDITS: {
    id: 'student-credits-panel',
    human: 'Credits',
    leftDefaultIcon: 'credits-default',
    leftSelectedIcon: 'credits-selected',
    studentTypes: ['HS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  CREDIT_GAPS: {
    id: 'student-credit-gaps-panel',
    human: 'Credit Gaps',
    leftDefaultIcon: 'credits-default',
    leftSelectedIcon: 'credits-selected',
    studentTypes: ['HS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  REGENTS: {
    id: 'student-regents-panel',
    human: 'Regents',
    leftDefaultIcon: 'regents-default',
    leftSelectedIcon: 'regents-selected',
    studentTypes: ['HS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  POINT_PEOPLE: {
    id: 'student-point-people-panel',
    human: 'Point People',
    leftDefaultIcon: 'details-default',
    leftSelectedIcon: 'details-selected',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  POSTSECONDARY: {
    id: 'student-postsec-panel',
    human: 'Postsecondary',
    leftDefaultIcon: 'postsecondary-default',
    leftSelectedIcon: 'postsecondary-selected',
    studentTypes: ['HS'],
    otherSchoolTypes: [StudentOtherSchoolType.OPSR],
    districts: ['NYC'],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  TRANSCRIPT_AND_EXAM_HISTORY: {
    id: 'student-transcript-and-exam-history-panel',
    human: 'Transcript History',
    alternativePanelName: {
      Lansing: 'Transcript'
    },
    leftDefaultIcon: 'transcript-history-default',
    leftSelectedIcon: 'transcript-history-selected',
    studentTypes: ['HS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR, StudentOtherSchoolType.CUNY],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL, PartnerTypes.CUNY],
  },
  BEHAVIORAL_INCIDENTS: {
    id: 'student-behavioral-incidents-panel',
    human: 'Behavioral Incidents',
    leftDefaultIcon: 'behavioral-grey',
    leftSelectedIcon: 'behavioral-blue',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'Lansing',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SCHOOL],
  },
  SUCCESS_MENTORING: {
    id: 'student-success-mentoring-panel',
    human: 'Success Mentoring',
    leftDefaultIcon: 'supports-default',
    leftSelectedIcon: 'supports-selected',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [],
    districts: [
      'NYC',
    ],
    toggles: [],
    contextPartnerType: [PartnerTypes.SHELTER],
  },
};

export default StudentPanelsConfig;
