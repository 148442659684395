import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PusherService } from './../pusher/pusher-service';
import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import { ModalsService } from '../../modals/modals.service';
import { PortalConfig } from '../../services/portal-config';

export enum BACKGROUND_JOB_TIMEOUT_THRESHOLDS {
  /* eslint-disable-next-line no-unused-vars */
  BATCH_ACTIONS = 30000
}

export enum BACKGROUND_JOB_STATUS_TYPES {
  /* eslint-disable-next-line no-unused-vars */
  NOTIFY = 'NOTIFY',
  /* eslint-disable-next-line no-unused-vars */
  RESOLVE = 'RESOLVE',
  /* eslint-disable-next-line no-unused-vars */
  PARTIAL = 'PARTIAL',
  /* eslint-disable-next-line no-unused-vars */
  REJECT = 'REJECT',
}

export interface IJobStatus {
  type: BACKGROUND_JOB_STATUS_TYPES;
  status: { progress: number };
}

export interface IJobStatusSubject {
  type: BACKGROUND_JOB_STATUS_TYPES
  data: IJobStatus['status']

}

/**
 * Inner class that manages the interaction with Pusher and the HTTP endpoint.
 */

@Injectable()
export class BackgroundJob {
  constructor (private portalConfig: PortalConfig, private injector: Injector) {
    const backgroundJobConstructor = uuid => {
      const BackgroundJob = initBackgroundJob(this.portalConfig, this.injector);
      return new BackgroundJob(uuid);
    };
    return backgroundJobConstructor as any;
  }
}

/* istanbul ignore next */
function initBackgroundJob (portalConfig, injector) {
  // tslint:disable-next-line:max-classes-per-file
  return class BackgroundJob {
    _uuid;
    _channel;
    _pusherHandler;
    _subject;
    _hasOutstandingPollRequest;
    _pollPromise;
    _isPolling;
    private publicConfig: PortalConfig['publicConfig'];
    private injected$Http;
    private injectedPusher: PusherService;
    private injectedModalsService;

    constructor (uuid) {
      this.injected$Http = injector.get(HttpClient);
      this.injectedPusher = injector.get(PusherService);
      this.injectedModalsService = injector.get(ModalsService);

      this.publicConfig = portalConfig.publicConfig;
      this._uuid = uuid;
      this._channel = this.injectedPusher.pusher.subscribe('nv-background-job-' + this._uuid);
      this._pusherHandler = this._handlePusherMessage.bind(this);
      this._channel.bind('status-update', this._pusherHandler);
      this._subject = new Subject();
      this._hasOutstandingPollRequest = false;

      this._startPolling();
    }

    static get POLLING_INTERVAL () {
      return 3000;
    } // milliseconds

    getById (id) {
      return this.injected$Http.get(this.publicConfig.NV_API_ORIGIN + '/v1/backgroundJobs/' + id).toPromise();
    }

    _startPolling () {
      this._pollPromise = setInterval(this._poll.bind(this), BackgroundJob.POLLING_INTERVAL);
      this._isPolling = true;
    }

    _stopPolling () {
      clearInterval(this._pollPromise);
      this._isPolling = false;
    }

    _poll () {
      // Don't make another request if we are still waiting for one to resolve
      if (this._hasOutstandingPollRequest) return;

      this.getById(this._uuid).then(response => {
        if (!this._isPolling) return; // in case requests come in after we stop polling, ignore them

        let type;
        switch (response.state) {
          case 'STARTED':
            type = 'NOTIFY';
            break;
          case 'RESOLVED':
            type = 'RESOLVE';
            break;
          case 'REJECTED':
            type = 'REJECT';
            break;
          case 'PARTIAL_SETTLED':
            type = 'PARTIAL';
            break;
          default:
            return;
        }

        this._handleStatusUpdate({
          type,
          status: response.status,
        });

        this._hasOutstandingPollRequest = false;
      });

      this._hasOutstandingPollRequest = true;
    }

    _handlePusherMessage (msg) {
      this._handleStatusUpdate(msg);
    }

    _stopListeningToPusher () {
      this._channel.unbind('status-update', this._pusherHandler);
    }

    _handleStatusUpdate (data: IJobStatus) {
      switch (data.type) {
        case BACKGROUND_JOB_STATUS_TYPES.NOTIFY:
          this._subject.next({ type: BACKGROUND_JOB_STATUS_TYPES.NOTIFY, data: data.status });
          break;
        case BACKGROUND_JOB_STATUS_TYPES.RESOLVE:
          this._stopListeningToPusher();
          this._stopPolling();
          this._subject.next({ type: BACKGROUND_JOB_STATUS_TYPES.RESOLVE, data: data.status });
          this._subject.complete();
          break;
        case BACKGROUND_JOB_STATUS_TYPES.REJECT:
          this._subject.next({ type: BACKGROUND_JOB_STATUS_TYPES.REJECT, data: data.status });
          this._subject.error();
          this._showRejectError(data);
          this._stopListeningToPusher();
          this._stopPolling();
          break;
        case BACKGROUND_JOB_STATUS_TYPES.PARTIAL:
          this._subject.next({ type: BACKGROUND_JOB_STATUS_TYPES.PARTIAL, data: data.status });
          this._subject.error();
          this._showPartialErrors(data);
          this._stopListeningToPusher();
          this._stopPolling();
          break;
        default:
          break;
      }
    }

    _formatPartialErrors (errors) {
      const acc = {
        message: '',
        details: '',
      };

      _.reduce(
        errors,
        (acc, docs: any, errMssg) => {
          const docsInfo = {
            humanReadable: [],
            ids: [],
          };

          _.reduce(
            docs,
            (acc, v: any) => {
              acc.humanReadable.push(v.humanReadableIdentifier);
              acc.ids.push(v.id);
              return acc;
            },
            docsInfo,
          );

          const wasOrWere = docs.length > 1 ? 'were' : 'was';
          let formattedMssg = errMssg.replace(/_/g, '.');

          formattedMssg = formattedMssg.replace(/\\/g, '');

          acc.message += `${
            docsInfo.humanReadable
          } - ${wasOrWere} not processed due to the following error: ${formattedMssg}\n`;

          acc.details += `_ids: ${
            docsInfo.ids
          } - ${wasOrWere} not processed due to the following error: ${formattedMssg}\n`;

          return acc;
        },
        acc,
      );

      return acc;
    }

    _showPartialErrors (data) {
      const errors = data.status.errors;

      if (errors) {
        this.injectedModalsService.openErrorModal({
          title: 'Please try again later',
          message: '',
        });
      }
    }

    _showRejectError (data) {
      const error = data.status.error;
      if (error) {
        const payload = this._constructErrorPayload(data);
        this.injectedModalsService.openErrorModal(payload);
      }
    }

    _constructErrorPayload (data) {
      const {
        status: { message },
      } = data;
      const response = {
        title: 'Unable to complete batch action',
        message,
        confirmText: 'Try again',
      };
      return response;
    }

    subject () {
      return this._subject;
    }
  };
}
