import { PortalLanguages } from './portal-languages.constant';
import { IDropdownOption } from '../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { snakeCase } from 'lodash';

export type TValidPdfReportNames =
  | 'Snapshot'
  | 'Regents Prep'
  | 'EMS Screeners'
  | 'HS Screeners'
  | 'Attendance'
  | 'Academics'
  | 'DESSA (SEL) Report'
  | 'Mock Regents';

type TTranslationLngs = 'ar' | 'bn' | 'en' | 'es' | 'fr' | 'ht' | 'ko' | 'ru' | 'ur' | 'zh';

export type TValidPdfReportKeys =
  | 'snapshot'
  | 'assessment'
  | 'emsAssessment'
  | 'hsAssessment'
  | 'regentsSched'
  | 'attendance'
  | 'academic'
  | 'dessa'
  | `dessa_${TTranslationLngs}`
  | 'mockRegents';

// TODO: change on flip
export type TValidMockRegentsReports =
| 'mockRegents_alg1June2023'
| 'mockRegents_livingEnvJune2023'
| 'mockRegents_globalJune2023'
| 'mockRegents_usFrameworkJune2023'
| 'mockRegents_elaJune2023'
| 'mockRegents_earthScienceJune2023'
| 'mockRegents';

interface IPdfReportTypes {
  snapshot: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
    default: boolean,
  };
  emsAssessment: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
    default: boolean,
  };
  hsAssessment: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
    default: boolean,
  };
  regentsSched: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
    default: boolean,
  };
  attendance: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
    default: boolean,
  };
  academic: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
    default: boolean,
  };
  dessa: IDropdownOption & { 
    human: TValidPdfReportNames; 
    key: TValidPdfReportKeys; 
    options: Array<IDropdownOption>;
    districts: Array<string>;
    default: boolean,
  };
  mockRegents: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
    default: boolean,
  }
}

const nonDessaDefaults = {
  exportFileNamePrefix: 'students',
};

const dessaDefaults = {
  exportFileNameBody: 'dessa_letter',
};

const mockRegentsDefaults = {
  exportFileNamePrefix: 'mock',
  exportFileNameBody: 'regents',
};

function calculateBody({ key }) {
  return snakeCase(key);
}

function calculateSuffix({ human }) {
  return snakeCase(human);
}

//Want to include some calculations in the form of getters but to have this be entirely constant at export time.
//So, going to export the destructure of this type.
const pdfReportTypesUnprocessed: IPdfReportTypes = {
  snapshot: {
    ...nonDessaDefaults,
    human: 'Snapshot',
    key: 'snapshot',
    options: [],
    get exportFileNameBody() {
      return calculateBody(this);
    },
    districts: ['NYC', 'Schenectady', 'Uniondale'],
    default: true,
    order: 5,
  },
  regentsSched: {
    ...nonDessaDefaults,
    human: 'Regents Prep',
    key: 'regentsSched',
    exportFileNameBody: 'regents_prep',
    options: [],
    districts: ['NYC', 'Schenectady', 'Uniondale'],
    default: true,
    order: 6,
  },
  emsAssessment: {
    ...nonDessaDefaults,
    human: 'EMS Screeners',
    key: 'hsAssessment',
    exportFileNameBody: 'assessment',
    options: [],
    districts: ['NYC', 'Schenectady', 'Uniondale'],
    default: true,
    order: 3,
  },
  hsAssessment: {
    ...nonDessaDefaults,
    human: 'HS Screeners',
    key: 'emsAssessment',
    exportFileNameBody: 'assessment',
    options: [],
    districts: ['NYC', 'Schenectady', 'Uniondale'],
    default: true,
    order: 4,
  },
  attendance: {
    ...nonDessaDefaults,
    human: 'Attendance',
    key: 'attendance',
    options: [],
    get exportFileNameBody() {
      return calculateBody(this);
    },
    districts: ['NYC', 'Lansing', 'Schenectady', 'Uniondale'],
    default: true,
    order: 1,
  },
  academic: {
    ...nonDessaDefaults,
    human: 'Academics',
    key: 'academic',
    options: [],
    get exportFileNameBody() {
      return calculateBody(this);
    },
    districts: ['NYC'],
    default: true,
    order: 2,
  },
  dessa: {
    human: 'DESSA (SEL) Report',
    key: 'dessa',
    default: true,
    options: [
      {
        ...dessaDefaults,
        key: 'dessa_en',
        human: PortalLanguages.ENGLISH,
        pillPlaceholder: 'DESSA (SEL) Report: English',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_ar',
        human: PortalLanguages.ARABIC,
        pillPlaceholder: 'DESSA (SEL) Report: Arabic',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_bn',
        human: PortalLanguages.BENGALI,
        pillPlaceholder: 'DESSA (SEL) Report: Bengali',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_zh',
        human: PortalLanguages.CHINESE,
        pillPlaceholder: 'DESSA (SEL) Report: Chinese',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_fr',
        human: PortalLanguages.FRENCH,
        pillPlaceholder: 'DESSA (SEL) Report: French',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_ht',
        human: PortalLanguages.HAITIAN_CREOLE,
        pillPlaceholder: 'DESSA (SEL) Report: Haitian Creole',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_ko',
        human: PortalLanguages.KOREAN,
        pillPlaceholder: 'DESSA (SEL) Report: Korean',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_ru',
        human: PortalLanguages.RUSSIAN,
        pillPlaceholder: 'DESSA (SEL) Report: Russian',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_es',
        human: PortalLanguages.SPANISH,
        pillPlaceholder: 'DESSA (SEL) Report: Spanish',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_ur',
        human: PortalLanguages.URDU,
        pillPlaceholder: 'DESSA (SEL) Report: Urdu',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
    ],
    districts: [],
    order: 8,
  },
  mockRegents: {
    human: 'Mock Regents',
    key: 'mockRegents',
    default: false,
    options: [
      {
        ...mockRegentsDefaults,
        key: 'mockRegents_alg1June2023',
        human: 'Algebra 1',
        pillPlaceholder: 'Algebra 1 Mock Regent',
        exportFileNameSuffix: 'alg1June2023',
      },
      {
        ...mockRegentsDefaults,
        key: 'mockRegents_earthScienceJune2023',
        human: 'Earth Science',
        pillPlaceholder: 'Earth Science Mock Regent',
        exportFileNameSuffix: 'earthScienceJune2023',
      },
      {
        ...mockRegentsDefaults,
        key: 'mockRegents_elaJune2023',
        human: 'ELA',
        pillPlaceholder: 'ELA Mock Regent',
        exportFileNameSuffix: 'elaJune2023',
      },
      {
        ...mockRegentsDefaults,
        key: 'mockRegents_globalJune2023',
        human: 'Global History',
        pillPlaceholder: 'Global History Mock Regent',
        exportFileNameSuffix: 'globalJune2023',
      },
      {
        ...mockRegentsDefaults,
        key: 'mockRegents_livingEnvJune2023',
        human: 'Living Environment',
        pillPlaceholder: 'Living Environment Mock Regent',
        exportFileNameSuffix: 'livingEnvJune2023',
      },
      {
        ...mockRegentsDefaults,
        key: 'mockRegents_usFrameworkJune2023',
        human: 'US History',
        pillPlaceholder: 'US History Mock Regent',
        exportFileNameSuffix: 'usFrameworkJune2023',
      },
    ],
    districts: ['NYC'],
    order: 7,
  },
};

//Take a static clone, effectively, and return that.
export const pdfReportTypes = { ...pdfReportTypesUnprocessed };
