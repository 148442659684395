import { PORTAL_TYPES } from './../../typings/interfaces/portal.interface';

export const PORTAL_SWITCHER_CONFIG = {
  [PORTAL_TYPES.SCHOOL]: [
    {
      key: 'Switch School',
      human: 'Switch School',
      permissions: {
        isSuperAdmin: null,
        isClusterUser: {
          needsHybridAccess: false,
          needsMultiSchoolOrShelterAccess: true,
          needsAtLeastOneSchoolOrShelterAccess: false,
        },
        isMultiSchoolUser: {
          needsAtLeastTwoSchoolsAccess: true,
        },
      },
    },
    {
      key: 'View Network',
      human: 'View Network',
      permissions: {
        isSuperAdmin: null,
        isClusterUser: {
          needsHybridAccess: false,
          needsMultiSchoolOrShelterAccess: false,
          needsAtLeastOneSchoolOrShelterAccess: false,
        },
      },
    },
    {
      key: 'Explore Schools',
      human: 'Explore Schools',
      permissions: {
        isSuperAdmin: {
          needsSuperAdminAccess: true,
        },
        isClusterUser: null,
      },
    },
    {
      key: 'Explore Shelters',
      human: 'Explore Shelters',
      permissions: {
        isSuperAdmin: {
          needsSuperAdminAccess: true,
        },
        isClusterUser: {
          needsHybridAccess: true,
          needsMultiSchoolOrShelterAccess: false,
          needsAtLeastOneSchoolOrShelterAccess: false,
        },
      },
    },
    {
      key: 'Switch District',
      human: 'Switch District',
      permissions: {
        isSuperAdmin: null,
        isClusterUser: null,
        isMultiDistrictUser: true,
      },
    },
  ],
  [PORTAL_TYPES.NETWORK]: [
    {
      key: 'Switch to a School',
      human: 'Switch to a School',
      permissions: {
        isSuperAdmin: null,
        isClusterUser: {
          needsHybridAccess: false,
          needsMultiSchoolOrShelterAccess: false,
          needsAtLeastOneSchoolOrShelterAccess: true,
        },
      },
    },
    {
      key: 'Explore Shelters',
      human: 'Explore Shelters',
      permissions: {
        isSuperAdmin: {
          needsSuperAdminAccess: true,
        },
        isClusterUser: {
          needsHybridAccess: true,
          needsMultiSchoolOrShelterAccess: false,
          needsAtLeastOneSchoolOrShelterAccess: false,
        },
      },
    },
    {
      key: 'Explore Schools',
      human: 'Explore Schools',
      permissions: {
        isSuperAdmin: {
          needsSuperAdminAccess: true,
        },
        isClusterUser: null,
      },
    },
  ],
  [PORTAL_TYPES.SHELTER_NETWORK]: [
    {
      key: 'Switch to a Shelter',
      human: 'Switch to a Shelter',
      permissions: {
        isSuperAdmin: null,
        isClusterUser: {
          needsHybridAccess: false,
          needsMultiSchoolOrShelterAccess: false,
          needsAtLeastOneSchoolOrShelterAccess: true,
        },
      },
      viewType: 'shelter',
    },
    {
      key: 'Explore Schools',
      human: 'Explore Schools',
      permissions: {
        isSuperAdmin: {
          needsSuperAdminAccess: true,
        },
        isClusterUser: {
          needsHybridAccess: true,
          needsMultiSchoolOrShelterAccess: false,
          needsAtLeastOneSchoolOrShelterAccess: false,
        },
      },
    },
    {
      key: 'Explore Shelters',
      human: 'Explore Shelters',
      permissions: {
        isSuperAdmin: {
          needsSuperAdminAccess: true,
        },
        isClusterUser: null,
      },
    },
  ],
  [PORTAL_TYPES.SHELTER]: [
    {
      key: 'Switch Shelter',
      human: 'Switch Shelter',
      permissions: {
        isSuperAdmin: null,
        isClusterUser: {
          needsHybridAccess: false,
          needsMultiSchoolOrShelterAccess: true,
          needsAtLeastOneSchoolOrShelterAccess: false,
        },
        multiSubLocations: false,
      },
    },
    {
      key: 'Switch Shelter Location',
      human: 'Switch Shelter Location',
      permissions: {
        isSuperAdmin: {
          needsSuperAdminAccess: true,
        },
        isClusterUser: {
          needsHybridAccess: false,
          needsMultiSchoolOrShelterAccess: false,
          needsAtLeastOneSchoolOrShelterAccess: false,
        },
        multiSubLocations: true,
      },
    },
    {
      key: 'View Network',
      human: 'View Network',
      permissions: {
        isSuperAdmin: null,
        isClusterUser: {
          needsHybridAccess: false,
          needsNetworkAccess: true,
          needsMultiSchoolOrShelterAccess: false,
          needsAtLeastOneSchoolOrShelterAccess: false,
        },
      },
    },
    {
      key: 'Explore Schools',
      human: 'Explore Schools',
      permissions: {
        isSuperAdmin: {
          needsSuperAdminAccess: true,
        },
        isClusterUser: {
          needsHybridAccess: true,
          needsMultiSchoolOrShelterAccess: false,
          needsAtLeastOneSchoolOrShelterAccess: false,
        },
        multiSubLocations: false,
      },
    },
    {
      key: 'Explore Shelters',
      human: 'Explore Shelters',
      permissions: {
        isSuperAdmin: {
          needsSuperAdminAccess: true,
        },
        isClusterUser: null,
        multiSubLocations: false,
      },
    },
  ],
};
