import { IServerSideGetRowsParams, IStatusPanelParams } from '@ag-grid-community/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { skeletonHeaderLineTheme } from 'Src/ng2/shared/constants/skeleton-loader.constant';

export interface IGridToolbarButton {
  name: string;
  icon: string;
  size: string;
  type: string;
  action: () => void;
}

@Component({
  selector: 'grid-toolbar',
  styleUrls: ['./grid-toolbar.component.scss'],
  templateUrl: './grid-toolbar.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class GridToolbarComponent {
  public params: IStatusPanelParams;
  public toolbarButtons: IGridToolbarButton[] = [];
  public skeletonHeaderLineTheme = skeletonHeaderLineTheme;
  public isContentLoaded: boolean;

  agInit (params: IServerSideGetRowsParams): void {
    this.params = params;
    this.toolbarButtons = this.params.context.componentParent.gridToolbarButtons;
    this.isContentLoaded = this.params.context.isContentLoaded;
  }

  public setContentLoaded (isContentLoaded: boolean): void {
    this.isContentLoaded = isContentLoaded;
  }
}
