import { ColDef } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { QueryParamsHandling, Router } from '@angular/router';
import {
  GRIDVIEW_FILTER_MAPPING,
  GRIDVIEW_MAPPING,
  GRIDVIEW_MAPPING_LANSING,
} from 'Src/ng2/shared/constants/gridview-mapping.constant';
import { GraphQLGridViewHelperService } from 'Src/ng2/shared/services/graphql-helpers/gridviews/gridviews-queries.service';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { EventFormatterService } from 'Src/ng2/shared/services/mixpanel/event-formatter.service';
import { MixpanelService } from 'Src/ng2/shared/services/mixpanel/mixpanel.service';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
import { IGridViewFilter } from 'Src/ng2/shared/typings/interfaces/grid-view.interface';
import { PartnerTypes, TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { cloneDeep } from 'lodash';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { DateHelpers } from 'projects/shared/services/date-helpers/date-helpers.service';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, expand, last, map, scan, tap } from 'rxjs/operators';
import { NetworkFociGridData } from '../../../network/network-foci-grid/data/network-foci-grid-data.service';
import { ApiService } from '../../../shared/services/api-service/api-service';
import { IApi } from '../../../shared/services/api-service/api-service.interface';
import { District } from '../../../shared/typings/interfaces/district.interface';

export interface IDataGridColDef extends ColDef {
  graphQlKey: string;
  field: string;
  wildcardKey: string | null;
}

export interface IDataGridColumnRequest {
  columnKey: string;
  field: string;
}

export interface ISortModel {
  colId: string;
  sort: 'asc' | 'desc';
}
export interface IRowGroup {
  colId: string;
  aggFunc: string;
}
export interface IAgGridRequest {
  startRow: number;
  endRow: number;
  sortModel: ISortModel[];
  filterModel: {
    [key: string]: any;
  };
  rowGroups?: IRowGroup[];
  rowGroupCols: any[];
  groupKeys: string[];
  pivotCols: any[];
  pivotMode: boolean;
  valueCols: any[];
}

interface IGridCalcsPayload {
  query: string;
  fetchPolicy: string;
  variables: {
    columnRequest: IDataGridColumnRequest[];
    agGridRequest: IAgGridRequest;
  };
}

export type TPathsToGrid = 'myGridViewsDropdown' | 'contentAreaDashboard' | 'homepageTile';

export interface INavToGridInfo {
  originComponent: TPathsToGrid;
  originId: string;
}

export interface IRedirectInfo {
  filterDetails?: string[];
  filters?: IGridViewFilter[];
  gridViewId: string;
  type?: string;
}

@Injectable()
export class ServerSideGridService {
  private readonly GRID_CALCS_ROW_LIMIT = 300; // This was determined to be a safe block size to avoid mongo errors and server timeouts when fetching large amount of data
  constructor (
    private apiService: ApiService,
    private dateHelpers: DateHelpers,
    private eventFormatterService: EventFormatterService,
    private gridViewHelper: GraphQLGridViewHelperService,
    private imSchool: ImSchool,
    private mixPanelService: MixpanelService,
    private networkGridData: NetworkFociGridData,
    private objectCache: ObjectCache,
    private router: Router,
    private urlPathService: UrlPathService,
  ) {}

  public filteredBySelectAllColumn$ = new BehaviorSubject<boolean>(false);

  setFilterBySelectAllColumn (newFilterValue: boolean) {
    this.filteredBySelectAllColumn$.next(newFilterValue);
  }

  public getGridConfig$ ({ contextPartnerId, gridType, contextPartnerType }): Observable<any> {
    const query = `{
      GridConfig(contextPartnerId: "${contextPartnerId}", gridType: "${gridType}", contextPartnerType: "${contextPartnerType}") {
        category
        categoryOrder
        cellRenderer
        cellRendererParams {
          params
        }
        checkboxSelection
        field
        filter
        filterParams {
          valueFormatter
          values
          filters {
            filter
            filterParams {
              suppressAndOrCondition
              buttons
            }
          }
        }
        graphQlKey
        headerName
        headerTooltip
        headerComponent
        hide
        lockPinned
        lockPosition
        pinned
        resizable
        sortable
        tags
        tooltipComponentParams {
          tooltipTemplate
          tooltipHeader
        }
        tooltipField
        valueFormatter
        valueGetter
        width
        wildcardKey
      }
    }`;
    const payload = { query, fetchPolicy: 'no-cache' };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res: IApi['GetGridConfigRes']) => {
        const columnDefs = res.data.GridConfig;
        return columnDefs.map((c: any) => {
          c.filterParams = {
            ...c.filterParams,
            buttons: ['clear'],
          };
          if (c.filter === 'agSetColumnFilter' && !c.filterParams?.values) {
            c.filterParams = {
              ...c.filterParams,
              suppressSorting: true,
              values: this.getValues(contextPartnerId, contextPartnerType),
            };
          }
          return c;
        });
      }),
      catchError(err => {
        if (err) {
          return throwError(() => EMPTY);
        }
      }),
    );
  }

  public getGridViewsConfig$ ({ district, gridType, contextPartnerId, userId, contextPartnerType }): Observable<any> {
    const query = this._getGridViewsQuery({ district, gridType, contextPartnerId, userId, contextPartnerType });

    const payload = { query, fetchPolicy: 'no-cache' };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res: IApi['GetGridViewConfigRes']) => {
        return res?.data;
      }),
      catchError(err => {
        if (err) {
          return throwError(() => EMPTY);
        }
      }),
    );
  }

  public getRow$ (opts: {
    columnDefs: ColDef[];
    contextPartnerId: string;
    contextPartnerType: TValidPartnerTypes;
    networkExternalFilterOptions?: string;
    request: IAgGridRequest;
    studentIds?: string[];
  }): Observable<any> {
    const { columnDefs, contextPartnerId, contextPartnerType, networkExternalFilterOptions, request, studentIds } =
      opts;
    if (contextPartnerType === PartnerTypes.SCHOOL_NETWORK || contextPartnerType === PartnerTypes.SHELTER_NETWORK) {
      return this.networkGridData.getGridDataAndCount$({
        request,
        columnDefs,
        gridView: networkExternalFilterOptions,
        clusterId: contextPartnerId,
        contextPartnerType,
      });
    }

    const columnRequest = this.getColumnRequest(columnDefs as any);
    const columnFields = this.getColumnFields(columnDefs);
    const query = studentIds
      ? `
      query ($columnRequest: [GridColumnRequest!], $agGridRequest: AgGridRequest) {
        GridCalcs(
          columns: $columnRequest,
          studentIds: [${this.getStudentIdKeys(studentIds)}]
          agGridRequest: $agGridRequest
          contextPartnerType: "${contextPartnerType}"
          contextPartnerId: "${contextPartnerId}"
        ) {
          rowData {
            ${columnFields}
          }
          count
        }
      }
    `
      : `
      query ($columnRequest: [GridColumnRequest!], $agGridRequest: AgGridRequest) {
        GridCalcs(
          columns: $columnRequest
          agGridRequest: $agGridRequest
          contextPartnerType: "${contextPartnerType}"
          contextPartnerId: "${contextPartnerId}"
        ) {
          rowData {
            ${columnFields}
          }
          count
        }
      }
    `;

    const { startRow: originalStartRow, endRow: originalEndRow } = request;
    const isRowLimitTooBig = this.isRowLimitTooBig(originalStartRow, originalEndRow);
    const updatedRequest = isRowLimitTooBig
      ? { ...request, endRow: originalStartRow + this.GRID_CALCS_ROW_LIMIT }
      : request;
    const maxAttemps =
      isRowLimitTooBig && originalEndRow ? this.getGridCalcsMaxAttemps(originalStartRow, originalEndRow) : null;
    const payload = { query, fetchPolicy: 'no-cache', variables: { columnRequest, agGridRequest: updatedRequest } };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      expand(({ data: { GridCalcs } }, idx) => {
        const shouldFetchMoreData = maxAttemps
          ? idx < maxAttemps
          : !!GridCalcs && GridCalcs.rowData.length === this.GRID_CALCS_ROW_LIMIT;
        if (shouldFetchMoreData) {
          const updatedPayload = this.updateGetStudentsGraphQLPayload(payload, originalEndRow);
          if (updatedPayload) return this.apiService.getStudentsGraphQL(updatedPayload);
          return EMPTY;
        }
        return EMPTY;
      }),
      map(res => {
        // Intensinally thrown errors in the api layer aren't caught by the catchError operator as the response code is 200
        // Therefore, manually check if the response has errors
        if (res.errors) throw throwError(() => new Error(res.errors[0].message));
        return res.data.GridCalcs;
      }),
      scan((previousData, currentData) => {
        if (!currentData && previousData) return previousData;
        previousData.rowData.push(...currentData.rowData);
        return previousData;
      }),
      last(),
      catchError(err => {
        if (err) {
          return throwError(() => EMPTY);
        }
      }),
    );
  }

  public createGridView$ (gridViewData: any, contextPartnerType: TValidPartnerTypes): Observable<any> {
    // cannot update admin/template grid views
    if (gridViewData.gridViewType === 'admin') {
      return throwError(() => 'cannot create/save template grid views');
    } else {
      return this.apiService.createGridView(gridViewData, contextPartnerType).pipe(
        map(createdGridView => {
          return createdGridView;
        }),
        catchError((err: any) => {
          return throwError(() => err);
        }),
      );
    }
  }

  public updateGridView$ (gridViewData: any, contextPartnerType: TValidPartnerTypes): Observable<any> {
    if (gridViewData.gridViewType === 'admin') {
      return throwError(() => 'cannot update/save template grid views');
    } else {
      return this.apiService.updateGridView(gridViewData, contextPartnerType).pipe(
        map(updatedGridView => {
          return updatedGridView;
        }),
        catchError((err: any) => {
          return throwError(() => err);
        }),
      );
    }
  }

  public deleteGridView$ (gridViewData: any, contextPartnerType: TValidPartnerTypes): Observable<any> {
    if (gridViewData.gridViewType === 'admin') {
      return throwError(() => 'cannot delete template grid views');
    } else {
      gridViewData.active = false;
      return this.apiService.deleteGridView(gridViewData, contextPartnerType).pipe(
        tap(deleteGridView => {
          return deleteGridView;
        }),
        catchError((err: any) => {
          return throwError(() => err);
        }),
      );
    }
  }

  private getGridCalcsMaxAttemps (originalStartRow: number, originalEndRow: number): number {
    if (!originalStartRow) return originalEndRow / this.GRID_CALCS_ROW_LIMIT - 1;
    return (originalEndRow - originalStartRow) / this.GRID_CALCS_ROW_LIMIT - 1;
  }

  private isRowLimitTooBig (startRow: number, endRow: number): boolean {
    const requestRowLimit = endRow ? endRow - startRow : null;
    return !requestRowLimit || requestRowLimit > this.GRID_CALCS_ROW_LIMIT;
  }

  private updateGetStudentsGraphQLPayload (payload: IGridCalcsPayload, originalEndRow: number): IGridCalcsPayload {
    const updatedPayload = { ...payload };
    const {
      variables: {
        agGridRequest: { startRow, endRow },
      },
    } = updatedPayload;
    const updatedStartRow = startRow + this.GRID_CALCS_ROW_LIMIT;
    const updatedEndRow = originalEndRow
      ? endRow + this.GRID_CALCS_ROW_LIMIT > originalEndRow
        ? originalEndRow
        : endRow + this.GRID_CALCS_ROW_LIMIT
      : endRow + this.GRID_CALCS_ROW_LIMIT;

    if (updatedStartRow === updatedEndRow) return null;
    updatedPayload.variables.agGridRequest.startRow = updatedStartRow;
    updatedPayload.variables.agGridRequest.endRow = updatedEndRow;
    return updatedPayload;
  }

  private getColumnRequest (columnDefs: IDataGridColDef[]): IDataGridColumnRequest[] {
    return columnDefs.map((columnDef: IDataGridColDef) => {
      return {
        columnKey: columnDef.wildcardKey ? `${columnDef.graphQlKey}=${columnDef.wildcardKey}` : columnDef.graphQlKey,
        field: columnDef.field,
      };
    });
  }

  private getColumnFields (columnDefs: ColDef[]): string[] {
    return columnDefs.map((columnDef: ColDef) => {
      return columnDef.field;
    });
  }

  private getStudentIdKeys (studentIds: string[]): string[] {
    return studentIds.map((studentId: string) => {
      return `"${studentId}"`;
    });
  }

  private getValues (contextPartnerId: string, contextPartnerType: string): any {
    return (params: any) => {
      const { colDef } = params;
      let columnKey: string;

      if (colDef.wildcardKey) {
        const sortedWildcards = colDef.wildcardKey
          .split(',')
          .map((k: any) => k.trim())
          .sort()
          .join(',');
        columnKey = `${colDef.graphQlKey}=${sortedWildcards}`;
      } else {
        columnKey = colDef.graphQlKey;
      }

      this.getGridSetFilterValue$({ contextPartnerId, columnKey, contextPartnerType }).subscribe((values: string[]) => {
        params.success(values);
      });
    };
  }

  public getGridSetFilterValue$ ({ contextPartnerId, columnKey, contextPartnerType }): any {
    const query = `{
      GridSetFilterValues(contextPartnerId: "${contextPartnerId}", columnKey: "${columnKey}", contextPartnerType: "${contextPartnerType}") {
        values
      }
    }`;
    const payload = { query, fetchPolicy: 'no-cache' };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res: IApi['GetGridSetFilterValuesRes']) => {
        const { values } = res.data.GridSetFilterValues;
        return values;
      }),
      catchError(err => {
        if (err) {
          return throwError(() => EMPTY);
        }
      }),
    );
  }

  private _getGridViewsQuery ({ district, gridType, contextPartnerId, userId, contextPartnerType }): string {
    let queryType: string;
    if (gridType === 'custom') {
      queryType = 'CustomGridViewsByUser';
    } else {
      queryType = 'GridViewsByUser';
    }

    const contextPathString = this.gridViewHelper.getContextPath(contextPartnerType) || '';
    const contextPartnerIdString = contextPartnerId ? `"${contextPartnerId}"` : 'null';

    const query = `{
      ${queryType}(district: "${district}", gridType: "${gridType}", contextPartnerId: ${contextPartnerIdString}, userId: "${userId}", contextPartnerType: "${contextPartnerType}") {
        _id
        gridViewId
        gridViewName
        gridViewType
        parentCategory
        order
        columnDefs {
          field
          width
          pinned
        }
        filters {
          colId,
          values,
          filterType,
          operator,
          condition1,
          condition2,
          type,
          filter,
          filterTo,
          dateFrom,
          dateTo
        }
        accessPermissions {
          districts
          gridTypes
          userIds
          ${contextPathString}
        }
        sorts {
          colId
          sort
          sortIndex
        }
        createdBy {
          userId
          gafeEmail
        }
        createdAt
      }
    }`;
    return query;
  }

  public orderViewsByCreationDate (data: any[] = []): any[] {
    const clonedData = cloneDeep(data);
    return clonedData?.sort((a, b) => {
      const aTime = parseInt(this.dateHelpers.getFormattedMoment(a.createdAt, 'x'));
      const bTime = parseInt(this.dateHelpers.getFormattedMoment(b.createdAt, 'x'));
      return bTime - aTime;
    });
  }

  public convertGridviewsToDropdowns (data: any[] = []): IDropdownOption[] {
    return data?.map(gridView => {
      return {
        key: gridView.gridViewId,
        human: gridView.gridViewName,
      } as IDropdownOption;
    });
  }

  public constructRedirectInfo ({
    filterDetails = null,
    filters = [],
    gridViewId,
    type = null,
  }: {
    filterDetails?: string[];
    filters?: IGridViewFilter[];
    gridViewId: string;
    type?: string;
  }): IRedirectInfo {
    return {
      filterDetails,
      filters,
      gridViewId,
      type,
    };
  }

  public navigateToGridView ({
    originInfo,
    queryParamsHandling = '',
    redirectInfo,
    schoolId,
  }: {
    originInfo: INavToGridInfo;
    queryParamsHandling: QueryParamsHandling;
    redirectInfo: IRedirectInfo;
    schoolId: string;
  }): void {
    this.trackNavToGridViewEvent(originInfo);
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/data-grid`);
    const redirectInfoHash = this.objectCache.cacheObject(redirectInfo);
    this.router.navigate([url], { queryParams: { state: redirectInfoHash }, queryParamsHandling });
  }

  public trackNavToGridViewEvent (originInfo: INavToGridInfo) {
    const event = this.eventFormatterService.getNavToGridEvent({ ...originInfo, portal: 'SCHOOL' });
    this.mixPanelService.trackEvents([event]);
  }

  public getGridViewId ({ school, focusKey }: { school: ISchool; focusKey: string }): string {
    const gridType = this.imSchool.getGridType(school);
    const { district } = school;
    let gridViewMappingConstant: any;
    if (district === District.LANSING) {
      gridViewMappingConstant = GRIDVIEW_MAPPING_LANSING;
    } else {
      gridViewMappingConstant = GRIDVIEW_MAPPING;
    }
    let gridviewId = gridViewMappingConstant.find(x => {
      let match = false;
      if (x.foci === focusKey && x.gridType.includes(gridType)) {
        match = true;
        if (x.districts) {
          match = x.district.includes(district);
        }
      }
      return match;
    })?.gridViewId;

    if (!gridviewId) {
      gridviewId = gridViewMappingConstant.find(
        x => x.foci === 'ACTIVE_ONLY_STUDENTS' && x.gridType.includes(gridType),
      )?.gridViewId;
    } // go to portal default if cannot find focusKey for that schooltype
    return gridviewId;
  }

  public getFilterFromFilterMap (filterKey?: string): IGridViewFilter[] {
    if (!filterKey) return [];
    const filterSet = GRIDVIEW_FILTER_MAPPING.find(x => x.filterKey === filterKey)?.filters || [];
    const filters = filterSet?.map(filter => {
      const filterObj = {
        colId: '', // if filterValue = 'ALL', no colId
        values: [] as string[],
        filterType: 'set',
      } as IGridViewFilter;
      const apply = filter?.applyForFilter ? filter.applyForFilter : 'true';
      const isFilterValueArray = Array.isArray(filter.filterValue);
      if (apply && (isFilterValueArray || filter.filterValue?.toUpperCase() !== 'ALL')) {
        filterObj.colId = filter.filter;
        filterObj.values = isFilterValueArray ? filter.filterValue : [filter.filterValue];
        if (filter.filterDetails) filterObj.filterDetails = filter.filterDetails;
      }
      return filterObj;
    });
    return filters;
  }

  public getFilterDetailsFromFilters (filterSet: IGridViewFilter[]): string[] {
    const filterDetails = [];
    filterSet.forEach(fil => {
      if (fil.filterDetails) filterDetails.push(fil.filterDetails);
    });
    return filterDetails;
  }

  public getAllFilters ({
    populationFilter,
    includeFocusFilters = true,
    focusFilterKey,
  }: {
    populationFilter: string;
    includeFocusFilters?: boolean;
    focusFilterKey?: string;
  }): IGridViewFilter[] {
    const filtersFromFilterKey =
      focusFilterKey && includeFocusFilters ? this.getFilterFromFilterMap(focusFilterKey) : [];
    const filtersFromPopFilter = this.getFilterFromFilterMap(populationFilter);
    const allFilters = filtersFromPopFilter.concat(filtersFromFilterKey);
    return allFilters;
  }

  public getUrl (contextPartnerType: TValidPartnerTypes, contextPartnerId: string): string {
    const url = contextPartnerId
      ? this.urlPathService.computeDistrictUrlPath(`${contextPartnerType}/${contextPartnerId}/student`)
      : this.urlPathService.computeDistrictUrlPath(`${contextPartnerType}/student`);

    return url;
  }

  public getNetworkExternalFilterOptions (
    contextPartnerId: string,
    contextPartnerType: TValidPartnerTypes,
  ): Observable<any> {
    // We are temporarily removing this with the intent to bring it back in the future
    // https://newvisions.atlassian.net/browse/SDC40-1167
    // if (contextPartnerType === PartnerTypes.SCHOOL_NETWORK) {
    //   return this.networkGridData.getGridViewOptions$(contextPartnerId);
    // }
    return of(null);
  }
}
