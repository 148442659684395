import { reduce, camelCase, has, orderBy } from 'lodash';
/* eslint-disable camelcase  */
import { ImCachedObject } from './../../../shared/services/im-models/im-cached-object.service';
import { IFormattedMadlibOptions } from './../network-mid-level.models';
import { FormGroup, FormControl } from '@angular/forms';

import { map, tap } from 'rxjs/operators';
import { ApiService } from './../../../shared/services/api-service/api-service';
import { Injectable } from '@angular/core';
import { Observable, of, asyncScheduler } from 'rxjs';
import {
  IRawNetworkMadlibData,
  INetworkMidLevelFilterOption,
  INetworkMidLevelFilterOptionHash,
} from '../network-mid-level.models';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { MixpanelService } from 'Src/ng2/shared/services/mixpanel/mixpanel.service';
import { PORTAL_TYPES } from 'Src/ng2/shared/typings/interfaces/portal.interface';

export interface IRawNetDashDropdownOption {
  key: string;
  label: string;
  category: string;
  subcategory?: string;
}

export interface IDefaultFiltersMap { 
  [filterKey: string]: string | string[];
}

interface INetworkGroupDataParams {
  focus: string;
  filters: { label: string; values: string[]; }[],
  group: { key: string; label: string; },
  secondaryGroup: { key: string; label: string; },
}

type TCustomSortPredicate = (sortIndex: Record<string, number>) => (o: { data: string; meta: string }[]) => string;

/* istanbul ignore next */
@Injectable()
export class NetworkMidLevelDataService {
  private groupCache = {};
  private madlibCache;

  constructor (
    public apiService: ApiService,
    private imObjectHash: ImCachedObject,
    private mixpanelService: MixpanelService,
  ) {}

  static sortRollupListData (
    list: any[],
    columns: any,
    sort: { key: string; direction: 'asc' | 'desc', customPredicate?: TCustomSortPredicate },
  ) {
    const columnIndexHash = columns.reduce((hash: { [key: string]: number }, { key }, index: number) => {
      hash[key] = index;
      return hash;
    }, {});

    const sortIndex = columnIndexHash[sort.key];

    // default predicate sorts lexographically, as it was originally defined
    const defaultPredicate = o => o[sortIndex].data;
    const customPredicate = sort.customPredicate ? sort.customPredicate(sortIndex) : null;
    const orderByPredicate = customPredicate || defaultPredicate;
    return orderBy(list, o => orderByPredicate(o), [sort.direction]);
  }

  public getNetworkGroupData$ ({ clusterId, focus, filters, group, secondaryGroup }, filterHash: INetworkMidLevelFilterOptionHash, groupHash) {
    const modelHash = this.imObjectHash.createHash({ clusterId, focus, filters, group, secondaryGroup });
    const formattedFocus = focus.toLowerCase();
    const formattedFilters = this.getFormattedFilters(filters, filterHash);

    const { column_name, label } = groupHash[group];
    const formattedGroup = {
      key: column_name,
      label,
    };

    let formattedSecondaryGroup;

    if (groupHash[secondaryGroup]) {
      const { column_name: secondaryColName, label: secondaryLabel } = groupHash[secondaryGroup];
      formattedSecondaryGroup = {
        key: secondaryColName,
        label: secondaryLabel,
      }
    } else {
      formattedSecondaryGroup = null;
    }

    if (!this.groupCache[modelHash]) {
      // Does a proxy make more sense here? Or is the naive solution sufficient for now?
      if (Object.keys(this.groupCache).length > 10) this.groupCache = {};

      return this.apiService
        .getStudentsGraphQL(this.getNetworkGroupDataPayload(clusterId, formattedFocus, formattedFilters, formattedGroup, formattedSecondaryGroup))
        .pipe(
          map(({ data: { NetworkMidLevelGrouping } }) => NetworkMidLevelGrouping),
          tap(groupData => (this.groupCache[modelHash] = groupData)),
        );
    } else {
      this.trackNetworkMidlevelGroupingEvents({ focus: formattedFocus, filters: formattedFilters, group: formattedGroup, secondaryGroup: formattedSecondaryGroup });
      return of(this.groupCache[modelHash], asyncScheduler);
    }
  }

  getNetworkMadlibData$ (clusterId: string): Observable<IRawNetworkMadlibData> {
    if (!this.madlibCache) {
      return this.apiService.getStudentsGraphQL(this.getNetworkMadlibQuery(clusterId)).pipe(
        map(({ data: { NetworkMidLevelMadlib } }) => NetworkMidLevelMadlib),
        tap(madlibData => (this.madlibCache = { ...madlibData, clusterId })),
      );
    } else return of(this.madlibCache, asyncScheduler);
  }

  getNetworkMadlibQuery (clusterId) {
    const validatedClusterId = clusterId ? `"${clusterId}"` : 'null';
    const query = `{
      NetworkMidLevelMadlib(
        clusterId: ${validatedClusterId}
      ) {
        fociOptions {
          key
          label
          category
          subcategory
          category_order
          focus_order
          mid_level_column
          sort
          ems
          hs
          hst
        }
        filterOptions{
          key
          label
          filter_order
          category
          filter_type
          filter_logic
          column_name
          filter_values
          min
          max
        }
        groupOptions {
          key
          column_name
          label
          group_order
          category
          category_order
        }
        secondaryGroupOptions {
          key
          label
        }
      }
    }`;
    return { query, fetchPolicy: 'no-cache' };
  }

  public getFormattedFilters (filters: { [key: string]: Array<string> }, filtersHash: INetworkMidLevelFilterOptionHash) {
    return reduce(
      filters,
      (acc, userSelection, key) => {
        if (!filtersHash[key]) return acc;
        const { filter_type, column_name, filter_values, filter_logic, label } = filtersHash[key];
        // If everything is selected it is equivalant to no filter at all
        if (!userSelection || (filter_type === 'multiselect' && userSelection.length === filter_values.length)) { return acc; } else acc.push({ key, label, column_name, filter_type, filter_logic, values: userSelection });
        return acc;
      },
      [],
    );
  }

  // We should reconsider how we are storing those filters in PG and remove this complexity
  // And currently the hash can't reference the column bc params don't map to a column so the pg value is null
  public getDefaultFilters (
    filters_to_apply: string,
    filterOptionHash: { [key: string]: INetworkMidLevelFilterOption },
  ): IDefaultFiltersMap {
    const filterStrings = filters_to_apply.split('AND');
    return filterStrings.reduce((acc: IDefaultFiltersMap, filterStr: string) => {
      const inClause = /\b(in)\b/;
      const extractFromInParens = /\(([^)]+)\)/;
      if (filterStr.match(inClause)) {
        const [filterColumn] = filterStr.split(inClause);
        // Why camelcase? bc the filters_to_apply in pg reference the column, but the filterHash uses the key
        acc[camelCase(filterColumn)] = extractFromInParens.exec(filterStr)[1].split(',');
      } else {
        const [filterColumn, filterValues] = filterStr.split('=');
        const trimmedValues = filterValues.trim();
        // Why camelcase? bc the filters_to_apply in pg reference the column, but the filterHash uses the key
        const filterKey = camelCase(filterColumn);
        const isMultiselect = filterOptionHash[filterKey].filter_type === 'multiselect';
        acc[filterKey] = isMultiselect ? [trimmedValues] : trimmedValues;
      }
      return acc;
    }, {});
  }

  public getTableDataShapedToMadlib$ ({ fociOptions, filterOptions, groupOptions, secondaryGroupOptions }): IFormattedMadlibOptions {
    const fociDropdownOptions = this.shapeTableDataToDropdown(fociOptions);
    const filterDropdownOptions = this.shapeFiltersToSuperFilter(filterOptions);
    const groupingDropdownOptions = this.shapeTableDataToDropdown(groupOptions);
    const secondaryGroupingDropdownOptions = secondaryGroupOptions.map(({ key, label }) => ({ key, human: label }));
    return { fociDropdownOptions, filterDropdownOptions, groupingDropdownOptions, secondaryGroupingDropdownOptions } as any;
  }

  public shapeFiltersToSuperFilter (rawFilterData: Array<INetworkMidLevelFilterOption>) {
    return rawFilterData.reduce(
      (formattedFilters, filter: INetworkMidLevelFilterOption) => {
        const { category, filter_type } = filter;
        if (filter_type === 'range') {
          const { min, max } = filter;
          formattedFilters[camelCase(category)].push({
            ...filter,
            ...this.getRangeOptions([min, max]),
          });
        } else formattedFilters[camelCase(category)].push({ ...filter, filter_search_text: new FormControl() });
        return formattedFilters;
      },
      { studentFilter: [], schoolFilter: [], shelterFilter: [] },
    );
  }

  public shapeTableDataToDropdown (dropdownOptions: Array<IRawNetDashDropdownOption>): Array<IDropdownOption> {
    const catLookup = {};
    const subcatLookup = {};
    const formattedOptions = dropdownOptions
      .reduce((acc, { category, key, label: human, subcategory }) => {
        // Has cat been added? Add category IDropdown
        if (!has(catLookup, category)) {
          catLookup[category] = acc.length;
          acc.push({ key: category, human: category, options: [] });
        }
        // Has subcat been aded?  Create subcat IDropdown & push to respective category options array
        if (subcategory && !has(subcatLookup, subcategory)) {
          subcatLookup[subcategory] = acc[catLookup[category]].options.length;
          acc[catLookup[category]].options.push({ key: subcategory, human: subcategory, options: [] });
        }
        // Push option to proper IDropdown options array (cat or subcat)
        subcategory
          ? acc[catLookup[category]].options[subcatLookup[subcategory]].options.push({ key, human })
          : acc[catLookup[category]].options.push({ key, human });
        return acc;
      }, []);
      // if there is only one option, flatten it
    return formattedOptions.reduce((acc, option) => {
      if (option.options.length === 1) option = this.flattenSingleOptionDropdowns(option);
      acc.push(option);
      return acc;
    }, []);
  }

  private flattenSingleOptionDropdowns (unflattenedOpt: IDropdownOption): IDropdownOption {
    let currOption: IDropdownOption = {
      human: unflattenedOpt.options?.length ? unflattenedOpt.options[0].human : unflattenedOpt.human,
      key: unflattenedOpt.options?.length ? unflattenedOpt.options[0].key : unflattenedOpt.key,
      options: [] as IDropdownOption[],
    };
    if (unflattenedOpt.options && unflattenedOpt.options.length && unflattenedOpt.options[0].options?.length) {
      if (unflattenedOpt.options[0].options?.length === 1) {
        currOption = this.flattenSingleOptionDropdowns(unflattenedOpt.options[0]);
      } else {
        unflattenedOpt.options[0].options.forEach(opt => currOption.options.push(this.flattenSingleOptionDropdowns(opt)));
      }
    }
    return currOption;
  }

  public getOptionHash (options) {
    return options.reduce((hash, option) => {
      hash[option.key] = option;
      return hash;
    }, {});
  }

  public getInitialFilters (madlibData: IRawNetworkMadlibData): { [key: string]: Array<string> } {
    return madlibData.filterOptions.reduce((acc, { key, filter_type, filter_values }) => {
      acc[key] = filter_type === 'multiselect' ? [...filter_values] : null;
      return acc;
    }, {});
  }

  private getRangeOptions ([min, max]: Array<number>) {
    const rangeForm = new FormGroup({ sliderControl: new FormControl([min, max]) });
    const rangeOptions = {
      floor: min,
      ceil: max,
      step: 1,
      hideLimitLabels: true,
      boundPointerLabels: false,
    };
    return { rangeForm, rangeOptions };
  }

  private getNetworkGroupDataPayload (clusterId, formattedFocus, formattedFilters, formattedGroup, formattedSecondaryGroup) {
    const validClusterId = clusterId ? `"${clusterId}"` : 'null';
    const query = `
      query NetworkMidLevel($filter: [NetworkDashMidLevelFilter]!, $group: NetworkDashMidlevelGroup!, $secondaryGroup: NetworkDashMidlevelGroup) {
        NetworkMidLevelGrouping(
          clusterId: ${validClusterId},
          focus:"${formattedFocus}",
          filters: $filter,
          group: $group,
          secondaryGroup: $secondaryGroup,
        ) {
          key
          label
          tooltip
          rowData {
            columnKey
            data
            meta
          }
          columnData {
            key
            label
            cellFormat
            tooltip
          }
        }
      }
    `;
    return { query, fetchPolicy: 'no-cache', variables: { filter: formattedFilters, group: formattedGroup, secondaryGroup: formattedSecondaryGroup } };
  }

  private trackNetworkMidlevelGroupingEvents = ({ focus, filters, group, secondaryGroup }: INetworkGroupDataParams): void => {
    const mixpanelEvents = filters.map(
      ({ label, values }) => {
        return {
          event: 'Viewed Network Mid-level',
          metaData: {
            focus,
            filter: label,
            options: values,
            group: group.label,
            secondaryGroup: secondaryGroup?.label || 'None',
            portal: PORTAL_TYPES.NETWORK,
          },
        };
      },
    );
    this.mixpanelService.trackEvents(mixpanelEvents as any);
  }

  public resetMidlevelCache (): void {
    this.madlibCache = null;
  }
}
