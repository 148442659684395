/* eslint-disable no-new */
import {
  AgGridEvent,
  BodyScrollEvent,
  CellClickedEvent,
  ColDef,
  Column,
  ColumnApi,
  ColumnPinnedEvent,
  ColumnState,
  DragStoppedEvent,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  Grid,
  GridApi,
  GridOptions,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  MenuItemDef,
  Module,
  RowSelectedEvent,
  SortChangedEvent,
} from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { districtsConfig } from 'Src/ng2/shared/constants/districts-config.constant';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ACTIONS_ORIGIN,
  BatchActionOriginOptions,
  IAction,
  PORTAL_ACTIONS,
  TBatchActionsOrigin,
  TPortalAction,
} from 'Src/ng2/shared/components/nv-actions/nv-actions.interface';
import { ArrowCellRenderer } from 'Src/ng2/shared/components/server-side-grid/arrow-cell-renderer/arrow-cell-renderer.component';
import { LoadingOverlayComponent } from 'Src/ng2/shared/components/server-side-grid/loading-overlay/loading-overlay.component';
import { NoRowsComponent } from 'Src/ng2/shared/components/server-side-grid/no-rows/no-rows.component';
import { SelectAllComponent } from 'Src/ng2/shared/components/server-side-grid/select-all/select-all.component';
import { StatusCellRenderer } from 'Src/ng2/shared/components/server-side-grid/status-cell-renderer/status-cell-renderer.component';
import {
  skeletonBanner,
  skeletonGridToolbar,
  skeletonIconTheme,
} from 'Src/ng2/shared/constants/skeleton-loader.constant';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IConfirmModalComponentData } from 'Src/ng2/shared/modals/confirm/confirm-modal.component';
import { INotesData } from 'Src/ng2/shared/modals/notes/notes-modal-shell.component';
import { IResultModalEventData } from 'Src/ng2/shared/modals/results/results-modal.component';
import { ITaskData } from 'Src/ng2/shared/modals/task/task-modal.component';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import {
  BackgroundJobNotificationService,
  TValidBackgroundJob,
} from 'Src/ng2/shared/services/background-job-notification-service/background-job-notification-service';
import { GraphQLGridViewHelperService } from 'Src/ng2/shared/services/graphql-helpers/gridviews/gridviews-queries.service';
import {
  IGridViewMetadata,
  getServerSideGridViewEvent,
} from 'Src/ng2/shared/services/mixpanel/event-trackers/server-side-grid';
import { MixpanelService } from 'Src/ng2/shared/services/mixpanel/mixpanel.service';
import { ServerSideGrid } from 'Src/ng2/shared/services/server-side-grid/server-side-grid.service';
import { SnackBarService } from 'Src/ng2/shared/services/snackbar/snackbar.service';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
import { SessionStorageService } from 'Src/ng2/shared/services/web-storage/session-storage/session-storage.service';
import { EVENT_NAMES, EVENT_TYPES, TEventType } from 'Src/ng2/shared/typings/interfaces/mixpanel.interface';
import { IValidNoteModes, VALID_NOTE_MODES } from 'Src/ng2/shared/typings/interfaces/note.interface';
import { PartnerTypes, TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { PORTAL_TYPES, TPortalLocation } from 'Src/ng2/shared/typings/interfaces/portal.interface';
import { TGridShelter } from 'Src/ng2/shared/typings/interfaces/shelter.interface';
import { ValidTaskModeType } from 'Src/ng2/shared/typings/interfaces/task.interface';
import { camelCase, cloneDeep, isEqual, orderBy, unionBy } from 'lodash';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import Rollbar from 'rollbar';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  Unsubscribable,
  forkJoin,
  from,
  merge,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { ISidebarItem } from '../../../nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
import { IMenuItem } from '../../../nvps-libraries/design/nv-split-button/nv-split-button.component';
import { AgTooltipComponent } from '../../shared/components/ag-grid/ag-tooltip/ag-tooltip.component';
import { PortalActionsService } from '../../shared/components/nv-actions/nv-actions.service';
import { IPageBannerMeta } from '../../shared/components/page-banner/page-banner.interface';
import { StatusBarComponent } from '../../shared/components/server-side-grid/status-bar/status-bar.component';
import { SpinnerModalComponent } from '../../shared/components/spinner/spinner-modal.component';
import { SpinnerService } from '../../shared/components/spinner/spinner-modal.service';
import { IEditGridColumnsModalComponentData } from '../../shared/modals/edit-grid-columns/edit-grid-columns-modal.component';
import { IStudentReportModalData, ModalsService } from '../../shared/modals/modals.service';
import { CsvExporterService } from '../../shared/services/csv-exporter/csv-exporter.service';
import { ImSchool } from '../../shared/services/im-models/im-school';
import { ImShelter } from '../../shared/services/im-models/im-shelter';
import { ImUser } from '../../shared/services/im-models/im-user';
import { ObjectCache } from '../../shared/services/object-cache/object-cache.service';
import { PortalConfig } from '../../shared/services/portal-config';
import { IUrlNavigateOpts, PrevStateService } from '../../shared/services/prev-state/prev-state.service';
import { District, TDistricts } from '../../shared/typings/interfaces/district.interface';
import { IGridView, IGridViewFilter } from '../../shared/typings/interfaces/grid-view.interface';
import { ISchool, TGrid } from '../../shared/typings/interfaces/school.interface';
import { IUser } from '../../shared/typings/interfaces/user.interface';
import {
  ISelectedStudentIdData,
  ToggleBatchActions,
  UpdateSelectedAction,
  UpdateSelectedStudentIds,
  getBatchActionsState,
  getCurrentUser,
  getGridDataNextTermPlanningRunning,
  getGridDataScrollLeft,
  getGridDataScrollTop,
  getSchool,
} from '../../store';
import { ClearGridData, SaveGridData } from '../../store/actions/grid-actions';
import { ArrowTrendCellRenderer } from './../../shared/components/server-side-grid/arrow-trend-cell-renderer/arrow-trend-cell-renderer.component';
import { IconCellRenderer } from './../../shared/components/server-side-grid/icon-cell-renderer/icon-cell-renderer.component';
import { RollbarService } from './../../shared/services/rollbar/rollbar.service';
import {
  GridTableControlActionType,
  GridTableControlButtonType,
} from './grid-table-controls/grid-table-controls.component';
import { GridToolbarComponent, IGridToolbarButton } from './grid-toolbar/grid-toolbar.component';
import { ISortModel, ServerSideGridService } from './services/server-side-grid.service';

/* eslint-disable no-unused-vars */
export enum Density {
  Dense = 'density-toggle-dense',
  Comfortable = 'density-toggle-comfortable',
}

export enum GridSidenavIcon {
  Expand = 'expand-sidebar-blue',
  Collapse = 'collapse-sidebar-blue',
}

export enum GridIconMenuActions {
  Save = 'save',
  Delete = 'delete',
  Rename = 'rename',
  Revert = 'revert',
  Create = 'create',
}

const EXPANDED = 'expand_ed_multi_school';
const CUNY = 'cuny_multi_school';

@unsubscribeComponent
@Component({
  selector: 'server-side-grid',
  templateUrl: './server-side-grid.component.html',
  styleUrls: ['./server-side-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServerSideGridComponent implements OnInit {
  private DENSE_ROW_HEIGHT = 32 + 1; // need the plus 1 to account for the border
  private COMFORTABLE_ROW_HEIGHT = 48 + 1; // need the plus 1 to account for the border

  public modules: Module[] = [
    ClipboardModule,
    CsvExportModule,
    ExcelExportModule,
    MenuModule,
    RangeSelectionModule,
    ServerSideRowModelModule,
    SetFilterModule,
    MultiFilterModule,
    StatusBarModule,
  ];

  public gridToolbarButtons: IGridToolbarButton[] = [
    {
      name: 'Density',
      icon: Density.Comfortable,
      size: 'small',
      type: 'secondary',
      action: this.onDensityToggle.bind(this),
    },
    {
      name: 'Export CSV',
      icon: 'download-small-blue',
      size: 'small',
      type: 'secondary',
      action: this.onExportCsv.bind(this),
    },
  ];

  protected AUTO_SAVE_PENDING_TEXT = 'Saving...';
  protected AUTO_SAVE_PENDING_TOOLTIP = '';

  protected AUTO_SAVE_SUCCESS_TEXT = 'All changes saved';
  protected AUTO_SAVE_SUCCESS_TOOLTIP = 'Changes to your views auto-save.';

  protected UNSAVED_CHANGES_TEXT = 'Unsaved changes';
  protected UNSAVED_CHANGES_TOOLTIP =
    'Changes to Portal views do not auto-save. To save your changes, click the Save To My Views button in the toolbar.';

  private classColumnExists: boolean = true;
  private defaultSortByClass: ISortModel[];
  private defaultSortByGrade: ISortModel[];
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  private gridType: TGrid | TGridShelter;
  private gridViewChange: boolean = false;
  private gridViewSkips: number = 0;
  private loadedConfig$ = new BehaviorSubject(false);
  private lockedColumns: ColDef[];
  private publicConfig: PortalConfig['publicConfig'];
  private scrollLeft: number;
  private scrollTop: number;
  private selectedRows: any[];
  private selectedStudents: IStudentReportModalData['students'];
  private skipUpdateColumnDefs: boolean = true;
  private spinner: MatDialogRef<SpinnerModalComponent>;
  private state: any;
  private time: number = 0;
  private unlockedColumns: ColDef[];
  private updatedRequest: IServerSideGetRowsRequest;
  public allFilterTerms: string[] = [];
  public autoSaveIcon: string = 'check-circle-small-white';
  public autoSaveText: string = this.AUTO_SAVE_SUCCESS_TEXT;
  public autoSaveTooltip: string = '';
  public unsavedChangesTooltip: string = this.UNSAVED_CHANGES_TOOLTIP;
  public unsavedChangesIcon: string = 'warning-small-white';
  public unsavedChangesText: string = this.UNSAVED_CHANGES_TEXT;
  public batchActionMode: boolean = false;
  public columnDefs: ColDef[];
  public customViewIconTooltip: string =
    'Create your own views from scratch, or by making a copy of an existing view. Your changes will auto-save.';

  public disableToolButtons: boolean = true;
  public exitState: IUrlNavigateOpts;
  public filterFormControl: FormControl;
  public gridOptions: GridOptions;
  public gridTableControlData$: Subject<any> = new Subject();
  public isCustomView: boolean = false;
  public isSidenavClosed: boolean = false;
  public myViewsListData: ISidebarItem[] = [];
  public noCustomViewText: string = 'Create your first custom view by clicking "New"';
  public numFilters: number = 0;
  public onSaveGridView$: Subject<null> = new Subject();
  public onSaveGridViewSub: Unsubscribable;
  public openSidebarItems: string[] = [];
  public pageBannerMeta: IPageBannerMeta = {};
  public portalViewIcon: string = 'info-small-hover';
  public portalViewIconTooltip: string =
    'Portal views are templates that are the same for all users. Your changes will not auto-save. To save your changes, click the Save To My Views button in the toolbar.';

  public portalViewsListData: ISidebarItem[] = [];
  public rowModelType: 'serverSide' = 'serverSide';
  public searchBoxPlaceHolder: string = 'Search by name or ID';
  public selectedGridViewKey: string;
  public sidenavClosedMargin: number = 81;
  public sidenavOpenMargin: number = 251;
  public sidevavIcon: GridSidenavIcon = GridSidenavIcon.Collapse;
  public visibleOptionsLimit: number = 5;

  // Props for batch action menu
  public currentUser: IUser;
  public school: ISchool;
  public userCanEdit: boolean;
  public contextPartnerType: TValidPartnerTypes = PartnerTypes.SCHOOL;
  public portalType: PORTAL_TYPES = PORTAL_TYPES.SCHOOL;
  public contextPartnerId: string;
  public uniqueRowId: 'CARES_ID' | 'OSIS_NUMBER' | 'dbn' | 'school' | 'shelter_id';
  public batchActionOrigin: TBatchActionsOrigin;
  public subLocationId: string | null = null;

  // Props for Portal Actions
  public gridActions: any = [];
  public selectedAction: IAction;

  // Props for edit columns modal
  public categories: string[];
  public tags: string[];
  public portalOrigin: TPortalLocation;
  private columnUpdateComplete = new BehaviorSubject<boolean>(false);

  // Props to update view
  public isOriginNetworkStudentLevel: boolean;
  public isOriginShelterNetworkStudentLevel: boolean;

  public isSelectAll: boolean = false;
  public isInDeterminateSelectAll: boolean = false;

  private totalFilteredRowCount: number = 0;
  private numRowsSelected: number = 0;
  public unselectedStudentList = new Map();

  // SubSinks
  public filterSub: Unsubscribable;
  public notificationServiceSub: Unsubscribable;
  public afterCloseResultsModalServiceSub: Unsubscribable;
  public backgroundJobDependencies: TValidBackgroundJob[] = [
    'BulkStudentNoteCreate',
    'BulkStudentTaskCreate',
    'BulkStudentTaskPartialCreate',
  ];

  public originalColumnDefs: ColDef[];
  public isGridViewChanged: boolean = false;
  public batchActionSub: Unsubscribable;

  private gridViewsByUser: any;
  private selectedGridViewConfig: IGridView;
  private gridViewColumnDefs: any[];
  public gridViewOptions: IDropdownOption[];
  private flattenedGridViewOptions: IDropdownOption[];
  public selectedGridView: IDropdownOption;
  public saveButtonName: string = 'Save changes';
  public saveMenuOptions: IMenuItem[] = [];
  public saveButtonAction: () => void;
  public confirmModalData: IConfirmModalComponentData;
  public toBeSelectedGridView: IDropdownOption;
  public deleteIconClicked: boolean = false;
  private batchActionSelectedRows = [];
  public newSkinModeIsOn: boolean;
  public isExpandEd: boolean = false;
  public isCUNY: boolean = false;
  public gridName = 'Data Grid';
  public isGridViewEnabled: boolean;
  public isLogoutEnabled: boolean;
  private contextPartnerTypesWithGridViews: string[] = [
    PartnerTypes.SCHOOL,
    PartnerTypes.SHELTER,
    PartnerTypes.SCHOOL_NETWORK,
    PartnerTypes.SHELTER_NETWORK,
  ];

  private contextPartnerTypesWithLogout: string[] = [
    PartnerTypes.CUNY,
    PartnerTypes.EXPANDED,
  ];

  // Skeleton Loaders
  public isContentLoaded: boolean = false;
  public skeletonGridToolbar = skeletonGridToolbar;
  public skeletonIconTheme = skeletonIconTheme;
  public skeletonBanner = skeletonBanner;

  public networkExternalFilterOptions: IDropdownOption[];
  public activeNetworkExternalFilterOptions: string = 'All Students';
  private networkExternalFilter$ = new Subject();

  constructor (
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,
    private csvExporterService: CsvExporterService,
    private el: ElementRef,
    private gridViewHelper: GraphQLGridViewHelperService,
    private imSchool: ImSchool,
    private imShelter: ImShelter,
    private imUser: ImUser,
    private mixpanelService: MixpanelService,
    private modalsService: ModalsService,
    private notificationService: BackgroundJobNotificationService,
    private objectCache: ObjectCache,
    private portalActionsService: PortalActionsService,
    private portalConfig: PortalConfig,
    private prevStateService: PrevStateService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private ssgService: ServerSideGridService,
    private ssrm: ServerSideGrid,
    private store: Store<any>,
    private urlPathService: UrlPathService,
  ) {
    this.publicConfig = this.portalConfig.publicConfig;
    this.defaultSortByClass = [
      { colId: 'STATUS', sort: 'asc' },
      { colId: 'CLASS', sort: 'asc' },
      { colId: 'STUDENT_NAME', sort: 'asc' },
    ];
    this.defaultSortByGrade = [
      { colId: 'STATUS', sort: 'asc' },
      { colId: 'GRADE', sort: 'desc' },
      { colId: 'STUDENT_NAME', sort: 'asc' },
    ];
  }

  ngOnInit (): void {
    this.isContentLoaded = false;

    this.gridOptions = {
      blockLoadDebounceMillis: 30, // allows to skip blocks without loading them
      cacheBlockSize: 50,
      maxBlocksInCache: 10,
      getRowId: ({ data }) => this.getRowId(data, this.contextPartnerType),
      animateRows: true,
      defaultColDef: {
        menuTabs: ['filterMenuTab', 'generalMenuTab'], // Set the order of menu tabs (filter options tab appears first left to right)
        tooltipComponent: 'agCustomTooltip',
      },
      enableRangeSelection: true,
      components: {
        ArrowCellRenderer,
        ArrowTrendCellRenderer,
        IconCellRenderer,
        LoadingOverlayComponent,
        StatusCellRenderer,
        agCustomTooltip: AgTooltipComponent,
        gridToolbarComponent: GridToolbarComponent,
        noRowsComponent: NoRowsComponent,
        selectAllComponent: SelectAllComponent,
        statusBarComponent: StatusBarComponent,
      },
      getContextMenuItems: this.onGetContextMenuItems,
      headerHeight: 72,
      maintainColumnOrder: true,
      serverSideInfiniteScroll: true, // sets serverSideStoreType to partial
      suppressDragLeaveHidesColumns: true,
      suppressMultiRangeSelection: true,
      suppressPropertyNamesCheck: this.shouldSuppressPropertyNamesCheck(),
      suppressRowClickSelection: true,
      tooltipShowDelay: 500, // default is 2000 ms
      statusBar: {
        statusPanels: [
          { statusPanel: 'statusBarComponent', align: 'left' },
          { statusPanel: 'agAggregationComponent', align: 'left' },
          {
            statusPanel: 'gridToolbarComponent',
            key: 'toolbarComponentKey',
            align: 'right',
            statusPanelParams: { context: { componentParent: this } },
          },
        ],
      },
      noRowsOverlayComponent: 'noRowsComponent',
      noRowsOverlayComponentParams: {
        parentComponent: this,
        parentName: 'server-side-grid',
      },
      loadingOverlayComponent: 'LoadingOverlayComponent',
      context: { parentComponent: this },
    };

    // Check if this.contextPartnerType is 'school' before including it in forkJoin
    this.contextPartnerType = this.route.snapshot.data.contextPartnerType;
    this.searchBoxPlaceHolder = this.getSearchBoxPlaceHolder(this.route.snapshot.data.contextPartnerType);
    this.isGridViewEnabled = this.getGridViewEnabled();
    const schoolObservable$ =
      this.contextPartnerType === PartnerTypes.SCHOOL ? this.store.select(getSchool).pipe(first()) : of(null);

    forkJoin({
      school: schoolObservable$,
      scrollLeft: this.store.select(getGridDataScrollLeft).pipe(first()),
      scrollTop: this.store.select(getGridDataScrollTop).pipe(first()),
      state: this.getState$(),
      user: this.store.select(getCurrentUser).pipe(first()),
    })
      .pipe(
        switchMap(({ school, scrollLeft, scrollTop, state, user }) => {
          this.scrollLeft = scrollLeft;
          this.scrollTop = scrollTop;
          this.setRowHeight(state?.isDenseRowHeight);
          this.state = state;
          this.setUserState(user);
          this.setPartnerState(school);
          this.setGridName();
          this.setExitState();
          return forkJoin({
            columnDefs: this.ssgService.getGridConfig$({
              contextPartnerType: this.contextPartnerType,
              gridType: this.gridType,
              contextPartnerId: this.contextPartnerId,
            }),
            gridViewOptions: this.getGridViewOption$(),
            gridActions: this.portalActionsService.getPortalActionsConfigs$({
              origin: this.batchActionOrigin,
              contextPartnerType: this.contextPartnerType,
              contextPartnerId: this.contextPartnerId,
            }),
            networkExternalFilterOptions: this.ssgService.getNetworkExternalFilterOptions(
              this.contextPartnerId,
              this.contextPartnerType,
            ),
          });
        }),
        tap(({ gridViewOptions, columnDefs, gridActions, networkExternalFilterOptions }) => {
          this.columnDefs = columnDefs;
          this.originalColumnDefs = columnDefs;
          this.classColumnExists = this.columnDefs?.some(col => col.field === 'CLASS');
          this.gridActions = gridActions;
          this.networkExternalFilterOptions = networkExternalFilterOptions;

          const cols = this.columnDefs.reduce(
            (acc, c) => {
              if (!c.lockPinned) {
                acc.unlockedColumns.push(c);
              } else {
                acc.lockedColumns.push(c);
              }
              return acc;
            },
            { lockedColumns: [], unlockedColumns: [] },
          );
          this.unlockedColumns = cols.unlockedColumns;
          this.lockedColumns = cols.lockedColumns;
          this.setEditColumnsState(columnDefs);
          this.processGridViewOptions(gridViewOptions, this.state?.gridViewId);
          this.trackGridViewEvents(EVENT_TYPES.VIEWED, this.selectedGridViewConfig);
          this.loadedConfig$.next(true);
        }),
      )
      .subscribe();

    this.notificationServiceSub = this.notificationService
      .getMessage()
      .pipe(
        filter(({ backgroundJob, metaData }) => {
          // refresh on screen after updates are complete & before batch action message is displayed
          this.gridApi?.refreshServerSide({ purge: false });
          this.snackBarService.showBatchActionResult(backgroundJob, metaData);
          return this.backgroundJobDependencies.includes(backgroundJob);
        }),
      )
      .subscribe();

    this.afterCloseResultsModalServiceSub = this.snackBarService.actionEvent$.subscribe(
      (eventData: IResultModalEventData) => {
        // If the user wants to View X students, it takes the user back to the current grid with an additional filtered applied on the Student ID column for the students who failed.
        const failedStudentIds = eventData?.failedStudentIds;
        if (failedStudentIds && failedStudentIds.length) {
          const filter = {
            OSIS_NUMBER: {
              filterType: 'set',
              values: failedStudentIds.map(id => id.replace(this.contextPartnerId, '')), // Convert student id into OSIS_NUMBER
            },
          };
          this.gridApi.setFilterModel(filter);
          this.gridApi.onFilterChanged();
        }
      },
    );

    this.confirmModalData = {
      title: 'Leave without saving?',
      // eslint-disable-next-line
      message: `You have unsaved changes in the current view. If you switch to another view, your changes will be lost. Click <span class="bold">Stay on View</span> to stay on the page and save your changes. Click <span class="bold">Leave View</span> to continue without saving.`,
      confirmText: 'Leave View',
      cancelText: 'Stay on View',
      hasWarningIcon: true,
    };

    this.batchActionSub = this.store.select(getBatchActionsState).subscribe(batchActionState => {
      this.batchActionSelectedRows = batchActionState.selectedStudentIds;
      this.selectedAction = batchActionState.selectedAction;
    });

    this.filterSub = merge(this.getNetworkExternalFilter$(), this.getSearchBoxFilter$())
      .pipe(
        tap(() => {
          this.saveToUrl();
          this.gridApi?.refreshServerSide({ purge: true });
        }),
      )
      .subscribe();

    this.onSaveGridViewSub = this.onSaveGridView$
      .pipe(
        filter(() => this.isGridViewChanged && this.isCustomView),
        tap(() => this.setAutoSaveToSavingState()),
        debounceTime(1500), // can be adjusted
        switchMap(() => this.autoSaveGridView$()),
        catchError((_, caught) => {
          this.setAutoSaveToConnectingState();
          return caught;
        }),
        tap(() => this.setAutoSaveToSavedState()),
      )
      .subscribe();
  }

  private getSearchBoxPlaceHolder (partnerType: TValidPartnerTypes) {
    switch (partnerType) {
      case PartnerTypes.CUNY:
      case PartnerTypes.EXPANDED:
      case PartnerTypes.HYBRID:
      case PartnerTypes.SCHOOL:
      case PartnerTypes.SCHOOL_NETWORK:
        return 'Search by name or ID';
      case PartnerTypes.SHELTER: {
        return 'Search by name or OSIS';
      }
      case PartnerTypes.SHELTER_NETWORK:
        return 'Search by name or FAC';
      default: {
        return 'Search by name or ID';
      }
    }
  }

  private getRowId (data: any, partnerType: TValidPartnerTypes): string {
    switch (partnerType) {
      case PartnerTypes.CUNY:
      case PartnerTypes.EXPANDED:
      case PartnerTypes.HYBRID:
      case PartnerTypes.SCHOOL:
        return data.OSIS_NUMBER;
      case PartnerTypes.SCHOOL_NETWORK: {
        if (data.dbn) {
          return data.dbn;
        } else {
          return data.school;
        }
      }
      case PartnerTypes.SHELTER: {
        return data.CARES_ID;
      }
      case PartnerTypes.SHELTER_NETWORK: {
        return data.shelter_id;
      }
      default: {
        return data.OSIS_NUMBER;
      }
    }
  }

  private getSearchBoxFilter$ (): Observable<any> {
    this.filterFormControl = new FormControl();
    return this.filterFormControl.valueChanges.pipe(
      debounceTime(1000), // can be adjusted
      distinctUntilChanged(),
      map((filterTerm: string) => {
        return filterTerm
          .trim()
          .split(' ')
          .filter(ft => ft !== '');
      }),
      tap((allFilterTerms: string[]) => {
        this.allFilterTerms = allFilterTerms;
      }),
    );
  }

  private getNetworkExternalFilter$ (): Observable<any> {
    if (this.contextPartnerType === PartnerTypes.SCHOOL_NETWORK) {
      return this.networkExternalFilter$.asObservable();
    } else {
      return EMPTY;
    }
  }

  public onNetworkExternalFilterOptionChange (option: IDropdownOption): void {
    const { key } = option;
    this.activeNetworkExternalFilterOptions = key;
    this.networkExternalFilter$.next(key);
  }

  public autoSaveGridView$ (): any {
    const saveGridViewConfig = this.getModifiedGridViewToSave(false);
    return this.ssgService
      .updateGridView$(saveGridViewConfig, this.contextPartnerType)
      .pipe(tap((_: any) => this.handleSuccessfulSave(saveGridViewConfig, EVENT_TYPES.UPDATED)));
  }

  private setAutoSaveToSavingState (): void {
    if (this.autoSaveText === this.AUTO_SAVE_SUCCESS_TEXT) {
      this.autoSaveIcon = 'auto-save-small-white';
      this.autoSaveText = this.AUTO_SAVE_PENDING_TEXT;
      this.autoSaveTooltip = this.AUTO_SAVE_PENDING_TOOLTIP;
    }
  }

  private setAutoSaveToSavedState (): void {
    this.autoSaveIcon = 'check-circle-small-white';
    this.autoSaveText = this.AUTO_SAVE_SUCCESS_TEXT;
    this.autoSaveTooltip = this.AUTO_SAVE_SUCCESS_TOOLTIP;
  }

  private setAutoSaveToConnectingState (): void {
    this.autoSaveIcon = 'warning-small';
    this.autoSaveText = 'Trying to connect...';
    this.onSaveGridView$.next(null);
  }

  public updateIsCustomView (): void {
    this.isCustomView = this.myViewsListData.some(ld => {
      return ld.key === this.selectedGridViewKey;
    });
  }

  private initSidenav (data: any[]) {
    const customViews = data
      .filter(gv => gv.gridViewType === 'custom')
      .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
      .map(gv => {
        return { gridViewId: gv.gridViewId, gridViewName: gv.gridViewName };
      });
    const adminViews = data
      .filter(gv => gv.gridViewType === 'admin')
      .sort((a, b) => a.order - b.order)
      .map(gv => {
        return { gridViewId: gv.gridViewId, gridViewName: gv.gridViewName, parentCategory: gv.parentCategory };
      });
    this.setMyViewsListData(customViews);
    this.setPortalViewsListData(adminViews);
  }

  private setPortalViewsListData (adminViews: any[]) {
    this.portalViewsListData = adminViews.reduce((acc, av) => {
      const option = {
        human: av.gridViewName,
        key: av.gridViewId,
        rightHoverIcon: 'more-small-blue',
        rightHoverIconOptions: [
          { human: 'Save to my views', key: GridIconMenuActions.Save },
          { human: 'Revert changes', disabled: true, key: GridIconMenuActions.Revert },
        ],
        tooltipData: this.getSidenavTooltip(av.gridViewName),
      };

      if (av.parentCategory) {
        const index = acc.findIndex((a: any) => a.human === av.parentCategory);
        if (index <= 0) {
          const parent = {
            human: av.parentCategory,
            key: av.gridViewName,
            expandAs: 'accordion',
            children: [],
          };
          acc.push(parent);
          acc[acc.length - 1].children.push(option);
        } else {
          acc[index].children.push(option);
        }
      } else {
        acc.push(option);
      }

      return acc;
    }, []);
  }

  private setMyViewsListData (customViews: any[]) {
    this.myViewsListData = customViews.map(cv => {
      const option = {
        human: cv.gridViewName,
        key: cv.gridViewId,
        rightHoverIcon: 'more-small-blue',
        rightHoverIconOptions: [
          { human: 'Make a copy', key: GridIconMenuActions.Save },
          { human: 'Rename', key: GridIconMenuActions.Rename },
          { human: 'Delete', key: GridIconMenuActions.Delete },
        ],
        tooltipData: this.getSidenavTooltip(cv.gridViewName),
      };
      return option;
    });
  }

  private getSidenavTooltip (name: string): string | null {
    const lengthCutOff = 18;
    if (name.length >= lengthCutOff) {
      return name;
    } else {
      return null;
    }
  }

  public onSidenavClick (key: string, isLoading?: boolean): void {
    const selectedItem = this.portalViewsListData.find(av => av.key === key);
    if (selectedItem?.children) {
      const isSelectedOptionOpen = this.openSidebarItems.includes(key);
      if (isSelectedOptionOpen) {
        this.openSidebarItems = this.openSidebarItems.filter(si => si !== key);
      } else {
        this.openSidebarItems.push(key);
      }
    }

    const found = this.gridViewsByUser.find((gv: any) => {
      return gv.gridViewId === key;
    });
    if (found) {
      this.onGridViewChange({ human: found.gridViewName, key: found.gridViewId } as any, isLoading);
      // track grid view selection by the user only..
      this.trackGridViewEvents(EVENT_TYPES.VIEWED, this.selectedGridViewConfig);
    }
  }

  public onIconMenuSelect (event: { iconMenuKey: GridIconMenuActions; nodeKey: string }): void {
    const { iconMenuKey: gridIconMenuAction, nodeKey: gridViewId } = event;
    switch (gridIconMenuAction) {
      case GridIconMenuActions.Save: {
        if (this.selectedGridViewKey !== gridViewId) {
          const found = this.gridViewsByUser.find((gv: any) => {
            return gv.gridViewId === gridViewId;
          });
          this.toBeSelectedGridView = { human: found.gridViewName, key: found.gridViewId };
          this.applyGridViewChange();
        }
        this.saveAsNewGridView();
        break;
      }
      case GridIconMenuActions.Delete: {
        this.selectedGridViewKey = null;
        this.deleteGridView({ key: gridViewId });
        break;
      }
      case GridIconMenuActions.Rename: {
        if (this.selectedGridViewKey !== gridViewId) {
          const found = this.gridViewsByUser.find((gv: any) => {
            return gv.gridViewId === gridViewId;
          });
          this.toBeSelectedGridView = { human: found.gridViewName, key: found.gridViewId };
          this.applyGridViewChange();
        }
        this.updateGridView({ key: gridViewId, action: gridIconMenuAction });
        break;
      }
      case GridIconMenuActions.Revert: {
        this.clearGridViewChanges();
        break;
      }
      default: {
        break;
      }
    }
  }

  private setGridName () {
    if (this.isExpandEd) this.gridName = 'ExpandEd Data Grid';
    else if (this.isCUNY) this.gridName = 'CUNY Data Grid';
    else if (this.isOriginNetworkStudentLevel) this.gridName = `Data Grid for Students in ${this.school.nickName}`;
    else this.gridName = 'Data Grid';
  }

  private setBatchActionOrigin (): void {
    if (this.contextPartnerType === PartnerTypes.SCHOOL && this.school?._id) {
      this.batchActionOrigin = BatchActionOriginOptions.DATA_GRID;
    } else if (
      this.contextPartnerType === PartnerTypes.SHELTER ||
      this.contextPartnerType === PartnerTypes.SHELTER_NETWORK
    ) {
      this.batchActionOrigin = BatchActionOriginOptions.SHELTER_DATA_GRID;
    } else {
      this.batchActionOrigin = BatchActionOriginOptions.DATA_GRID; // ExpandEd, etc not currently separated out
    }
  }

  private getState$ (): Observable<any> {
    // if any legacy query params exist we wipe them. We only care about state and gridViewId.
    const { filter, gridViewId, isNextTermPlanningGridRunning, queryParamsHandling, redirectInfo, sort, state } =
      this.route.snapshot.queryParams;
    if (filter || gridViewId || isNextTermPlanningGridRunning || queryParamsHandling || redirectInfo || sort) {
      const queryParams = {
        filter: null,
        gridViewId: null,
        isNextTermPlanningGridRunning: null,
        queryParamsHandling: null,
        redirectInfo: null,
        sort: null,
        state: null,
      };
      this.updateQueryParams(queryParams);
    }

    // if we have gridViewId we still populate it
    if (gridViewId) {
      return from(this.objectCache.getObject(gridViewId)).pipe(
        map(gridViewId => {
          return { gridViewId };
        }),
      );
    } else if (state) {
      return from(this.objectCache.getObject(state));
    } else {
      return of(null);
    }
  }

  private updateQueryParams (queryParams: any): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  public onToggleSidenav (): void {
    this.isSidenavClosed = !this.isSidenavClosed;
    if (this.isSidenavClosed) {
      this.sidevavIcon = GridSidenavIcon.Expand;
    } else {
      this.sidevavIcon = GridSidenavIcon.Collapse;
    }
    this.saveToUrl();
  }

  public onGridReady (params: AgGridEvent): void {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    this.gridColumnApi = params.columnApi;
    this.loadedConfig$.pipe(first(loaded => loaded)).subscribe(() => {
      params.api.setServerSideDatasource({
        getRows: this.getRows.bind(this),
      });
    });
  }

  private getRows (params: IServerSideGetRowsParams): void {
    const visibleColumns = this.getAllDisplayedColumnDefs();
    this.updatedRequest = this.updateSortModel(params);
    this.updatedRequest.filterModel = this.updateFilterModel(this.updatedRequest.filterModel);
    const time = new Date().getTime();
    this.ssgService
      .getRow$({
        columnDefs: visibleColumns,
        contextPartnerId: this.contextPartnerId,
        contextPartnerType: this.contextPartnerType,
        networkExternalFilterOptions: this.activeNetworkExternalFilterOptions,
        request: this.updatedRequest,
      })
      .subscribe((data: any) => {
        const { rowData, rowCount } = this.getRowData(data, params);
        params.success({ rowData, rowCount });
        this.isContentLoaded = true;
        this.updateGridToolbar(this.isContentLoaded); // Send isContentLoaded to GridToolbarComponent
        this.updateStatusBar(rowCount, !!Object.keys(this.updatedRequest.filterModel).length, time);
        this.totalFilteredRowCount = rowCount;
        this.updateSelectedRowCount();

        if (this.batchActionSelectedRows.length) {
          this.onBatchActions(this.selectedAction);
          this.checkBatchActionBoxes();
        }
        this.updateDensity();
        this.spinner?.close();
      });
  }

  private getRowData (data: any, params: IServerSideGetRowsParams): { rowData: any; rowCount: number } {
    let rowData: any;
    let rowCount: number;
    if (
      this.contextPartnerType === PartnerTypes.SCHOOL_NETWORK ||
      this.contextPartnerType === PartnerTypes.SHELTER_NETWORK
    ) {
      [rowData, rowCount] = data;
    } else {
      rowData = data.rowData;
      rowCount = data.count;
    }
    return { rowData, rowCount };
  }

  public getMainMenuItems (params: GetMainMenuItemsParams): (string | MenuItemDef)[] {
    const itemsToInclude = ['autoSizeThis', 'pinSubMenu', 'separator'];
    const menuItems: (string | MenuItemDef)[] = params.defaultItems.filter(i => itemsToInclude.includes(i));
    menuItems.pop(); // removes final separator
    const { field: colId, lockPinned } = params.column.getColDef();

    if (!lockPinned) {
      menuItems.push({
        name: 'Hide Column',
        action: () => {
          const filteredCol = params.api.getFilterInstance(colId);
          filteredCol?.setModel(null); // remove filter
          params.columnApi.applyColumnState({
            state: [{ colId, hide: true, sort: null }],
          });
          params.api.onFilterChanged();
        },
      });
    } else {
      menuItems.pop(); // removes final separator
    }
    return menuItems;
  }

  public onClear (): void {
    this.filterFormControl.setValue('');
    this.allFilterTerms = [];
  }

  private updateSortModel (params: IServerSideGetRowsParams): IServerSideGetRowsRequest {
    const { request } = params;
    if (
      this.contextPartnerType === PartnerTypes.SCHOOL_NETWORK ||
      this.contextPartnerType === PartnerTypes.SHELTER_NETWORK
    ) {
      return request;
    }
    let newRequest: IServerSideGetRowsRequest;

    if (this.classColumnExists) {
      newRequest = { ...request, sortModel: unionBy(request.sortModel, this.defaultSortByClass, 'colId') };
    } else {
      newRequest = { ...request, sortModel: unionBy(request.sortModel, this.defaultSortByGrade, 'colId') };
    }

    // Shelter: Add subLocation Id filter if exists
    if (this.subLocationId) {
      newRequest.filterModel = {
        ...request.filterModel,
        SUB_LOCATION_ID: { filterType: 'set', values: [this.subLocationId] },
      };
    }
    return newRequest;
  }

  private updateFilterModel (filterModel: { [key: string]: any }): { [key: string]: any } {
    if (this.allFilterTerms.length) {
      return {
        ...filterModel,
        NV_SEARCH: {
          filterType: 'NV_SEARCH',
          filter: this.allFilterTerms,
        },
      };
    } else {
      return filterModel;
    }
  }

  public onFirstDataRendered (): void {
    this.setGridRouteOptions();
    this.setScrollPlacement();
  }

  private updateStatusBar (count: number, isFiltered: boolean, time: number): void {
    if (time > this.time) {
      this.time = time;
      const statusBarComponent = this.gridApi.getStatusPanel('statusBarComponent') as any;
      if (isFiltered) {
        statusBarComponent.setFilteredRows(count);
      } else {
        statusBarComponent.setTotalRows(count);
        statusBarComponent.setFilteredRows(count);
      }
      this.setNoRowsOverlay();
    }
  }

  public onGridTableControlAction (type: GridTableControlActionType): void {
    switch (type) {
      case GridTableControlActionType.CLEAR_FILTER: {
        this.onClearFilters();
        break;
      }
      case GridTableControlActionType.CLEAR_SORT: {
        this.gridColumnApi.applyColumnState({ defaultState: { sort: null } });
        break;
      }
    }
  }

  public onGridTableControlDataRequest (type: GridTableControlButtonType): void {
    const filterModel = this.gridApi.getFilterModel();
    const columnState = this.gridColumnApi.getColumnState().filter((c: ColumnState) => {
      return !c.hide;
    });
    this.gridTableControlData$.next({
      columnState,
      filterModel,
      type,
    });
  }

  private setGridRouteOptions (): void {
    let state = this.state?.columnState;
    let filters = this.state?.filters;
    const isAdminState = this.state?.type === 'admin';
    this.isSidenavClosed = this.state?.isSidenavClosed;

    if (this.isSidenavClosed) {
      this.sidevavIcon = GridSidenavIcon.Expand;
    } else {
      this.sidevavIcon = GridSidenavIcon.Collapse;
    }

    if (this.state?.allFilterTerms) {
      this.filterFormControl.setValue(this.state?.allFilterTerms.join(' '));
    }

    if (this.state?.networkExternalFilter) {
      this.onNetworkExternalFilterOptionChange({ key: this.state?.networkExternalFilter } as IDropdownOption);
    }

    this.updateIsCustomView();

    if (isAdminState) {
      this.isOriginNetworkStudentLevel = this.state.isOriginNetworkStudentLevel;
      if (this.isOriginNetworkStudentLevel && !this.isExpandEd) this.portalType = PORTAL_TYPES.NETWORK;
      if (this.isOriginShelterNetworkStudentLevel) this.portalType = PORTAL_TYPES.SHELTER_NETWORK;
      state = this.selectedGridViewConfig?.columnDefs.map(cd => {
        return {
          colId: cd.field,
          hide: false,
          pinned: cd.pinned,
          width: cd.width,
        };
      });
      filters = this.convertToFilterModel(filters);
    }

    if (state) {
      this.isGridViewChanged = true;
      this.gridViewChange = false;
      const stateChangesNum = this.getStateChangesNum(state);
      if (stateChangesNum) this.gridViewSkips = this.gridViewSkips + stateChangesNum;
      this.gridColumnApi.applyColumnState({
        applyOrder: true,
        defaultState: { hide: true },
        state,
      });
      if (filters) {
        this.gridViewSkips++;
        this.gridApi.setFilterModel(filters);
        this.filterChanged(filters);
      }
      if (isAdminState) this.autoSizeColumns();
      this.gridApi.refreshServerSide({ purge: true });
      this.setSaveMenuOptions();
      this.updateListDataIconOptions();
      this.skipUpdateColumnDefs = false;
    } else {
      this.handleClick(this.selectedGridView, false, true);
    }
  }

  private setScrollPlacement (): void {
    const gridBody = this.el.nativeElement.querySelector('.ag-body-viewport') as ElementRef;
    const gridCenter = this.el.nativeElement.querySelector('.ag-center-cols-viewport') as ElementRef;
    // This will not work until v28 of the grid because onFirstDataRendered emits too early
    // https://github.com/ag-grid/ag-grid/issues/2662
    this.renderer.setProperty(gridBody, 'scrollTop', this.scrollTop);
    this.renderer.setProperty(gridCenter, 'scrollLeft', this.scrollLeft);
  }

  public saveGridState (_: AgGridEvent | ColumnPinnedEvent | DragStoppedEvent | SortChangedEvent): void {
    this.onGridTableControlDataRequest(GridTableControlButtonType.UPDATE);
    if (this.gridViewSkips > 0) {
      this.gridViewSkips--;
      return;
    } else {
      this.gridViewSkips = 0;
    }

    const isDenseRowHeight = this.gridToolbarButtons[0].icon === 'density-toggle-dense' ? true : null;
    if (!isDenseRowHeight && !this.isSidenavClosed && this.gridViewChange && this.isMainGridView()) {
      this.gridViewChange = false;
      this.filterChanged({});
      const queryParams = { state: null };
      this.updateQueryParams(queryParams);
      return;
    }

    if (this.gridViewChange) {
      this.isGridViewChanged = false;
      this.gridViewChange = false;
    } else {
      this.isGridViewChanged = true;
    }

    this.saveModifiedStateToUrl();
    this.updateListDataIconOptions();
    this.onSaveGridView$.next(null);
  }

  private isMainGridView () {
    return (
      this.selectedGridView.key.includes('presetCUNY') ||
      this.selectedGridView.key.includes('presetDefault') ||
      this.selectedGridView.key.includes('presetExpandEd') ||
      this.selectedGridView.key.includes('presetShelterDefault')
    );
  }

  private updateListDataIconOptions (): void {
    this.portalViewsListData = this.portalViewsListData.map(this.enableDisableRevert.bind(this));
  }

  private enableDisableRevert (v: ISidebarItem): ISidebarItem {
    if (v.rightHoverIconOptions) {
      v.rightHoverIconOptions = v.rightHoverIconOptions.map(o => {
        if (o.key === GridIconMenuActions.Revert) {
          if (this.isGridViewChanged && v.key === this.selectedGridViewKey) {
            o.disabled = false;
          } else {
            o.disabled = true;
          }
        }
        return o;
      });
    } else {
      v.children = v.children.map(this.enableDisableRevert.bind(this));
    }
    return v;
  }

  private saveModifiedStateToUrl (): void {
    const columnState = this.gridColumnApi.getColumnState().filter(c => {
      return !c.hide;
    });
    const allFilterTerms = this.allFilterTerms;
    const filters = this.gridApi.getFilterModel();
    const gridViewId = this.selectedGridView.key;
    const isDenseRowHeight = this.gridToolbarButtons[0].icon === 'density-toggle-dense' ? true : null;
    const isSidenavClosed = this.isSidenavClosed;
    const networkExternalFilter =
      this.activeNetworkExternalFilterOptions === 'All Students' ? null : this.activeNetworkExternalFilterOptions;
    const subLocationId = this.subLocationId;

    const state = {
      allFilterTerms,
      columnState: this.isGridViewChanged ? columnState : null,
      filters: this.isGridViewChanged ? filters : null,
      gridViewId,
      isDenseRowHeight,
      isSidenavClosed,
      networkExternalFilter,
      subLocationId,
    };

    const stateHash = this.objectCache.cacheObject(state);

    const queryParams = { state: stateHash };
    this.updateQueryParams(queryParams);
    this.filterChanged(filters);
  }

  private saveNonModifiedStateToUrl (): void {
    const allFilterTerms = this.allFilterTerms;
    const gridViewId = this.selectedGridView.key;
    const isDenseRowHeight = this.gridToolbarButtons[0].icon === 'density-toggle-dense' ? true : null;
    const isSidenavClosed = this.isSidenavClosed;
    const networkExternalFilter =
      this.activeNetworkExternalFilterOptions === 'All Students' ? null : this.activeNetworkExternalFilterOptions;
    const subLocationId = this.subLocationId;

    const state = {
      allFilterTerms,
      columnState: null,
      filters: null,
      gridViewId,
      networkExternalFilter,
      isDenseRowHeight,
      isSidenavClosed,
      subLocationId,
    };

    const stateHash = this.objectCache.cacheObject(state);

    const queryParams = { state: stateHash };
    this.updateQueryParams(queryParams);
  }

  public onGetContextMenuItems (params: GetContextMenuItemsParams) {
    return ['copy', 'copyWithHeaders'];
  }

  public onFilterChanged (_: AgGridEvent): void {
    this.onGridTableControlDataRequest(GridTableControlButtonType.UPDATE);
    if (this.gridViewSkips > 0) {
      this.gridViewSkips--;
      return;
    } else {
      this.gridViewSkips = 0;
    }

    if (this.gridViewChange) {
      this.isGridViewChanged = false;
      this.gridViewChange = false;
    } else {
      this.isGridViewChanged = true;
    }

    this.saveModifiedStateToUrl();
    this.updateListDataIconOptions();
    this.onSaveGridView$.next(null);
  }

  public filterChanged (model: any) {
    this.numFilters = Object.keys(model).length;
    this.resetSelectedRows(true);
    if (this.batchActionMode && this.isSelectAll) this.updateSelectedRowCount();
    this.setNoRowsOverlay();
    this.ssgService.setFilterBySelectAllColumn(!!model[this.uniqueRowId]);
  }

  public onBodyScroll (params: BodyScrollEvent): void {
    const { left, top } = params;
    this.store.dispatch(new SaveGridData({ scrollLeft: left, scrollTop: top }));
  }

  public onPaginationChanged (params: any): void {
    this.selectNodes();
  }

  public onViewPortChanged (params: any): void {
    this.selectNodes();
  }

  public onRowSelected (params: RowSelectedEvent): void {
    const selectedRows = [];
    params?.api.forEachNode(node => {
      if (node.isSelected()) selectedRows.push(node.data);
    });
    this.selectedRows = selectedRows;
    this.updateSelectedRowCount();
  }

  public onSelectionChanged (params) {
    if (this.batchActionMode && this.isSelectAll) {
      params?.api?.forEachNode(node => {
        this.updateUnselectedList(node?.data, !node.isSelected());
      });
    }
  }

  private updateUnselectedList (data: any, isUnselected: boolean) {
    if (data) {
      const unselectedStudent = { [this.uniqueRowId]: data[this.uniqueRowId] };
      isUnselected
        ? this.unselectedStudentList.set(data[this.uniqueRowId], unselectedStudent)
        : this.unselectedStudentList.delete(data[this.uniqueRowId]);
      this.updateSelectedRowCount();
    }
  }

  private updateSelectedRowCount (): void {
    const selectedRowCount =
      this.isSelectAll && this.totalFilteredRowCount > 0
        ? this.totalFilteredRowCount - this.unselectedStudentList?.size
        : this.selectedRows?.length;
    this.numRowsSelected = selectedRowCount ?? 0;
    this.setCheckboxState();
  }

  private checkBatchActionBoxes (): void {
    // find selected students and check the boxes
    this.batchActionSelectedRows.forEach(({ studentId }) => {
      const currRowNode = this.gridOptions.api.getRowNode(studentId);
      currRowNode?.setSelected(true);
    });
  }

  private getAllFilteredStudents (): Observable<any> {
    if (!this.isSelectAll) {
      return this.getAllFilteredRowsCallback(of(this.selectedRows));
    } else {
      const visibleColumns = this.getAllDisplayedColumnDefs();

      let requiredColumns: any = [this.uniqueRowId];
      requiredColumns = [...new Set([...requiredColumns, ...Object.keys(this.gridApi.getFilterModel())])];

      const idColumns: any = visibleColumns.filter(col => col.field);

      const selectAllRequest: IServerSideGetRowsRequest = { ...this.updatedRequest };
      selectAllRequest.startRow = 0;
      selectAllRequest.endRow = null;

      return this.ssgService
        .getRow$({
          columnDefs: idColumns,
          contextPartnerType: this.contextPartnerType,
          request: selectAllRequest,
          contextPartnerId: this.contextPartnerId,
        })
        .pipe(
          tap({
            next: ({ rowData, count: rowCount }) => {
              this.totalFilteredRowCount = rowCount;
              this.getAllFilteredRowsCallback(rowData);
            },
            error: err => {
              this.rollbar.debug('Fetch all filtered rows for Select All: ', err);
            },
            complete: () => {},
          }),
          take(1),
          shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }
  }

  private getAllFilteredRowsCallback (filteredData: Observable<any>) {
    return of(filteredData);
  }

  private setFilteredRows (filteredData: any) {
    if (this.isSelectAll) {
      const id = this.uniqueRowId;
      this.selectedRows = filteredData?.rowData?.filter(md =>
        Array.from(this.unselectedStudentList.values()).every(fd => fd[id] !== md[id]),
      );
    }
    this.updateSelectedRowCount();
    this.selectedStudents = this.getStudents({ selectedRows: this.selectedRows });
    this.saveBatchActionState(this.selectedRows);
  }

  private resetSelectedRows (resetTotalRows: boolean) {
    this.gridApi?.deselectAll();

    this.unselectedStudentList = new Map();
    this.selectedRows = [];
    this.numRowsSelected = 0;
    this.setCheckboxState();

    this.isSelectAll = false;
    if (resetTotalRows) this.totalFilteredRowCount = 0;
  }

  public onCancel (): void {
    this.clearBatchActionState();
    this.gridApi.deselectAll();
    this.updateColumnDefs(this.nullCheckboxSelectionOptions.bind(this));
    this.gridOptions.rowSelection = 'single';
    this.batchActionMode = false;
    this.selectedAction = {} as IAction;
    this.resetSelectedRows(false);
  }

  public onClearFilters (): void {
    this.gridApi.setFilterModel(null);
    this.resetSelectedRows(true);
  }

  public onBatchActions (event: IAction): void {
    this.updateColumnDefs(this.enableCheckboxSelection.bind(this));
    this.gridOptions.rowSelection = 'multiple';
    this.batchActionMode = true;
    this.selectedAction = event;
    this.store.dispatch(new UpdateSelectedAction(this.selectedAction));
    if (!this.batchActionSelectedRows.length) this.resetSelectedRows(false);
  }

  public onGridColumnsChanged (params: AgGridEvent): void {
    params.api.refreshCells();
  }

  private enableCheckboxSelection (c: ColDef) {
    const leftMostColumnId = this.getLeftMostColumnId();
    if (c.field === leftMostColumnId) {
      c.checkboxSelection = true;
    }
    return c;
  }

  private getLeftMostColumnId (): string {
    const colState = this.gridColumnApi.getColumnState();
    const visibleColState = colState.filter(c => c.hide === false);
    let leftMostColumnId = visibleColState[0].colId;
    let i: number;
    const len: number = visibleColState.length;
    for (i = 0; i < len; i++) {
      const col = visibleColState[i];
      if (col.pinned === 'left') {
        leftMostColumnId = col.colId;
        break;
      }
    }
    return leftMostColumnId;
  }

  private getAllRowsForExport (cols: ColDef[], gridOptions: GridOptions, params: IServerSideGetRowsParams): void {
    this.spinner = this.spinnerService.openSpinner({ message: 'Exporting CSV...' });
    const colsToBeExported = { columnKeys: cols?.map(obj => obj.field) };
    this.updatedRequest.startRow = 0;
    this.updatedRequest.endRow = null;
    this.ssgService
      .getRow$({
        columnDefs: cols,
        contextPartnerId: this.contextPartnerId,
        contextPartnerType: this.contextPartnerType,
        networkExternalFilterOptions: this.activeNetworkExternalFilterOptions,
        request: this.updatedRequest,
      })
      .subscribe({
        next: (data: any) => {
          const { rowData, rowCount } = this.getRowData(data, params);
          params.success({ rowData, rowCount });
        },
        error: err => {
          this.rollbar.debug('CSV download: ', err);
          this.spinner.close();
        },
        complete: () => {
          gridOptions.api.exportDataAsCsv(colsToBeExported);
          this.spinner.close();
          const { columns, rowData, csvType } = this.csvExporterService.getGridCsvMetadata(gridOptions);
          this.csvExporterService.captureCsvMetadata(
            { fileName: 'Server side grid.csv', columns, rowData, csvType },
            'Data grid',
          );
        },
      });
  }

  public setRowHeight (isDenseRowHeight: boolean): void {
    let rowHeight: number;
    if (isDenseRowHeight) {
      rowHeight = this.DENSE_ROW_HEIGHT;
      this.gridToolbarButtons[0].icon = Density.Dense;
    } else {
      rowHeight = this.COMFORTABLE_ROW_HEIGHT;
    }
    this.gridOptions.rowHeight = rowHeight;
  }

  public onDensityToggle (): void {
    const densityToggleMap = {
      'density-toggle-comfortable': Density.Dense,
      'density-toggle-dense': Density.Comfortable,
    };
    const icon = densityToggleMap[this.gridToolbarButtons[0].icon];
    this.gridToolbarButtons[0].icon = icon;
    this.updateDensity();
    this.saveToUrl();
  }

  public updateDensity (): void {
    const densityHeightMap = {
      'density-toggle-comfortable': this.COMFORTABLE_ROW_HEIGHT,
      'density-toggle-dense': this.DENSE_ROW_HEIGHT,
    };
    const height = densityHeightMap[this.gridToolbarButtons[0].icon];

    this.gridApi.forEachNode(node => {
      node.setRowHeight(height);
    });
    this.gridApi.onRowHeightChanged();
  }

  public saveToUrl (): void {
    if (this.isGridViewChanged) {
      this.saveGridState({ api: this.gridApi, columnApi: this.gridColumnApi } as AgGridEvent);
    } else {
      const isDenseRowHeight = this.gridToolbarButtons[0].icon === 'density-toggle-dense' ? true : null;
      const isDefaultNetworkExternalFilterOption = this.activeNetworkExternalFilterOptions === 'All Students';
      if (
        !isDenseRowHeight &&
        !this.allFilterTerms.length &&
        !this.isSidenavClosed &&
        isDefaultNetworkExternalFilterOption &&
        this.isMainGridView()
      ) {
        const queryParams = { state: null };
        this.updateQueryParams(queryParams);
      } else {
        this.saveNonModifiedStateToUrl();
      }
    }
  }

  public onExportCsv (): void {
    const visibleColumns = this.getAllDisplayedColumnDefs();
    const gridDiv = this.renderer.createElement('ng-container');
    this.renderer.appendChild(this.el.nativeElement, gridDiv);

    const exportGridOptions = <GridOptions>{
      /*  NOTE: there will be an ag-grid warning in DEV but not in PROD with regards to the column defs being undefined, as disclosed here:
      https://github.com/NewVisionsForPublicSchools/nv-data-tools-portal/blob/master/src/ng2/school/server-side-grid/server-side-grid.component.ts#L192
      to solve this issue: https://github.com/ag-grid/ag-grid/issues/2320
      */
      columnDefs: visibleColumns,
      rowModelType: this.rowModelType,
      defaultCsvExportParams: { fileName: this.getDefaultCsvExportParamsFileName() },
      onGridReady: () => {
        exportGridOptions.api.setServerSideDatasource({
          getRows: this.getAllRowsForExport.bind(this, visibleColumns, exportGridOptions),
        });
        this.renderer.removeChild(this.el.nativeElement, gridDiv);
      },
    };
    new Grid(gridDiv, exportGridOptions);
  }

  private getDefaultCsvExportParamsFileName (): string {
    switch (this.contextPartnerType) {
      case PartnerTypes.CUNY:
      case PartnerTypes.EXPANDED:
      case PartnerTypes.HYBRID:
      case PartnerTypes.SCHOOL:
      case PartnerTypes.SHELTER:
        return 'Grid Export';
      case PartnerTypes.SCHOOL_NETWORK:
      case PartnerTypes.SHELTER_NETWORK: {
        return 'Network Grid';
      }
      default: {
        return 'Grid Export';
      }
    }
  }

  public onBatchActionMenu (): void {
    this.getAllFilteredStudents()
      .pipe(
        tap(filteredData => {
          this.setFilteredRows(filteredData);
          this.saveBatchActionState(this.selectedRows);
        }),
        take(1),
        shareReplay(1),
      )
      .subscribe();
  }

  public onCellClicked (params: CellClickedEvent): void {
    if (
      this.contextPartnerType === PartnerTypes.SCHOOL_NETWORK ||
      this.contextPartnerType === PartnerTypes.SHELTER_NETWORK
    ) {
      return;
    }
    const {
      colDef: { lockPinned },
    } = params;

    const canAccessProfile = !this.isExpandEd;
    if (canAccessProfile) {
      if (lockPinned && !this.batchActionMode) {
        const {
          data: { OSIS_NUMBER, SCHOOL_DBN, CARES_ID },
        } = params;
        const studentId = this.getStudentId(OSIS_NUMBER, SCHOOL_DBN, CARES_ID);
        const filter = this.objectCache.cacheObject({ _id: [studentId] });
        const queryParams: any = this.isCUNY ? { filter, isCUNY: true } : { filter };

        if (this.contextPartnerType === PartnerTypes.SHELTER) queryParams.subLocationId = this.subLocationId;
        if (this.contextPartnerType === PartnerTypes.CUNY) queryParams.schoolId = SCHOOL_DBN;
        const url = this.ssgService.getUrl(this.contextPartnerType, this.contextPartnerId);

        this.router.navigate([url], { queryParams });
      }
    }
  }

  public getStudentId (osis, schoolDBN, caresId) {
    if (this.contextPartnerType === PartnerTypes.CUNY) {
      return `${osis}${schoolDBN}`;
    } else if (this.contextPartnerType === PartnerTypes.SCHOOL) {
      return `${osis}${this.contextPartnerId}`;
    } else if (this.contextPartnerType === PartnerTypes.SHELTER) {
      return caresId;
    }
  }

  /**
   * Opens the edit columns modal.
   * Depending on whether it'll create a new grid view or update the existing columns, it configures the modal accordingly.
   * @param isNewGridViewMode - Indicates whether to create a new grid view or edit an existing one.
   */
  public onEditColumns (isNewGridViewMode: boolean = false): void {
    const selectedColumns = isNewGridViewMode ? [] : this.getSelectedColumns();
    const titleLabel = isNewGridViewMode ? 'New view' : 'Edit columns';
    const iconName = isNewGridViewMode ? 'arrow-left-selected' : 'close-large-blue';
    this.columnUpdateComplete.next(false); // Start with default value

    const data: IEditGridColumnsModalComponentData = {
      columns: this.unlockedColumns,
      selectedColumns,
      categories: this.categories,
      tags: this.tags,
      origin: this.portalOrigin,
      titleLabel,
      iconName,
    };

    this.modalsService
      .openEditGridColumnsModal(data)
      .afterClosed()
      .pipe(
        tap(result => this._handleModalClose(result, isNewGridViewMode)),
        take(1),
      )
      .subscribe();
  }

  /**
   * Handles the modal close event.
   * @param result - The result returned by the modal, either the selected columns or false (if the user clicked the close/back button).
   * @param isNewGridViewMode - Indicates whether it was a new Grid View created.
   */
  private _handleModalClose (result: any, isNewGridViewMode: boolean): void {
    if (result) {
      this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
      this.updateColumnState(result);
      this.onGridTableControlDataRequest(GridTableControlButtonType.UPDATE);
      if (isNewGridViewMode) {
        this.columnUpdateComplete.next(true); // Notify that the column update is complete
      } else this.columnUpdateComplete.next(false);
    } else {
      // Only if we want to create a new view, re-open the modal: GridViewNameModalComponent
      if (isNewGridViewMode) this.onClickedCreateView();
    }
  }

  private resetFilter (name: string): void {
    // reset the filter in agGrid for hidden columns (SR)
    const filteredCol = this.gridApi.getFilterInstance(name);
    filteredCol?.setModel(null);
    // reset hash so filters on hidden cols do not persist on reload (SR)
    const { filter } = this.route.snapshot.queryParams;
    this.objectCache.getObject(filter).then((activeFilters: any) => {
      if (activeFilters[name]) {
        delete activeFilters[name];
        const grid = {
          api: this.gridApi,
          columnApi: this.gridColumnApi,
        } as AgGridEvent;
        this.onFilterChanged(grid);
      }
    });
    this.gridApi.onFilterChanged();
  }

  private getSelectedColumns (): ColDef[] {
    const state = this.gridColumnApi.getColumnState();
    const hash = state.reduce((hash: { [key: string]: boolean }, c: ColumnState) => {
      if (c.hide === false) {
        hash[c.colId] = true;
      }
      return hash;
    }, {});
    return this.columnDefs.filter(c => hash[c.field] && !c.lockPinned);
  }

  private updateColumnState (selectedCols: ColDef[]): void {
    const hash = selectedCols?.reduce((hash: { [key: string]: boolean }, col: ColDef) => {
      hash[col.field] = true;
      return hash;
    }, {});
    const filterModel = this.gridApi.getFilterModel();
    const state = this.gridColumnApi.getColumnState().map((c: ColumnState) => {
      if (this.lockedColumns.find(column => column.field === c.colId)) {
        c.hide = false;
      } else {
        c.hide = !hash[c.colId];
      }
      if (c.hide && filterModel[c.colId]) this.resetFilter(c.colId);
      return c;
    });
    this.gridColumnApi.applyColumnState({
      state,
      applyOrder: true,
    });
    this.gridApi.refreshServerSide({ purge: true });
  }

  private setEditColumnsState (columnDefs = []): void {
    const { allTags, allCategories } = columnDefs.reduce(
      (acc, { category, tags }) => {
        const colTags = tags || [];
        acc.allTags.push(...colTags);
        acc.allCategories.push(category);
        return acc;
      },
      { allTags: [], allCategories: [] },
    );
    this.categories = Array.from(new Set(allCategories));
    this.tags = Array.from(new Set(allTags));
  }

  private setUserState (user: IUser): void {
    this.currentUser = user;
    if (this.contextPartnerType === PartnerTypes.SHELTER) this.portalType = PORTAL_TYPES.SHELTER;
    if (this.imUser.isExpandEd(this.currentUser)) {
      this.isExpandEd = true;
      this.contextPartnerType = PartnerTypes.EXPANDED;
      this.portalType = PORTAL_TYPES.SCHOOL;
    } else if (this.imUser.isCUNY(this.currentUser)) {
      this.isCUNY = true;
      this.contextPartnerType = PartnerTypes.CUNY;
      this.portalType = PORTAL_TYPES.SCHOOL;
    }
    const isEditingUser = this.imUser.isEditingUser(user, {
      partnerType: this.contextPartnerType,
      partnerId: this.contextPartnerId,
    });
    this.userCanEdit = isEditingUser;
  }

  private setPartnerState (school: ISchool): void {
    if (this.contextPartnerType === PartnerTypes.SCHOOL && school?._id) {
      this.setGridType(school);
      this.contextPartnerId = school._id || null;
      this.uniqueRowId = 'OSIS_NUMBER';
      this.school = school;
      this.portalOrigin = PORTAL_TYPES.SCHOOL;
    } else if (this.contextPartnerType === PartnerTypes.SHELTER) {
      this.gridType = this.imShelter.getShelterGridType();
      this.contextPartnerId = this.route.snapshot.params.shelterId;
      this.uniqueRowId = 'CARES_ID';
      this.subLocationId = this.state?.subLocationId || this.sessionStorageService.getItem('subLocationId'); // set by side.nav
      this.portalOrigin = PORTAL_TYPES.SHELTER;
      if (this.subLocationId) this.setSubLocation(this.subLocationId);
    } else if (this.currentUser.nvRole.type === EXPANDED) {
      this.setGridType(school);
      this.uniqueRowId = 'OSIS_NUMBER';
      this.contextPartnerType = PartnerTypes.EXPANDED;
      this.contextPartnerId = school?._id || null;
      this.school = school;
      this.portalOrigin = PORTAL_TYPES.SCHOOL;
    } else if (this.currentUser.nvRole.type === CUNY) {
      this.setGridType(school);
      this.uniqueRowId = 'OSIS_NUMBER';
      this.batchActionOrigin = BatchActionOriginOptions.DATA_GRID;
      this.contextPartnerType = PartnerTypes.CUNY;
      this.contextPartnerId = school?._id || null;
      this.school = school;
      this.portalOrigin = PORTAL_TYPES.SCHOOL;
    } else if (this.contextPartnerType === PartnerTypes.SCHOOL_NETWORK) {
      const districtId = this.sessionStorageService.getItem('currentDistrict');
      if (districtId === districtsConfig.LANSING_DISTRICT) {
        this.uniqueRowId = 'school';
      } else {
        this.uniqueRowId = 'dbn';
      }
      this.gridType = PartnerTypes.SCHOOL_NETWORK; // Used in Grid Views (gridTypeSchoolType)
      this.batchActionOrigin = BatchActionOriginOptions.DATA_GRID;
      this.contextPartnerId = this.route.snapshot.params.clusterId;
      this.portalOrigin = PORTAL_TYPES.NETWORK;
    } else if (this.contextPartnerType === PartnerTypes.SHELTER_NETWORK) {
      this.uniqueRowId = 'shelter_id';
      this.batchActionOrigin = BatchActionOriginOptions.SHELTER_DATA_GRID;
      this.contextPartnerId = this.route.snapshot.params.clusterId;
      this.portalOrigin = PORTAL_TYPES.SHELTER_NETWORK;
      this.gridType = PartnerTypes.SHELTER_NETWORK;
    }
    this.setBatchActionOrigin();
  }

  private setGridType (school: ISchool): void {
    if (this.currentUser.nvRole.type === EXPANDED) {
      this.gridType = PartnerTypes.EXPANDED;
    } else if (this.currentUser.nvRole.type === CUNY) {
      this.gridType = PartnerTypes.CUNY;
    } else {
      this.gridType = this.imSchool.getGridType(school);
    }
  }

  private setExitState (): void {
    this.isLogoutEnabled = this.getLogoutEnabled();
    if (this.contextPartnerType === PartnerTypes.SCHOOL) {
      this.exitState = this.prevStateService.getPrevUrlNavigateOpts({
        // Makes sure it goes back to prev urls that are in current school only
        designatedPrevUrls: [
          `${this.contextPartnerId}/lists`,
          `${this.contextPartnerId}/regents-planning`,
          `${this.contextPartnerId}/regents-prep`,
          `${this.contextPartnerId}/credit-gaps`,
          `${this.contextPartnerId}/grad-planning`,
          `${this.contextPartnerId}/other-tools`,
          `${this.contextPartnerId}/settings`,
          `${this.contextPartnerId}/data-uploads`,
          `${this.contextPartnerId}/doe-postsec-advising`,
          `${this.contextPartnerId}/graduation/monitor`,
          `${this.contextPartnerId}/graduation/respond`,
          `${this.contextPartnerId}/academic/monitor`,
          `${this.contextPartnerId}/students`,
          `${this.contextPartnerId}/attendance/monitor`,
          `${this.contextPartnerId}/credits/monitor`,
          `${this.contextPartnerId}/credits/respond`,
          `${this.contextPartnerId}/postsecondary/monitor`,
          `${this.contextPartnerId}/regents/monitor`,
          `${this.contextPartnerId}/regents/respond`,
          `${this.contextPartnerId}/my-tasks`,
        ],
        fallbackUrl: this.urlPathService.computeDistrictUrlPath(`/school/${this.contextPartnerId}/lists/tiles`),
      });
    }

    if (this.contextPartnerType === PartnerTypes.SHELTER) {
      this.exitState = this.prevStateService.getPrevUrlNavigateOpts({
        // Makes sure it goes back to prev urls that are in current SHELTER only
        designatedPrevUrls: [`${this.contextPartnerId}/lists/attendance-list`],
        fallbackUrl: this.urlPathService.computeDistrictUrlPath(
          `/shelter/${this.contextPartnerId}/lists/attendance-list`,
        ),
      });
    }

    if (this.imUser.isExpandEd(this.currentUser)) {
      this.exitState = this.prevStateService.getPrevUrlNavigateOpts();
      this.exitState.isExpandEd = true;
    } else if (this.imUser.isCUNY(this.currentUser)) {
      this.exitState = this.prevStateService.getPrevUrlNavigateOpts();
      this.exitState.isCUNY = true;
      this.exitState.logout = true;
    }

    if (
      this.contextPartnerType === PartnerTypes.SCHOOL_NETWORK ||
      this.contextPartnerType === PartnerTypes.SHELTER_NETWORK
    ) {
      const typePath = this.contextPartnerType === PartnerTypes.SCHOOL_NETWORK ? 'school' : 'shelter';
      this.exitState = {
        url: this.urlPathService.computeDistrictUrlPath(`/network/${typePath}/${this.contextPartnerId}/dashboard`),
      };
    }

    this.exitState.callbackFunction = () => {
      this.store.dispatch(new SaveGridData({ scrollLeft: null, scrollTop: null }));
      this.clearBatchActionState();
      this.checkNextTermPlanningGridRunning.bind(this);
    };
  }

  public checkNextTermPlanningGridRunning (): void {
    this.store
      .select(getGridDataNextTermPlanningRunning)
      .pipe(
        tap(isGridDataNextTermPlanningRunning => {
          if (isGridDataNextTermPlanningRunning) {
            this.store.dispatch(new ClearGridData());
          }
        }),
        take(1),
      )
      .subscribe();
  }

  private autoSizeColumns (): void {
    const columnIds = this.gridColumnApi
      .getColumns()
      .filter((column: any) => {
        const {
          colDef: { width },
          visible,
        } = column;
        return visible && !width;
      })
      .reduce((acc, column: any) => {
        acc.push(column.colId);
        return acc;
      }, []);
    this.gridColumnApi.autoSizeColumns(columnIds);
  }

  private saveBatchActionState (students: any[] = []): void {
    // Batch actions uses 'studentId' to perform batch actions
    const studentIds: ISelectedStudentIdData[] = students.map(student => {
      if (this.contextPartnerType === PartnerTypes.SHELTER) {
        student.studentId = student.CARES_ID;
      } else student.studentId = student.OSIS_NUMBER; // OSIS_NUMBER should be required
      return student;
    });
    this.store.dispatch(new ToggleBatchActions(true));
    this.store.dispatch(new UpdateSelectedStudentIds(studentIds));
  }

  private clearBatchActionState (): void {
    this.store.dispatch(new ToggleBatchActions(false));
    this.store.dispatch(new UpdateSelectedStudentIds([]));
    this.store.dispatch(new UpdateSelectedAction({} as IAction));
  }

  private nullCheckboxSelectionOptions (c: ColDef) {
    c.checkboxSelection = null;
    return c;
  }

  private updateColumnDefs (fun: Function): void {
    if (this.skipUpdateColumnDefs || this.gridViewSkips > 0) return;
    const state = this.gridColumnApi.getColumnState();
    const hash = state.reduce(
      (
        hash: { [key: string]: { hide: boolean; width: number; pinned: 'left' | 'right' | boolean } },
        c: ColumnState,
      ) => {
        hash[c.colId] = { hide: c.hide, width: c.width, pinned: c.pinned };
        return hash;
      },
      {},
    );
    // ensure we keep hide, width and pinned from the columnState when mutating columnDefs
    this.columnDefs = this.columnDefs.map(c => {
      c.hide = hash[c.field]?.hide;
      c.width = hash[c.field]?.width;
      c.pinned = hash[c.field]?.pinned;
      return fun(c);
    });
  }

  private getStudents ({ selectedRows }) {
    if (this.contextPartnerType === PartnerTypes.SHELTER) {
      return selectedRows.map(({ CARES_ID }) => ({
        _id: CARES_ID,
        studentId: CARES_ID,
      }));
    } else if (this.contextPartnerType === PartnerTypes.CUNY) {
      return selectedRows.map(({ OSIS_NUMBER, SCHOOL_DBN }) => ({
        _id: `${OSIS_NUMBER}${SCHOOL_DBN}`,
        studentId: OSIS_NUMBER,
      }));
    } else {
      return selectedRows.map(({ OSIS_NUMBER }) => ({
        _id: `${OSIS_NUMBER}${this.contextPartnerId}`, // SCHOOL: append schoolId
        studentId: OSIS_NUMBER,
      }));
    }
  }

  // Decides whether to suppress warning message based on env.
  // currently, hides msgs only on prod but shows them on all other env.
  private shouldSuppressPropertyNamesCheck (): boolean {
    return ['prod'].includes(this.publicConfig.DISPLAY_ENV_PATH);
  }

  private getAllDisplayedColumnDefs (): ColDef[] {
    const allDisplayedColumns = this.gridColumnApi.getAllDisplayedColumns();
    return allDisplayedColumns.map((c: Column) => {
      return c.getColDef();
    });
  }

  private setNoRowsOverlay (): void {
    const gridRightBody = this.el.nativeElement.querySelector('.ag-body-horizontal-scroll-viewport') as ElementRef;
    const gridLeftBody = this.el.nativeElement.querySelector('.ag-horizontal-left-spacer') as ElementRef;

    const filteredRowCount: number = this.gridApi?.getDisplayedRowCount();
    this.renderer[filteredRowCount > 0 ? 'addClass' : 'removeClass'](gridRightBody, 'scroll-show-right-panel');
    this.renderer[filteredRowCount <= 0 ? 'addClass' : 'removeClass'](gridRightBody, 'scroll-hide-right-panel');

    this.renderer[filteredRowCount > 0 ? 'addClass' : 'removeClass'](gridLeftBody, 'scroll-show-left-panel');
    this.renderer[filteredRowCount <= 0 ? 'addClass' : 'removeClass'](gridLeftBody, 'scroll-hide-left-panel');

    filteredRowCount > 0 ? this.gridOptions.api.hideOverlay() : this.gridOptions.api.showNoRowsOverlay();
  }

  public selectAllByFilter (): void {
    if (this.isSelectAll) {
      this.unselectedStudentList = new Map();
      this.isInDeterminateSelectAll = false;
      this.updateSelectedRowCount();
      this.selectNodes();
    } else {
      this.gridApi.deselectAll();
    }
  }

  public selectNodes () {
    if (this.isSelectAll) {
      const isChecked = this.isSelectAll;
      this.gridApi?.forEachNode(node => {
        if (
          node?.data &&
          (this.unselectedStudentList?.size === 0 || !this.unselectedStudentList?.has(node.data[this.uniqueRowId]))
        ) {
          node.selectThisNode(isChecked);
        }
      });
    }
  }

  private setCheckboxState (): void {
    this.disableToolButtons = this.numRowsSelected <= 0;
    this.isInDeterminateSelectAll = this.numRowsSelected > 0 && this.totalFilteredRowCount !== this.numRowsSelected;
  }

  private getGridViewOption$ (): Observable<any> {
    let district: TDistricts;
    const isSchoolNetwork = this.contextPartnerType === PartnerTypes.SCHOOL_NETWORK;
    const isShelterNetwork = this.contextPartnerType === PartnerTypes.SHELTER_NETWORK;
    const isShelter = this.contextPartnerType === PartnerTypes.SHELTER;

    if (isSchoolNetwork) {
      const districtId = this.sessionStorageService.getItem('currentDistrict');
      const districts = this.sessionStorageService.getItem('districts');
      district = districts.find((d: any) => d._id === districtId).displayName;
    } else if (isShelterNetwork || isShelter || this.isCUNY || this.isExpandEd) {
      district = District.NYC;
    } else {
      district = this.school.district;
    }

    return this.ssgService.getGridViewsConfig$({
      contextPartnerId: this.contextPartnerId,
      contextPartnerType: this.contextPartnerType,
      district,
      gridType: this.gridType,
      userId: this.currentUser._id,
    });
  }

  private refreshGridViewOptions (currentGridViewId: string = null): void {
    this.getGridViewOption$().subscribe(data => {
      this.processGridViewOptions(data, currentGridViewId);
      this.updateIsCustomView();
      this.applyGridViewColumns();
    });
  }

  private processGridViewOptions (gridViewOptions: any, currentGridViewId: string = null): void {
    let options: any;
    if (!gridViewOptions?.GridViewsByUser) {
      options = this.generateDefaultGridView();
    } else {
      options = gridViewOptions;
    }

    this.gridViewsByUser = options?.GridViewsByUser;
    this.gridViewOptions = this.setGridViewOptions(this.gridViewsByUser);
    this.initSidenav(this.gridViewsByUser);
    this.flattenedGridViewOptions = this.flattenGridViews(this.gridViewOptions);

    let currentGridView: any;

    if (currentGridViewId) {
      currentGridView = this.flattenedGridViewOptions?.find(option => option.key === currentGridViewId);
    }

    if (!currentGridView) {
      currentGridView = this.getDefaultGridView();
      this.deleteIconClicked = false;

      if (currentGridViewId?.includes('preset')) {
        this.state = {
          gridViewId: currentGridView.key,
        };
      }
    }

    this.selectedGridView = currentGridView;
    this.selectedGridViewKey = currentGridView.key;
    this.selectedGridViewConfig = this.gridViewsByUser.find((gv: any) => {
      return gv.gridViewId === this.selectedGridView.key;
    });
  }

  private generateDefaultGridView (): any {
    const columnDefs = this.columnDefs.map(c => {
      return {
        field: c.field,
        pinned: c.pinned ? c.pinned : null,
      };
    });

    const gridViewOption = {
      _id: 'admin',
      gridViewId: 'presetDefault',
      gridViewName: 'Main',
      gridViewType: 'admin',
      parentCategory: null,
      order: 1,
      columnDefs,
      filters: null,
      sorts: [],
    };
    return {
      GridViewsByUser: [gridViewOption],
    };
  }

  private getDefaultGridView (): any {
    let defaultGridView: any;
    if (this.isExpandEd) {
      defaultGridView = this.flattenedGridViewOptions?.find(option => option.key.includes('presetExpandEd'));
    } else if (this.isCUNY) {
      defaultGridView = this.flattenedGridViewOptions?.find(option => option.key.includes('presetCUNY'));
    } else if (this.contextPartnerType === PartnerTypes.SHELTER) {
      defaultGridView = this.flattenedGridViewOptions?.find(option => option.key.includes('presetShelterDefault1'));
    } else if (this.contextPartnerType === PartnerTypes.SCHOOL_NETWORK) {
      defaultGridView = this.flattenedGridViewOptions?.find(option => option.key.includes('presetSchoolNetwork'));
    } else if (this.contextPartnerType === PartnerTypes.SHELTER_NETWORK) {
      defaultGridView = this.flattenedGridViewOptions?.find(option => option.key.includes('presetShelterNetwork'));
    } else {
      defaultGridView = this.flattenedGridViewOptions?.find(option => option.key.includes('presetDefault'));
    }

    if (!defaultGridView) {
      defaultGridView = this.flattenedGridViewOptions?.find(option => option.key.includes('presetDefault'));
    }
    return defaultGridView;
  }

  private flattenGridViews (gridViewOptions: IDropdownOption[]): IDropdownOption[] {
    return gridViewOptions.reduce<IDropdownOption[]>((acc, item) => {
      return acc.concat(item, item.options ? this.flattenGridViews(item.options) : []);
    }, []);
  }

  private setGridViewOptions (data: any[]): IDropdownOption[] {
    let gridViewOptions: IDropdownOption[] = [];

    const nestedGridViewOptions = [];
    const categories = {};
    data?.map(obj => {
      const { parentCategory, ...rest } = obj;
      if (parentCategory === null) {
        nestedGridViewOptions.push(rest);
      } else {
        if (!categories[parentCategory]) {
          categories[parentCategory] = {
            _id: parentCategory,
            gridViewId: parentCategory,
            gridViewName: parentCategory,
            gridViewType: 'admin',
            options: [],
          };
          nestedGridViewOptions.push(categories[parentCategory]);
        }
        categories[parentCategory].options.push(rest);
      }
      return nestedGridViewOptions;
    });

    const presetGridViewOptionsTier2 = nestedGridViewOptions
      ?.filter(gViews => gViews.gridViewType === 'admin')
      ?.sort((a, b) => a.order - b.order)
      ?.sort((a, b) =>
        a.gridViewName === 'Portal Default'
          ? -1
          : b.gridViewName === 'Portal Default'
            ? 1
            : a.gridViewName.localeCompare(b.gridViewName),
      )
      .map((gridViewConfigData: any) => {
        return {
          key: gridViewConfigData.gridViewId,
          human: gridViewConfigData.gridViewName,
          options: gridViewConfigData.options?.map(option => {
            const dropdownOption = { key: option.gridViewId, human: option.gridViewName };
            return dropdownOption;
          }),
        };
      }) as IDropdownOption[];
    const presetGridViewOptionsTier1 = {
      key: 'templateGridViews',
      human: 'Template Views',
      options: presetGridViewOptionsTier2,
    };

    let sortedMyGridViewOptionsTier2 = data
      ?.filter(gViews => gViews.gridViewType === 'custom' && gViews.createdBy?.userId === this.currentUser?._id)
      ?.sort((a, b) => a.gridViewName.localeCompare(b.gridViewName))
      .map((gridViewConfigData: any) => {
        return {
          key: gridViewConfigData.gridViewId,
          human: gridViewConfigData.gridViewName,
          actionIcon: 'trash-large-blue',
        };
      }) as IDropdownOption[];

    if (sortedMyGridViewOptionsTier2?.length <= 0) {
      sortedMyGridViewOptionsTier2 = [
        {
          key: '',
          human: 'No custom views have been saved yet',
          disabled: true,
          customClass: 'disabled-option-text',
        },
      ];
    }

    const myGridViewOptionsTier1 = {
      key: 'myGridViews',
      human: 'My Custom Views',
      options: sortedMyGridViewOptionsTier2,
    };

    // TODO: Add to gridViewOptions when shared grid views are applied
    // let sortedSharedGridViewOptionsTier2  = data?.
    // filter(gViews => gViews.gridViewType === "custom"
    // && gViews.createdBy?.userId !== this.currentUser?._id
    // && gViews.accessPermissions?.userIds?.includes(this.currentUser?._id))?.
    // sort((a, b) => a.gridViewName.localeCompare(b.gridViewName)).
    // map((gridViewConfigData: any) => {
    //   return {
    //     key: gridViewConfigData.gridViewId,
    //     human: gridViewConfigData.gridViewName,
    //   };
    // }) as IDropdownOption[];

    // if (sortedSharedGridViewOptionsTier2?.length <= 0) {
    //   sortedSharedGridViewOptionsTier2 = [{
    //     key: '',
    //     human: 'No views have been shared with me yet',
    //     disabled: true,
    //     customClass: 'disabled-option-text'
    //   }];
    // }
    // const sharedGridViewOptionsTier1 = {
    //   key: 'sharedGridViews',
    //   human: 'Shared with me',
    //   options: sortedSharedGridViewOptionsTier2,
    // };

    gridViewOptions = [presetGridViewOptionsTier1, myGridViewOptionsTier1];
    return gridViewOptions;
  }

  public onGridViewChange (newGridView: IDropdownOption, isLoading?: boolean): void {
    this.toBeSelectedGridView = newGridView;
    const isSameGridView = this.selectedGridView.key === this.toBeSelectedGridView.key;
    if (!isLoading && !this.isGridViewChanged && isSameGridView) return;
    this.autoSaveTooltip = this.AUTO_SAVE_SUCCESS_TOOLTIP;
    if (!this.deleteIconClicked) {
      if (!isLoading) this.onClear();
      this.applyGridViewChange();
    }
  }

  private applyGridViewChange (): void {
    this.selectedGridView = this.toBeSelectedGridView;
    this.selectedGridViewKey = this.selectedGridView.key;
    this.selectedGridViewConfig = this.gridViewsByUser.find((gv: any) => {
      return gv.gridViewId === this.toBeSelectedGridView.key;
    });
    this.updateIsCustomView();

    if (this.selectedGridViewConfig) {
      this.gridViewChange = true;
      this.isGridViewChanged = false;

      this.applyGridViewColumns();
      this.applyGridViewFilters();
      this.applyGridViewSorts();
      this.gridApi.refreshServerSide({ purge: true });

      if (this.gridViewSkips < 1) {
        this.gridViewSkips = 0;
        this.saveGridState({ api: this.gridApi, columnApi: this.gridColumnApi } as AgGridEvent);
      } else {
        this.gridViewSkips--; // at least one must not be skipped
      }
      this.selectedGridViewConfig.columnState = this.gridColumnApi.getColumnState();
    }
    this.updateListDataIconOptions();
    this.setSaveMenuOptions();
    this.skipUpdateColumnDefs = false;
  }

  public applyGridViewColumns (): void {
    this.gridViewColumnDefs = this.selectedGridViewConfig?.columnDefs;
    if (this.gridViewColumnDefs) {
      const state = this.gridViewColumnDefs.map(cd => {
        return {
          colId: cd.field,
          hide: false,
          pinned: cd.pinned,
          width: cd.width,
        };
      });
      const stateChangesNum = this.getStateChangesNum(state);
      if (stateChangesNum) this.gridViewSkips = this.gridViewSkips + stateChangesNum;
      this.gridColumnApi.applyColumnState({
        applyOrder: true,
        defaultState: { hide: true },
        state,
      });

      const isAdminGridView = this.selectedGridViewConfig.gridViewType === 'admin';
      if (isAdminGridView) this.autoSizeColumns();
    }
  }

  private getStateChangesNum (state: any[]): number {
    let stateChangesNum = 0;
    const { stateHash, visibleStateHash } = this.gridColumnApi.getColumnState().reduce(
      (hash: { [key: string]: any }, c: ColumnState) => {
        hash.stateHash[c.colId] = { hide: c.hide, pinned: c.pinned };
        if (!c.hide) {
          hash.visibleStateHash[c.colId] = { hide: c.hide, pinned: c.pinned };
        }
        return hash;
      },
      { stateHash: {}, visibleStateHash: {} },
    );

    let isVisibleChange = false;
    if (state.length === Object.keys(visibleStateHash).length) {
      isVisibleChange = state.some(s => {
        const sh = visibleStateHash[s.colId];
        return !sh || sh?.hide !== s.hide;
      });
    } else {
      isVisibleChange = true;
    }

    const isPinnedChange = state.some(s => {
      const sh = stateHash[s.colId];
      const isShPinned = sh ? !!sh.pinned : false;
      const isSPinned = s ? !!s.pinned : false;
      return isShPinned !== isSPinned;
    });

    if (isVisibleChange) stateChangesNum++;
    if (isPinnedChange) stateChangesNum++;
    return stateChangesNum;
  }

  public getDisplayColumnDefs (): any[] {
    const displayedColumnDefs: any = this.originalColumnDefs?.map((column: any) => {
      const gridViewColumn = this.gridViewColumnDefs?.find(g => g.field === column.field);
      column.hide = !gridViewColumn;
      if (gridViewColumn) {
        column.order = gridViewColumn.order ? Number(gridViewColumn.order) : 999999; // order hidden fields to the end
        column.width = gridViewColumn.width ? Number(gridViewColumn.width) : null;
        column.pinned = gridViewColumn.pinned ? gridViewColumn.pinned : column.pinned;
        column.lockPinned = gridViewColumn.lockPinned ? gridViewColumn.lockPinned : column.lockPinned;
        column.sort = gridViewColumn.sort ? gridViewColumn.sort : column.sort;
      }
      return column;
    });

    return displayedColumnDefs;
  }

  public applyGridViewFilters (): void {
    const gridViewFilters = this.convertToFilterModel(this.selectedGridViewConfig?.filters);
    const filters = this.gridApi.getFilterModel();

    if (Object.keys(filters).length) {
      this.gridViewSkips++;
      this.gridApi.setFilterModel(null);
    }

    if (Object.keys(gridViewFilters).length) {
      this.gridViewSkips++;
      this.gridApi.setFilterModel(gridViewFilters);
      this.filterChanged(gridViewFilters);
    }
  }

  private convertToFilterModel (filters: IGridViewFilter[]): any {
    if (Array.isArray(filters)) {
      return filters?.reduce(
        (
          filterModel: any,
          { colId, values, filterType, operator, condition1, condition2, type, filter, filterTo, dateFrom, dateTo },
        ) => {
          const parsedCondition1 = condition1 ?? (condition1 !== undefined ? JSON.parse(condition1) : undefined);
          const parsedCondition2 = condition2 ?? (condition2 !== undefined ? JSON.parse(condition2) : undefined);
          if (filterType === 'number') {
            filter = Number(filter);
          } else if (filterType === 'date') {
            filter = new Date(filter);
          }
          filterModel[colId] = {
            values,
            filterType,
            operator,
            condition1: parsedCondition1,
            condition2: parsedCondition2,
            type,
            filter,
            filterTo,
            dateFrom,
            dateTo,
          };
          return filterModel;
        },
        {},
      );
    } else {
      return {};
    }
  }

  public applyGridViewSorts (): void {
    const gridViewSorts = this.selectedGridViewConfig?.sorts;
    const isSortChange = this.isSortChange(gridViewSorts);
    if (isSortChange) this.gridViewSkips++;

    if (gridViewSorts.length) {
      // we used to call gridApi.setSortModel to apply sort which would do this step. Since it's been removed, we have to account for it
      const gridViewSortsWithSortIndex = gridViewSorts.map(({ colId, sort }, i) => ({ colId, sort, sortIndex: i }));
      this.gridColumnApi.applyColumnState({
        defaultState: { sort: null },
        state: gridViewSortsWithSortIndex,
      });
    } else {
      // reset all sorts
      this.gridColumnApi.applyColumnState({
        defaultState: { sort: null },
      });
    }
  }

  private isSortChange (sort: any[]): boolean {
    const sortHash = this.gridColumnApi.getColumnState().reduce((hash: { [key: string]: string }, c: ColumnState) => {
      if (typeof c.sort === 'string') {
        hash[c.colId] = c.sort;
      }
      return hash;
    }, {});
    if (sort.length === Object.keys(sortHash).length) {
      return sort.some(s => {
        return sortHash[s.colId] !== s.sort;
      });
    } else {
      return true;
    }
  }

  public determineGridViewEditedByState (currentColumnState: any): boolean {
    const selectedView = this.gridViewsByUser?.find(
      gridViewData => gridViewData.gridViewId === this.selectedGridView?.key,
    );
    const colDefSorts = selectedView?.sorts;
    const selectedViewColDefs = cloneDeep(this.gridViewColumnDefs);
    selectedViewColDefs?.forEach((colDef: any) => {
      const sortFoundIndex = colDefSorts?.findIndex(x => x.colId === colDef.field);
      colDef.sort = sortFoundIndex >= 0 ? colDefSorts[sortFoundIndex].sort : null;
      colDef.sortIndex = sortFoundIndex >= 0 ? sortFoundIndex : null;
    });
    const currentColState = currentColumnState.filter(col => col.hide === false);
    if (selectedViewColDefs?.length !== currentColState?.length) {
      return true;
    }
    const anyColumnsChanged = selectedViewColDefs.some((colDef, index) => {
      return this.isColumnChanged({ gridViewColDef: colDef, index, currentColState });
    });
    return anyColumnsChanged;
  }

  private isColumnChanged ({
    gridViewColDef,
    index,
    currentColState,
  }: {
    gridViewColDef: any;
    index: number;
    currentColState: any;
  }) {
    const colState = currentColState[index];
    const width =
      gridViewColDef.width === null && colState.width !== null && colState.width === 200
        ? colState.width
        : gridViewColDef.width;
    // if any of these col properties have changed, then return true
    return (
      gridViewColDef.field !== colState.colId ||
      gridViewColDef.pinned !== colState.pinned ||
      Math.abs(width - colState.width) > Number.EPSILON ||
      gridViewColDef.sortIndex !== colState.sortIndex ||
      gridViewColDef.sort !== colState.sort
    );
  }

  public determineGridViewEditedByFilter (currentFilters: any) {
    // if isEqual returns true, then grid view has not changed, so we will inverse the result of the isEqual
    return !isEqual(currentFilters, this.convertToFilterModel(this.selectedGridViewConfig?.filters));
  }

  private setSaveMenuOptions (): void {
    if (this.selectedGridView?.key?.includes('preset')) {
      this.saveButtonName = 'Save as new view';
      this.saveButtonAction = this.saveAsNewGridView.bind(this);
      this.saveMenuOptions = [{ label: 'Undo changes', action: this.clearGridViewChanges.bind(this) }];
    } else {
      this.saveButtonName = 'Save changes';
      this.saveButtonAction = this.saveGridView.bind(this);
      this.saveMenuOptions = [
        { label: 'Save as new view', action: this.saveAsNewGridView.bind(this) },
        { label: 'Undo changes', action: this.clearGridViewChanges.bind(this) },
      ];
    }
  }

  public clearGridViewChanges (): void {
    this.onGridViewChange(this.selectedGridView);
    this.showToast(true, 'Changes made to view have been undone.');
  }

  public saveGridView (): any {
    this.spinner = this.spinnerService.openSpinner({ message: 'Saving GridView...' });
    const saveGridViewConfig = this.getModifiedGridViewToSave(false);
    //  cannot update admin/template grid views
    if (saveGridViewConfig.gridViewType === 'admin') {
      return null;
    } else {
      return this.ssgService.updateGridView$(saveGridViewConfig, this.contextPartnerType).subscribe((_: any) => {
        this.handleSuccessfulSave(saveGridViewConfig, EVENT_TYPES.UPDATED, false);
        this.spinner?.close();
      });
    }
  }

  public saveAsNewGridView (): void {
    this.spinner = this.spinnerService.openSpinner({ message: 'Saving GridView...' });
    const saveGridViewConfig = this.getModifiedGridViewToSave(true);
    const { gridViewName, gridViewId } = saveGridViewConfig;
    const title = 'New view';
    const subtitle = null;
    this.modalsService
      .openGridViewNameModal({
        title,
        subtitle,
        gridViewName,
        gridViewId,
        menuAction: GridIconMenuActions.Save,
        existingGridViews: this.flattenedGridViewOptions,
      })
      .afterClosed()
      .pipe(
        switchMap(data => {
          if (data?.newGridViewName) {
            saveGridViewConfig.gridViewName = data.newGridViewName.toString();
            saveGridViewConfig.gridViewId = 'custom' + camelCase(saveGridViewConfig.gridViewName).replace(/\s/g, '');
            return this.ssgService.createGridView$(saveGridViewConfig, this.contextPartnerType);
          }
        }),
        tap((_: any) => {
          this.handleSuccessfulSave(saveGridViewConfig, EVENT_TYPES.CREATED, true);
          this.spinner?.close();
        }),
        catchError(err => {
          if (err) {
            this.spinner?.close();
            return EMPTY;
          }
        }),
      )
      .subscribe();
  }

  public onClickedCreateView (): void {
    this.modalsService
      .openGridViewNameModal({
        title: 'New view',
        subtitle: null,
        gridViewName: '',
        gridViewId: '',
        menuAction: GridIconMenuActions.Create,
        existingGridViews: this.flattenedGridViewOptions,
      })
      .afterClosed()
      .pipe(
        switchMap(data => (data?.newGridViewName ? this._handleNewGridViewName(data.newGridViewName) : of(null))),
        tap(result =>
          result?.saveGridViewConfig
            ? this.handleSuccessfulSave(result.saveGridViewConfig, EVENT_TYPES.CREATED, true)
            : this.spinner?.close(),
        ),
        take(1),
        catchError((err: any) => {
          if (err) {
            this.spinner?.close();
            return EMPTY;
          }
        }),
      )
      .subscribe();
  }

  private _handleNewGridViewName (newGridViewName: string): Observable<any> {
    const isNewGridViewMode = true;
    this.onEditColumns(isNewGridViewMode);
    return this.columnUpdateComplete.pipe(
      filter(isComplete => isComplete),
      switchMap(() => this._createGridView(newGridViewName)),
    );
  }

  private _createGridView (newGridViewName: string): Observable<any> {
    const saveGridViewConfig = this.getModifiedGridViewToSave(true);
    saveGridViewConfig.gridViewName = newGridViewName.toString();
    saveGridViewConfig.gridViewId = 'custom' + camelCase(saveGridViewConfig.gridViewName).replace(/\s/g, '');
    return this.ssgService
      .createGridView$(saveGridViewConfig, this.contextPartnerType)
      .pipe(map(result => ({ result, saveGridViewConfig })));
  }

  private handleSuccessfulSave (saveGridViewConfig: IGridView, eventType: any, showToast?: boolean) {
    // if the save is successful, disable the save button and reset edit state trackers
    this.isGridViewChanged = false;
    this.refreshGridViewOptions(saveGridViewConfig.gridViewId);
    if (showToast) this.showToast(true, 'New view saved. Changes to your views will auto-save.');
    this.updateListDataIconOptions();

    this.trackGridViewEvents(eventType, saveGridViewConfig);
  }

  private getModifiedGridViewToSave (isNew: boolean = false): IGridView {
    if (!this.selectedGridViewConfig) {
      const emptySaveGridViewConfig: IGridView = {
        gridViewType: 'custom',
        order: null,
        _id: '',
        gridViewId: '',
        gridViewName: '',
        createdBy: {
          userId: this.currentUser._id,
          gafeEmail: this.currentUser.gafeEmail,
          doeEmail: this.currentUser.doeEmail,
          dhsEmail: this.currentUser.dhsEmail,
          firstName: this.currentUser.name.firstName,
          lastName: this.currentUser.name.lastName,
        },
        accessPermissions: null,
        columnDefs: [],
        active: false,
        createdAt: undefined,
        lastUpdatedAt: undefined,
        lastUpdatedBy: undefined,
      };
      this.selectedGridViewConfig = emptySaveGridViewConfig;
    }

    const gvConfig = cloneDeep(this.selectedGridViewConfig);
    const { order, ...saveGridViewConfig } = gvConfig;
    if (isNew && saveGridViewConfig) {
      saveGridViewConfig.gridViewType = 'custom';
      saveGridViewConfig._id = '';
      saveGridViewConfig.gridViewId = '';
      saveGridViewConfig.gridViewName = '';

      saveGridViewConfig.createdBy = {
        userId: this.currentUser._id,
        gafeEmail: this.currentUser.gafeEmail,
        doeEmail: this.currentUser.doeEmail,
        dhsEmail: this.currentUser.dhsEmail,
        firstName: this.currentUser.name.firstName,
        lastName: this.currentUser.name.lastName,
      };
    }

    const contextPath = this.gridViewHelper.getContextPath(this.contextPartnerType);
    if (contextPath) {
      saveGridViewConfig.accessPermissions[contextPath] = [this.contextPartnerId];
    }

    saveGridViewConfig.active = true;

    const currentColumnDefs: ColDef[] = this.gridApi.getColumnDefs();
    const currentDisplayedColumnDefs: ColDef[] = currentColumnDefs?.filter(x => !x?.hide);
    // set column order as it is on the screen
    currentDisplayedColumnDefs.map((colDef: any, index) => {
      colDef.order = index + 1;
      colDef.width = colDef.width | 0;
      return colDef;
    });
    saveGridViewConfig.columnDefs = currentDisplayedColumnDefs;
    saveGridViewConfig.sorts = this.ssrm.getGridSortModel(this.gridOptions);
    const filterModel = this.gridApi.getFilterModel();
    if (filterModel) {
      const convertedFilters = Object.entries(filterModel).map(([colId, filter]) => ({
        colId,
        ...filter,
      }));
      saveGridViewConfig.filters = convertedFilters;
    }
    return saveGridViewConfig;
  }

  public handleClick (event: any, isDeleteAction: boolean, isLoading?: boolean) {
    if (isDeleteAction) {
      this.deleteGridView(event);
      this.deleteIconClicked = false;
    } else {
      this.onGridViewChange(event, isLoading);

      // track grid view selection by the user only..
      this.trackGridViewEvents(EVENT_TYPES.VIEWED, this.selectedGridViewConfig);
    }
  }

  public deleteGridView (gridViewOption: any): void {
    let deleteGridView: any;
    if (gridViewOption) {
      this.deleteIconClicked = true;
      const data: IConfirmModalComponentData = {
        title: 'Delete Custom View',
        subtitle: '',
        message: 'Are you sure you would like to delete this custom view? <br><br> This action can’t be undone.',
        confirmText: 'Delete',
      };
      this.modalsService
        .openConfirmModal(data)
        .afterClosed()
        .pipe(
          filter(confirmed => confirmed),
          switchMap(() => {
            deleteGridView = this.gridViewsByUser.find(gv => gv.gridViewId === gridViewOption.key);
            if (deleteGridView) {
              return this.ssgService.deleteGridView$(deleteGridView, this.contextPartnerType);
            } else {
              this.showToast(false, 'Unable to delete the view. Please try again.');
              return EMPTY;
            }
          }),
          tap(() => {
            this.gridViewsByUser = this.gridViewsByUser?.filter(gridView => gridView.gridViewId !== gridViewOption.key);
            const currKey = this.selectedGridView.key === gridViewOption.key ? null : this.selectedGridView?.key;
            this.refreshGridViewOptions(currKey);
            this.showToast(true, 'View has been deleted.');
            this.gridViewChange = false;
            this.isGridViewChanged = false;
            this.gridViewSkips = 0;
            this.updateListDataIconOptions();
            this.updateIsCustomView();
            this.trackGridViewEvents(EVENT_TYPES.DELETED, deleteGridView);
          }),
          catchError(error => {
            this.showToast(false, 'Unable to delete the view. Please try again.');
            return throwError(error);
          }),
        )
        .subscribe();
    }
    this.deleteIconClicked = false;
  }

  private updateGridView (gridViewOption: { key: string; action: GridIconMenuActions }): void {
    this.spinner = this.spinnerService.openSpinner({ message: 'Saving GridView...' });
    const saveGridViewConfig = this.getModifiedGridViewToSave(false);
    const { gridViewName, gridViewId } = saveGridViewConfig;
    const title = 'Rename view';
    const subtitle = null;
    this.modalsService
      .openGridViewNameModal({
        title,
        subtitle,
        gridViewName,
        gridViewId,
        menuAction: gridViewOption.action,
        existingGridViews: this.flattenedGridViewOptions,
      })
      .afterClosed()
      .pipe(
        switchMap(data => {
          if (data?.newGridViewName) {
            const newGridViewName = data.newGridViewName.toString();
            saveGridViewConfig.gridViewName = newGridViewName; // Assign new name
            saveGridViewConfig.gridViewId = gridViewId; // Use same gridViewId
            return this.ssgService.updateGridView$(saveGridViewConfig, this.contextPartnerType);
          }
        }),
        tap((_: any) => {
          this.handleSuccessfulSave(saveGridViewConfig, EVENT_TYPES.UPDATED, true);
          this.spinner?.close();
        }),
        catchError(err => {
          if (err) {
            this.spinner?.close();
            return EMPTY;
          }
        }),
      )
      .subscribe();
  }

  private showToast (updated: boolean, toastMessage: string): void {
    if (updated) {
      this.snackBarService.showToast({ toastText: toastMessage });
    } else {
      this.snackBarService.showDangerToastWithCloseButton({ toastText: toastMessage, isDanger: true });
    }
  }

  private trackGridViewEvents = (eventType: TEventType, gridView: IGridView): void => {
    const metaData: IGridViewMetadata = {
      eventName: EVENT_NAMES.SERVERSIDE_GRIDVIEW,
      eventType,
      gridView: {
        gridViewId: gridView.gridViewId,
        gridViewName: gridView.gridViewName,
        gridViewType: gridView.gridViewType,
        columns: {
          added: [],
          deleted: [],
        },
      },
      auditInfo: {
        userRole: this.currentUser.nvRole.type,
        gridOrSchoolType: this.gridType,
      },
      portal: this.portalType,
    };

    if (eventType === EVENT_TYPES.UPDATED || eventType === EVENT_TYPES.CREATED) {
      const prevColumns = this.selectedGridViewConfig.columnDefs
        .filter(x => x.hide !== false)
        .map(({ field }) => field);
      const currColumns = gridView.columnDefs.filter(x => x.hide !== false).map(({ field }) => field);
      const deletedCols = prevColumns.filter(x => !currColumns.includes(x));
      const addedCols = currColumns.filter(x => !prevColumns.includes(x));
      metaData.gridView.columns.added = addedCols;
      metaData.gridView.columns.deleted = deletedCols;
    }
    const event = getServerSideGridViewEvent(metaData);
    this.mixpanelService.trackEvents([event]);
  };

  private setSubLocation (subLocationId: string): void {
    this.state = {
      ...this.state,
      subLocationId,
    };

    const stateHash = this.objectCache.cacheObject(this.state);
    this.router.navigate([], {
      queryParams: { state: stateHash },
      queryParamsHandling: 'merge',
    });
  }

  public onClickedAction (actionKey: TPortalAction) {
    switch (actionKey) {
      case PORTAL_ACTIONS.CREATE_TASK:
        this.onTaskAction(actionKey);
        break;
      case PORTAL_ACTIONS.VIEW_PROFILES:
        this.onViewProfilesAction(actionKey);
        break;
      case PORTAL_ACTIONS.ADD_NOTES:
        this.onAddNoteAction(actionKey);
        break;
      case PORTAL_ACTIONS.REPORTS:
        this.onGenerateReportsAction(actionKey);
        break;
      case PORTAL_ACTIONS.FIELDS:
        this.onEditFieldsAction(actionKey);
        break;
      case PORTAL_ACTIONS.ASSIGN_GRAD_PLAN:
      case PORTAL_ACTIONS.ASSIGN_COURSE_PLAN:
      case PORTAL_ACTIONS.ASSIGN_REGENTS_PLAN:
        this.onAssignPlanAction(actionKey);
        break;
      case PORTAL_ACTIONS.ASSIGN_SUPPORT:
      case PORTAL_ACTIONS.COMPLETE_SUPPORT:
      case PORTAL_ACTIONS.DELETE_SUPPORT_RECORD:
        this.onSupportAction(actionKey);
        break;
      case PORTAL_ACTIONS.ADD_COLLEGE:
      case PORTAL_ACTIONS.UPDATE_COLLEGE_STATUS:
      case PORTAL_ACTIONS.REMOVE_COLLEGE:
      case PORTAL_ACTIONS.ADD_EXPERIENCE:
        this.onCollegeAction(actionKey);
        break;
      case PORTAL_ACTIONS.ASSIGN_SUCCESS_MENTOR:
      case PORTAL_ACTIONS.MARK_COMPLETE_SUCCESS_MENTOR:
      case PORTAL_ACTIONS.REMOVE_SUCCESS_MENTOR:
        this.onSuccessMentoringAction(actionKey);
        break;
      default:
        break;
    }
  }

  // Handle Actions
  public onTaskAction (actionKey: TPortalAction) {
    this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    forkJoin([
      this.apiService.getUsers(
        this.contextPartnerId,
        JSON.stringify({ where: { authorizationStatus: { $eq: 'FULL_ACCESS' } } }),
      ),
      this.isSelectAll ? this.getAllFilteredStudents() : of(this.selectedRows),
    ])
      .pipe(
        take(1),
        tap(([schoolUsers, filteredStudents]) => {
          const students = this.isSelectAll ? filteredStudents.rowData : filteredStudents;
          const studentIds = this.getStudents({ selectedRows: students }).map(({ _id }) => _id);
          const data: ITaskData = {
            partnerType: this.contextPartnerType,
            school: this.school,
            studentIds,
            currentUser: this.currentUser,
            schoolUsers,
            mode: ValidTaskModeType.CREATE_BULK_TASK_SCHOOL,
            origin:
              this.contextPartnerType === PartnerTypes.SHELTER
                ? ACTIONS_ORIGIN.SHELTER_DATA_GRID
                : ACTIONS_ORIGIN.DATA_GRID,
          };
          this.spinner?.close();
          this.portalActionsService.onAction(actionKey, data);
        }),
      )
      .subscribe();
  }

  public onViewProfilesAction (actionKey: TPortalAction) {
    // We are sorting selectedRows of students in this function instead of in the student profile component.
    // Reason: All 500+ grid column header names are snake cased (i.e STUDENT_NAME) while sorter column headers in student
    // profile component are camel cased (i.e studentName).
    // Please refer to test spec 'sorts the student profiles' under test suite 'onViewProfiles'
    this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    this.getAllFilteredStudents()
      .pipe(
        tap(filteredData => {
          this.setFilteredRows(filteredData);
          const sortModel = this.ssrm.getGridSortModel(this.gridOptions) || [];
          const colIds = sortModel.map(({ colId }) => colId);
          const sorts = sortModel.map(({ sort }) => sort);
          const _selectedRows = orderBy(this.selectedRows, colIds, sorts as any);
          this.saveBatchActionState(_selectedRows);

          const _ids = this.getStudents({ selectedRows: _selectedRows }).map(({ _id }) => _id);
          const filter = this.objectCache.cacheObject({ _id: _ids, grid: true });
          sessionStorage.setItem('sortedStudentIds', JSON.stringify(_ids));
          const url = this.ssgService.getUrl(this.contextPartnerType, this.contextPartnerId);
          const queryParams: any =
            this.contextPartnerType === PartnerTypes.CUNY ? { filter, isCUNY: true } : { filter };
          if (this.contextPartnerType === PartnerTypes.SHELTER) queryParams.subLocationId = this.subLocationId;
          const data = {
            url,
            queryParams,
            partnerType: this.contextPartnerType,
            origin:
              this.contextPartnerType === PartnerTypes.SHELTER
                ? ACTIONS_ORIGIN.SHELTER_DATA_GRID
                : ACTIONS_ORIGIN.DATA_GRID,
          };
          this.portalActionsService.onAction(actionKey, data);
          this.spinner?.close();
        }),
        take(1),
        shareReplay(1),
      )
      .subscribe();
  }

  public onAddNoteAction (actionKey: TPortalAction): void {
    const studentIds = this.getStudents({ selectedRows: this.selectedRows }).map(({ _id }) => _id);
    const mode: IValidNoteModes =
      this.contextPartnerType === PartnerTypes.SHELTER
        ? VALID_NOTE_MODES.CREATE_BULK_SHELTER
        : VALID_NOTE_MODES.CREATE_BULK_SCHOOL;
    const origin: TBatchActionsOrigin = this.batchActionOrigin;
    const data: INotesData = {
      partnerType: this.contextPartnerType,
      school: this.school,
      shelterId: this.contextPartnerId,
      studentIds,
      caresIds: studentIds,
      currentUser: this.currentUser,
      mode,
      origin,
    };

    this.portalActionsService.onAction(actionKey, data);
  }

  public onGenerateReportsAction (actionKey: TPortalAction): void {
    this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    this.getAllFilteredStudents()
      .pipe(
        tap(filteredData => {
          this.setFilteredRows(filteredData);
          const data = {
            students: this.selectedStudents,
            isProfileMode: false,
            view: ACTIONS_ORIGIN.DATA_GRID,
            partnerType: this.contextPartnerType,
            origin: this.batchActionOrigin,
          };
          this.portalActionsService.onAction(actionKey, data);
          this.spinner.close();
        }),
        take(1),
        shareReplay(1),
      )
      .subscribe();
  }

  public onEditFieldsAction (actionKey: TPortalAction): void {
    const studentIds = this.getStudents({ selectedRows: this.selectedRows }).map(({ _id }) => _id);
    const data = {
      studentIds,
      schoolId: this.contextPartnerId,
      origin: this.batchActionOrigin,
      partnerType: this.contextPartnerType,
    };

    this.portalActionsService.onAction(actionKey, data);
  }

  public onAssignPlanAction (actionKey: TPortalAction): void {
    const studentIds = this.getStudents({ selectedRows: this.selectedRows }).map(({ _id }) => _id);
    const data = {
      studentIds,
      school: this.school,
      origin: this.batchActionOrigin,
      partnerType: this.contextPartnerType,
    };

    this.portalActionsService.onAction(actionKey, data);
  }

  public onSupportAction (actionKey: TPortalAction): void {
    const studentIds = this.getStudents({ selectedRows: this.selectedRows }).map(({ _id }) => _id);
    const data = {
      studentIds,
      schoolId: this.contextPartnerId,
      origin: this.batchActionOrigin,
      partnerType: this.contextPartnerType,
    };

    this.portalActionsService.onAction(actionKey, data);
  }

  public onCollegeAction (actionKey: TPortalAction): void {
    const studentIds = this.getStudents({ selectedRows: this.selectedRows }).map(({ _id }) => _id);
    const data = {
      studentIds,
      schoolId: this.contextPartnerId,
      origin: this.batchActionOrigin,
      partnerType: this.contextPartnerType,
    };

    this.portalActionsService.onAction(actionKey, data);
  }

  private onSuccessMentoringAction (actionKey: TPortalAction): void {
    const caresIds = this.getStudents({ selectedRows: this.selectedRows }).map(({ _id }) => _id);
    const data = {
      caresIds, // for shelter students we use caresIds
      shelterId: this.contextPartnerId,
      origin: this.batchActionOrigin,
      partnerType: this.contextPartnerType,
    };
    this.portalActionsService.onAction(actionKey, data);
  }

  // Send isContentLoaded change to the  GridToolbarComponent
  private updateGridToolbar (isContentLoaded: boolean): void {
    const statusBarComponent = this.gridApi?.getStatusPanel<any>('toolbarComponentKey')!;
    statusBarComponent?.setContentLoaded(isContentLoaded);
  }

  private getGridViewEnabled (): boolean {
    return this.contextPartnerTypesWithGridViews.includes(this.contextPartnerType);
  }

  private getLogoutEnabled (): boolean {
    return this.contextPartnerTypesWithLogout.includes(this.contextPartnerType);
  }
}
