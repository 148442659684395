import { ApiService } from '../../../shared/services/api-service/api-service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class NetworkSideNavResolver implements Resolve<any> {
  constructor (
    private apiService: ApiService,
  ) {}

  resolve (route: ActivatedRouteSnapshot) {
    const { clusterId } = route.params;
    return this.apiService.getNetworkSideNavConfig(clusterId);
  }
}
