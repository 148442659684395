import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TConsentEvent } from '../../../../school/ecfik-management/ecfik-management.interface';

@Component({
  selector: 'decline-consent-status',
  templateUrl: 'decline-consent-status.component.html',
  styleUrls: ['./decline-consent-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeclineConsentStatusComponent implements OnInit {
  constructor () {}
  ngOnInit (): void {}
  public getPayload () {
    const consentEvent: TConsentEvent = 'declined';
    const payload = {
      consentEvent,
    };
    return payload;
  }
}
