import { PDFReportsService } from './../../services/pdf-reports/pdf-reports.service';
import { EventFormatterService } from './../../services/mixpanel/event-formatter.service';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { BatchActionsEffectsUtilities } from './../../../store/utilities/batch-actions-effects-utilities';
import { BACKGROUND_JOB_STATUS_TYPES } from './../../services/background-job/background-job.service';
import { WindowRef } from '../../../../../projects/shared/services/window-ref/windowRef';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { Component, Inject, Injector, OnInit, forwardRef, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { take, switchMap, tap, filter } from 'rxjs/operators';
import { BackgroundJob } from 'Src/ng2/shared/services/background-job/background-job.service';
import { getSchool } from '../../../store/selectors/school-selectors';
import { pdfReportTypes, TValidPdfReportKeys } from '../../constants/pdf-reports.constant';
import { Toggles } from '../../constants/toggles.constant';
import { ImSchool } from '../../services/im-models/im-school';
import { ToggleService } from '../../services/toggle/toggle.service';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { IDropdownOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { BaseModalComponent } from '../base-modal.component';
import { IStudent } from '../../typings/interfaces/student.interface';
import { Subscription } from 'rxjs';
import { MockRegentsService } from '../../services/mock-regents/mock-regents.service';
import { IFormatBatchActionEventArgs } from '../../services/mixpanel/event-interfaces/batch-action';
import { TMixpanelEvent } from '../../services/mixpanel/mixpanel.service';

const State = {
  NOT_STARTED: 'NOT_STARTED',
  GENERATING: 'GENERATING',
};

type TStudent = Pick<IStudent, '_id' | 'studentId'> & { isHS?: boolean };
export interface IStudentReportModalData {
  students: TStudent[];
  isProfileMode?: boolean;
}

type TValidSpinnerModes = 'determinate' | 'indeterminate';
@Component({
  selector: 'student-report-modal',
  templateUrl: './student-report.component.html',
  styleUrls: ['./student-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StudentReportModalComponent extends BaseModalComponent implements OnInit {
  report;
  state: string;
  selectedReportId: TValidPdfReportKeys;
  selectedStudents: TStudent[];
  downloadUrl: string;
  mode: TValidSpinnerModes;
  fileName: string;
  underStudentLimit: boolean;
  isHsSchool: boolean = false;
  isEmsSchool: boolean = false;
  title: string;
  itemCount: number;
  itemType: string;
  isProfileMode: boolean = false;
  reportOptions: Array<IDropdownOption> = [];
  isStartOfSchoolYearToggleActive: boolean;
  pdfDownloadSub: Subscription = Subscription.EMPTY;
  school: ISchool;
  restrictedExamView = false;
  currentExam = null;

  constructor(
    dialogRef: MatDialogRef<StudentReportModalComponent>,
    private store: Store<any>,
    private utils: UtilitiesService,
    private imSchool: ImSchool,
    private injector: Injector,
    private toggleService: ToggleService,
    private apiService: ApiService,
    private windowRef: WindowRef,
    private eventFormatterService: EventFormatterService,
    private mockRegentsService: MockRegentsService,
    private pdfReportsService: PDFReportsService,
    @Inject(forwardRef(() => ModalsService)) public modalsService: ModalsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef);
    this.selectedStudents = this.data.students;
    if (this.data.exam) {
      this.restrictedExamView = true;
      this.currentExam = this.data.exam;
    }
    this.underStudentLimit = this.selectedStudents.length <= 200;
    this.isProfileMode = this.data.isProfileMode;
    this.state = State.NOT_STARTED;
    this.mode = 'indeterminate';
    this.title = 'Generate reports';
  }

  ngOnInit(): void {
    // Get the school type
    this.store
      .select(getSchool)
      .pipe(take(1))
      .subscribe(school => {
        this.school = school;
        if (this.imSchool.isHighSchool(school)) this.isHsSchool = true;
        if (this.imSchool.isEmsSchool(school)) this.isEmsSchool = true;
      });

    this.isStartOfSchoolYearToggleActive = this.toggleService.getToggleState(Toggles.TOGGLE_START_OF_SCHOOL_YEAR);

    this.reportOptions = Object.entries(pdfReportTypes)
      .sort((a, b) => a[1].order - b[1].order)
      .filter(([reportKey, {districts}]) => {
        const validForDistrict = districts.includes(this.school.district);
        if (!validForDistrict) return false;
        if (reportKey === 'emsAssessment' || reportKey === 'hsAssessment') return this.validateAssmntReportOption(reportKey);
        else if (reportKey === 'regentsSched') return this.isHsSchool;
        else if (reportKey === 'attendance')
          return this.isStartOfSchoolYearToggleActive; // To remove attendance option over the summer ONLY
        else return true;
      })
      .map(([_, reportProperties]) => ({
        key: reportProperties.key,
        human: reportProperties.human,
        options: reportProperties.options,
        default: reportProperties.default,
      }));

    if (this.restrictedExamView) {
      this.reportOptions = this.mockRegentsService.removeOtherMockRegents(this.reportOptions, this.currentExam);
    } else {
      this.reportOptions = this.pdfReportsService.removeNonDefaultReports(this.reportOptions);
    }
    if (this.data.exam) this.selectedReportId = this.data.exam;
    // Set default option: summer: Snapshot, regular school year: Attendance
    else if (this.reportOptions.some((option) => option.key === 'attendance')) this.selectedReportId = 'attendance';
    else this.selectedReportId = 'snapshot';

    // Initialize the report state
    this.setSelectedReport(this.selectedReportId, this.selectedStudents);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public getReport (reportName: TValidPdfReportKeys, students: TStudent[], userProvidedFilename: string) {
    let lng = 'en'; // default language
    let mockRegent = null;
    let _reportName = reportName;

    if (reportName.includes('dessa_')) {
      _reportName = 'dessa';
      lng = reportName.split('_')[1];
    }

    if (reportName.includes('mockRegents_')) {
      _reportName = 'mockRegents';
      mockRegent = reportName.split('_')[1];
    }

    return {
      _id: this.utils.createV4Uuid(),
      studentIds: students.map(({ _id }) => _id),
      reportName: _reportName,
      lng,
      mockRegent,
      userProvidedFilename,
    };
  }

  // Event handler
  public handleReportTypeSelect(reportType: IDropdownOption) {
    this.setSelectedReport(reportType.key as TValidPdfReportKeys, this.selectedStudents);
  }

  // Prepares the report state for the selected report type
  private setSelectedReport(reportType: TValidPdfReportKeys, selectedStudents: TStudent[]): void {
    this.itemCount = selectedStudents.length;
    this.fileName = this.getFileName(reportType, selectedStudents[0].studentId);
    //Separating report type from report key mostly because of the special case of both hs and ems assessment mapping to 'assessment'
    const reportKey = reportType === 'hsAssessment' || reportType === 'emsAssessment' ? 'assessment' : reportType;
    this.report = this.getReport(reportKey, selectedStudents, this.fileName);
    this.itemType = 'student';
  }

  public getFileName(reportType: TValidPdfReportKeys, selectedStudentId: string): string {
    const dropdownOptions = this.retrieveDropdownOption(reportType);

    return this.getFileNameFromOptions(dropdownOptions, selectedStudentId);
  }

  //Pre-determine if the provided key represents one of the dessa sub-options, as opposed to one of the top-level options
  private isDessaType(reportType: TValidPdfReportKeys): boolean {
    return pdfReportTypes.dessa.options.some((option) => option.key === reportType);
  }

  private isMockRegentsType (reportType: TValidPdfReportKeys): boolean {
    return pdfReportTypes.mockRegents.options.some((option) => option.key === reportType);
  }

  private retrieveDropdownOption(reportType: TValidPdfReportKeys): IDropdownOption {
    if (this.isDessaType(reportType)) {
      //In the case of dessa, we are drilling one level deeper based on language.
      // But, still retrieving effectively the same set of props.
      return pdfReportTypes.dessa.options.find((option) => option.key === reportType);
    } else if (this.isMockRegentsType(reportType)) {
      return pdfReportTypes.mockRegents.options.find((option) => option.key === reportType);
    } else {
      return Object.values(pdfReportTypes).find((props) => props.key === reportType);
    }
  }

  private getFileNameFromOptions(dropdownOptions: IDropdownOption, selectedStudentId: string): string {
    const { exportFileNamePrefix, exportFileNameBody, exportFileNameSuffix } = dropdownOptions;

    let prefixWithUnderscore = exportFileNamePrefix ? `${exportFileNamePrefix}_` : '';

    let suffixWithUnderscore = exportFileNameSuffix ? `_${exportFileNameSuffix}` : '';

    const osisSuffix = this.itemCount === 1 ? `_${selectedStudentId}` : '';
    return `${prefixWithUnderscore}${exportFileNameBody}${suffixWithUnderscore}${osisSuffix}`;
  }

  private validateAssmntReportOption(reportKey: TValidPdfReportKeys): boolean {
    const hasGradeBand = this.selectedStudents.length && this.selectedStudents[0].isHS !== undefined;
    if (reportKey === 'emsAssessment') return this.validateAssmntReportOptionForEms(hasGradeBand);
    return this.validateAssmntReportOptionForHs(hasGradeBand);
  }

  private validateAssmntReportOptionForEms(hasGradeBand: boolean): boolean {
    if (this.selectedStudents.length === 1 && hasGradeBand) {
      return !this.selectedStudents[0].isHS;
    } else {
      return this.isEmsSchool;
    }
  }

  private validateAssmntReportOptionForHs(hasGradeBand): boolean {
    if (this.selectedStudents.length === 1 && hasGradeBand) {
      return this.selectedStudents[0].isHS;
    } else {
      return this.isHsSchool;
    }
  }

  public generate(): void {
    this.state = State.GENERATING;
    this.pdfDownloadSub = this.apiService
      .createReport(this.report)
      .pipe(
        switchMap((response) => {
          const backgroundJob = this.injector.get(BackgroundJob) as any;
          const jobId = response.headers.get('nv-background-jobs');
          return BatchActionsEffectsUtilities.getJobSubject(backgroundJob, { response, jobId });
        }),
        filter(({ type }) => type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE),
        switchMap(() => {
          const reportEvent = this.eventFormatterService.getReportEvent({
            report: this.report.reportName,
            view: this.isProfileMode ? 'STUDENT-PROFILE' : 'BATCH-ACTION',
            portal: 'SCHOOL',
          });
          const mixpanelEvents: TMixpanelEvent<any>[] = [reportEvent];

          if (!this.isProfileMode) {
            const batchActionMeta: IFormatBatchActionEventArgs = {
              item: 'Report',
              view: this.data.view,
              portal: 'SCHOOL',
              currentExam: this.data.reportHuman ?? null,
            };
            const batchActionEvent = this.eventFormatterService.getCreateBatchActionEvent(null, batchActionMeta);
            mixpanelEvents.push(batchActionEvent);
          }
          return this.apiService.getReport(this.report._id, mixpanelEvents);
        }),
        tap((report: any) => this.download(report._downloadUrl)),
        take(1),
      )
      .subscribe({ error: () => this.dialogRef.close() });
  }

  public download(url): void {
    this.windowRef.nativeWindow.location.assign(url); // Opens the url in the same tab
    super.close({ action: 'DOWNLOADED' });
  }

  public cancel(): void {
    if (!this.pdfDownloadSub.closed) {
      this.pdfDownloadSub.unsubscribe();
      this.state = State.NOT_STARTED;
      // Once a report is cancelled, a new report id is needed if the user makes another request to download a report
      this.setSelectedReport(this.selectedReportId, this.selectedStudents);
    } else {
      super.close();
    }
  }
}
