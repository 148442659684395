import { UserManagementSharedService } from 'Src/ng2/shared/services/user-management/user-management.shared.service';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BaseModalModule } from '../../../base-modal.module';
import { ShelterClusterUserModalShellService } from './shelter-cluster-user-modal-shell.service';
import { ShelterClusterUserModalsDataService } from './shelter-cluster-user-modal.data.service';
import { ShelterClusterUserModalShellComponent } from './shelter-cluster-user-modal-shell/shelter-cluster-user-modal-shell.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NvTooltipModule } from '../../../../nv-tooltip/nv-tooltip.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { NvFormFieldModule } from 'Src/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvIconButtonModule } from 'Src/nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvTextboxModule } from 'Src/nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvNoteModule } from 'Src/nvps-libraries/design/nv-note/nv-note.module';
import { NvDropdownModule } from 'Src/nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvPillButtonModule } from 'Src/nvps-libraries/design/nv-pill-button/nv-pill-button.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvDropdownItemModule } from 'Src/nvps-libraries/design/nv-dropdown-item/nv-dropdown-item.module';
import { HybridClusterUserAlertModule } from '../../../../components/user-management/hybrid-cluster-user-alert/hybrid-cluster-user-alert.module';
import { ClusterUserPortfolioListModule } from 'Src/ng2/shared/components/user-management/cluster-user-portfolio-list/cluster-user-portfolio-list.module';

@NgModule({
  declarations: [
    ShelterClusterUserModalShellComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    BaseModalModule,
    MatMenuModule,
    NvDropdownModule,
    NvDropdownItemModule,
    NvButtonModule,
    NvPillButtonModule,
    NvFormFieldModule,
    NvIconButtonModule,
    NvIconModule,
    NvTextboxModule,
    NvNoteModule,
    NvTooltipModule,
    HybridClusterUserAlertModule,
    ClusterUserPortfolioListModule,
  ],
  exports: [ShelterClusterUserModalShellComponent],
  providers: [
    ShelterClusterUserModalsDataService,
    ShelterClusterUserModalShellService,
    UserManagementSharedService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShelterClusterUserManagementModalModule {}
