import { ExcludeCollegesAlreadyPaths } from './path-category-forms/college-form/exclude-colleges-already-paths.pipe';
import { PublicServiceForm } from './path-category-forms/public-service-form/public-service-form.component';
import { OtherForm } from './path-category-forms/other-forms/other-form.component';
import { MilitaryForm } from './path-category-forms/military-form/military-form.component';
import { EmploymentForm } from './path-category-forms/employment-form/employment-form.component';
import { SubmitButton } from './path-category-forms/submit-button/submit-button.component';
import { StatusDropdown } from './path-category-forms/status-dropdown/status-dropdown.component';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { BatchEditService } from '../../services/batch-edit-service/batch-edit-service';
import { BaseModalModule } from '../base-modal.module';
import { StudentPathsModalComponent } from './student-paths-modal.component';
import { NvFormFieldModule } from './../../../../nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvDropdownModule } from './../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvTextboxModule } from './../../../../nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvNoteModule } from 'Src/nvps-libraries/design/nv-note/nv-note.module';
import { CareerTrainingProgramForm } from './path-category-forms/career-training-program-form/career-training-program-form.component';
import { CertificateProgramForm } from './path-category-forms/certificate-program-form/certificate-program-form.component';
import { CollegeForm } from './path-category-forms/college-form/college-form.component';

@NgModule({
  declarations: [
    StudentPathsModalComponent,
    CareerTrainingProgramForm,
    CertificateProgramForm,
    CollegeForm,
    EmploymentForm,
    MilitaryForm,
    OtherForm,
    PublicServiceForm,
    StatusDropdown,
    SubmitButton,
    ExcludeCollegesAlreadyPaths,
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    BaseModalModule,
    MatMenuModule,
    NvButtonModule,
    NvTextboxModule,
    NvDropdownModule,
    NvFormFieldModule,
    NvNoteModule,
    // CertificateProgramModalModule,
  ],
  exports: [StudentPathsModalComponent],
  providers: [BatchEditService, ApiService],
})
export class StudentPathsModule {}
