<base-modal class="base-modal edit-ecfik-guardian-modal" [title]="title" isProfileMode="false">
  <div class="form-container" *ngFor="let guardian of guardiansFormList.controls; index as i">
    <ng-container *ngIf="modalState === 'EDIT'">
      <div *ngIf="i === 0;then primaryGuardian else otherGuardian"></div>
        <ng-template #primaryGuardian>
          <div class="header">
            <h4>Primary guardian</h4>
          </div>
          </ng-template>
        <ng-template #otherGuardian>
          <div class="header">
            <h4>Guardian {{ i + 1 }}</h4>
            <nv-button type="secondary" size="small" (click)="deleteGuardian(i)">Delete</nv-button>
          </div>
        </ng-template>
    </ng-container>
    

    <div class="name-section">
      <div class="first-name">
        <nv-form-field label="First name"></nv-form-field>
        <nv-textbox [textControl]="guardian.controls.firstName" placeholder="First name" [hasClearBtn]="true" (clearValue)="onClearName(i, 'firstName')"></nv-textbox>
      </div>
      <div class="last-name">
        <nv-form-field label="Last name"></nv-form-field>
        <nv-textbox [textControl]="guardian.controls.lastName" placeholder="Last name" [hasClearBtn]="true" (clearValue)="onClearName(i, 'lastName')"></nv-textbox>
      </div>
    </div>
    <nv-note *ngIf="restrictedContactNameLookup[i]" priority="danger">This name is found on a restricted contact list and cannot be saved.</nv-note>

    <div class="contact">
      <nv-form-field label="Email"></nv-form-field>
      <nv-textbox [textControl]="guardian.controls.email" placeholder="Email"></nv-textbox>
    </div>

    <div class="contact">
      <nv-form-field label="Mobile"></nv-form-field>
      <nv-textbox
        placeholder="Mobile" 
        [showInvalid]="true"
        [hasClearBtn]="true"
        [textControl]="guardian.controls.mobile"
        (clearValue)="onClearMobile(i)"
        inputType="phoneNumber">
      </nv-textbox>
      <nv-note *ngIf="restrictedContactMobileLookup[i]" priority="danger">This number is found on a restricted contact list and cannot be saved.</nv-note>
    </div>
  </div>

  <mat-dialog-actions class="dialog-actions button-group">
    <nv-button type="secondary" (clickButton)="onCancel()">
      Cancel
    </nv-button>
    <nv-button htmlType="submit" (clickButton)="onSave()" [disabled]="guardiansFormList.invalid">
      Save
    </nv-button>
  </mat-dialog-actions>
</base-modal>