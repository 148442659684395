import { Injectable } from '@angular/core';
import { INavItem, getSmallSideNavConfig, ContentAreaNavItems, MoreToolsNavItems } from './side-nav.config';
import { TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { ISidebarItem } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
import { ISchool, ISummerSchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { TPortalLocation } from 'Src/ng2/shared/typings/interfaces/portal.interface';
import { District, TDistricts } from 'Src/ng2/shared/typings/interfaces/district.interface';
import { ISidenav } from './side-nav.component';
import { USER_ROLE_PERMISSIONS_FOR_GUARDS } from 'Src/ng2/routing/guards/user-role-permissions-for-route-guards.constant';
import { TToggles, Toggles } from 'Src/ng2/shared/constants/toggles.constant';
import { filter } from 'lodash';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { ToggleService } from 'Src/ng2/shared/services/toggle/toggle.service';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class SideNavConfigService {
  constructor (
    private objectCache: ObjectCache,
    private imSchool: ImSchool,
    private imUser: ImUser,
    private toggleService: ToggleService,
    private urlPathService: UrlPathService,
  ) {}

  public getSideNav (opts: {
    school: ISchool;
    selectedView: TPortalLocation;
    contextPartnerId: string;
    contextPartnerType: TValidPartnerTypes;
    district: TDistricts;
    user: IUser;
    route: ActivatedRoute,
  }): ISidenav | any {
    const { school, user, contextPartnerId } = opts;
    const isCaringAdult = this.imUser.isCaringAdult(user, contextPartnerId);
    const isProgramPoint = this.imUser.isProgramPoint(user, contextPartnerId);
    const isSummerSchool = this.imSchool.isSummerSchool(school as ISummerSchool, user);
    const isUftDoeAdvisingUser = this.imUser.isUftDoeAdvisingUser(user);
    const isCaseloadUser = this.imUser.isCaseloadUser(user);
    const preFilterOpts = {
      user,
      contextPartnerId,
      isCaringAdult,
      isProgramPoint,
      isSummerSchool,
      isUftDoeAdvisingUser,
      isCaseloadUser,
    };
    const ContentAreaConfig = this.getMenuItems(ContentAreaNavItems, { preFilterOpts, ...opts });
    const MoreToolsConfig = this.getMenuItems(MoreToolsNavItems, { preFilterOpts, ...opts });
    const settingsRoute = this.getSettingsFirstDefaultSubRoute(school);
    const SmallSideNavConfig = getSmallSideNavConfig(settingsRoute);
    const smallNav = this.getMenuItems(SmallSideNavConfig, { preFilterOpts, ...opts });
    return { contentArea: ContentAreaConfig, tools: MoreToolsConfig, smallNav };
  }

  private getMenuItems (
    configs: INavItem[],
    opts: {
      school: ISchool;
      selectedView: TPortalLocation;
      contextPartnerId: string;
      contextPartnerType: TValidPartnerTypes;
      district: TDistricts;
      user: IUser;
      route: ActivatedRoute;
      preFilterOpts?: any;
    },
  ): ISidebarItem[] {
    const { school, selectedView, district, contextPartnerId, contextPartnerType, user, preFilterOpts, route } = opts;
    const preFilteredConfigs = configs.reduce(this.filterItems(preFilterOpts), []);
    const allowedOptions = this.validateMenuOptions(preFilteredConfigs, {
      school,
      selectedView,
      contextPartnerId,
      contextPartnerType,
      district,
      user,
    });
    const sortedMenuOptions = allowedOptions.sort((a, b) => a.order - b.order);
    const formattedNavItems = this.shapeNavItems(sortedMenuOptions, {
      contextPartnerId,
      contextPartnerType,
      computeDistrictUrlPath: this.urlPathService.computeDistrictUrlPath.bind(this.urlPathService),
      route,
    });
    const cachedNavItems = this.cacheDefaultQueryParams(formattedNavItems);
    const unwrappedItems: ISidebarItem[] = cachedNavItems?.map(this.unwrapSingleChildren.bind(this));
    return unwrappedItems || [];
  }

  // Check user role permissions and school type using the same service methods as in RouteGuard and SchoolTypeGuard.
  // Applicable to all menu items
  private validateMenuOptions (
    options: INavItem[],
    opts: {
      school: ISchool;
      selectedView: TPortalLocation;
      contextPartnerId: string;
      contextPartnerType: TValidPartnerTypes;
      district: TDistricts;
      user: IUser;
    },
  ): INavItem[] {
    const { contextPartnerId, contextPartnerType, user } = opts;
    const valid = this.validate(options, opts);
    const authorized = this.isRestricted(valid, { user, contextPartnerType, contextPartnerId });
    return authorized;
  }

  /*
    Restrict this schools links based on current user
    only accounts for urls in the side nav that have school routes
    only restricts based on user role permissions
    This implementation should be reviewed as part of the following refactor:
    https://newvisions.atlassian.net/browse/PI-2672 (AB)
  */
  private isRestricted (options: INavItem[], opts: { user, contextPartnerType, contextPartnerId }): any[] {
    const { user, contextPartnerType, contextPartnerId } = opts;
    const allowedOptions = filter(options, (option: any) => {
      if (option.key === 'OTHER_TOOLS') return true;
      // get restrictions for all items
      if (option.isActive) {
        if (option.url) {
          const _url = typeof option.url === 'function' ? option.url(contextPartnerType) : option.url;
          const partnerIdPathParam = contextPartnerType === 'school' ? ':schoolId' : ':shelterId';
          const pathToAppend = `${contextPartnerType}/${partnerIdPathParam}`;
          const rolesThatCanAccessRoute = USER_ROLE_PERMISSIONS_FOR_GUARDS[`${pathToAppend}/${_url}`];
          const authorized = this.imUser.isRoleAuthorized({
            user,
            rolesThatCanAccessRoute,
            partnerId: contextPartnerId,
            partnerType: contextPartnerType,
          });
          option.isRestricted = !authorized;
        } else if (option.children) {
          const authorizedChildren = this.isRestricted(option.children, opts);
          option.children = authorizedChildren;
          option.isRestricted = !authorizedChildren.length;
        } else {
          option.isRestricted = false;
        }

        if (option.key === 'MY_TASKS') {
          if (!this.imUser.isSchoolNotClusterUser(user)) {
            option.isRestricted = true;
          }
        }
      }
      return option.isRestricted === false;
    });
    return allowedOptions;
  }

  private validate (
    options: INavItem[],
    opts: {
      school: ISchool;
      selectedView: TPortalLocation;
      contextPartnerType: TValidPartnerTypes;
      district: TDistricts;
    },
  ): INavItem[] {
    const { school, selectedView, contextPartnerType, district } = opts;
    const validOptions = options.filter((option: INavItem) => {
      const { schoolTypes, districts, portalLocations, toggleKeys, children } = option;
      if (children) {
        const validChildren = this.validate(children, opts);
        option.children = validChildren;
      }
      return (
        this.isCorrectPortalLocation(selectedView, portalLocations) &&
        this.isCorrectDistrict(district, districts) &&
        this.imSchool.hasCorrectSchoolType(school, schoolTypes, contextPartnerType) &&
        this.isBehindActiveToggle(toggleKeys)
      );
    });
    return validOptions;
  }

  private isCorrectDistrict (district: string = District.NYC, districtTypes: Array<string>): boolean {
    return districtTypes.includes(district);
  }

  private isCorrectPortalLocation (portalLocation: TPortalLocation, portalTypes: Array<TPortalLocation>): boolean {
    return portalTypes.includes(portalLocation);
  }

  private isBehindActiveToggle (toggleKeys?: TToggles[]): boolean {
    if (!toggleKeys || toggleKeys.length === 0) return true;
    const activeToggles = toggleKeys.some(toggle => this.toggleService.getToggleState(toggle));
    return activeToggles;
  }

  private cacheDefaultQueryParams (items: ISidebarItem[]): ISidebarItem[] {
    return items.map(item => {
      const defaults = item.queryParams;
      const queryParamsToHash = item.queryParamsToHash || [];
      let cachedDefaults;
      if (defaults) {
        cachedDefaults = Object.assign({}, defaults);
        for (const param in defaults) {
          const obj = defaults[param];
          if (obj && queryParamsToHash.includes(param)) {
            cachedDefaults[param] = this.objectCache.cacheObject(obj);
          }
        }
        return { ...item, queryParams: cachedDefaults };
      }
      return { ...item };
    });
  }

  private shapeNavItems (
    configs: INavItem[],
    opts: { parent?: string, contextPartnerId: string; contextPartnerType: TValidPartnerTypes; computeDistrictUrlPath: Function; route: ActivatedRoute },
  ): ISidebarItem[] {
    const { parent, contextPartnerId, contextPartnerType, computeDistrictUrlPath, route } = opts;
    const shapedItems = configs.map(item => {
      const { humanName, key, url, queryParams, children, hasBetaFlag, leftDefaultIcon, leftSelectedIcon, queryParamsToHash } = item;
      const formattedChildren = children
        ? this.shapeNavItems(children, { parent: key, contextPartnerId, contextPartnerType, computeDistrictUrlPath, route })
        : null;
      const expandAs = children ? ('accordion' as const) : null;
      const _url = typeof url === 'function' ? url(contextPartnerType) : url;
      const calculatedUrl = _url ? computeDistrictUrlPath(`/${contextPartnerType}/${contextPartnerId}/${_url}`) : null;
      const calculatedQueryParams = typeof queryParams === 'function' ? queryParams(contextPartnerType, route.snapshot.queryParams) : queryParams;
      return {
        human: humanName,
        key: key || humanName,
        expandAs,
        children: formattedChildren,
        parent,
        url: calculatedUrl,
        hasBetaFlag: hasBetaFlag || false,
        leftDefaultIcon,
        leftSelectedIcon,
        queryParams: calculatedQueryParams,
        queryParamsToHash,
        skipUnwrap: item.skipUnwrap,
      };
    });
    return shapedItems;
  }

  // Returns the first default sub route when on settings route,
  // depends on the school type and user role type
  // TODO - jchu : move to a route guard (e.g.SettingsRouteGuard) and put into SettingsRoutingModule
  private getSettingsFirstDefaultSubRoute (school: ISchool): string {
    const { district } = school;
    const defaultSettingsRoute = 'settings/supports';
    if (district === 'Lansing') return defaultSettingsRoute;
    // After Removing Old Metrics [POD-1335], all school types/users default to settings/supports
    else return defaultSettingsRoute;
  }

  /**
   * A recursive catch all reduce function that filters the available nav items based on
   * logic not included in the configs.
  */
  private filterItems (opts: {
    isUftDoeAdvisingUser: boolean;
    isSummerSchool: boolean;
    isProgramPoint: boolean;
    isCaringAdult: boolean;
    isCaseloadUser: boolean;
    user: IUser;
    contextPartnerId: string;
  }) {
    return (acc: INavItem[], item: INavItem) => {
      const { isUftDoeAdvisingUser, isSummerSchool, isProgramPoint, isCaringAdult, isCaseloadUser, user, contextPartnerId } = opts;
      // Add filter logic here
      switch (item.key) {
        case 'SUPPLEMENTAL_ADVISING':
          if (!isUftDoeAdvisingUser) return acc;
          break;
        case 'SUMMER_SCHOOL_LIST':
          /*
            special case toggle check for summer school list as there are
            scenearios where summerSchoolToggleOn and this.isSummerSchool
            may not align.
          */
          // eslint-disable-next-line no-case-declarations
          const summerSchoolToggleOn = this.toggleService.getToggleState(Toggles.TOGGLE_SUMMER_SCHOOL);
          if (!summerSchoolToggleOn || !isSummerSchool) return acc;
          break;
        case 'MANAGE_FAMILIES':
          if (!isProgramPoint && !isCaringAdult) {
            return acc;
          }
          break;
        case 'MY_FAMILIES':
          if (!isProgramPoint || !this.hasEcfikStudentCaseload(user, contextPartnerId)) return acc;
          break;
        case 'MANAGE_PROGRAM':
          if (!isProgramPoint) return acc;
          break;
        case 'REGENTS_RESULTS':
          if (isCaseloadUser) {
            item.url = 'regents-results-grid';
          }
          break;
        default:
          break;
      }
      // end filter logic

      const updatedItem = { ...item };
      if (item?.children?.length > 0) {
        updatedItem.children = item.children.reduce(this.filterItems(opts), []);
      }
      acc.push(updatedItem);
      return acc;
    };
  }

  private hasEcfikStudentCaseload (user: IUser, contextPartnerId: string): boolean {
    const caseload = user._ecfikPermissions?.studentCaseload?.find(caseload => caseload.schoolId === contextPartnerId);
    return !!caseload?.studentIds?.length;
  }

  private unwrapSingleChildren (item: ISidebarItem): ISidebarItem {
    const { children, human, key, leftDefaultIcon, leftSelectedIcon, skipUnwrap } = item;
    if (skipUnwrap) return item;
    if (children?.length === 1) {
      return { ...children[0], human, key, leftDefaultIcon, leftSelectedIcon };
    } else {
      return item;
    }
  }
}
