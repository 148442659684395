import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ManualCoursePlanComponent } from './manual-course-plan.component';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvFormFieldModule } from 'Src/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvDropdownModule } from 'Src/nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvTextboxModule } from 'Src/nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvTextareaModule } from 'Src/nvps-libraries/design/nv-textarea/nv-textarea.module';
import { NvNoteModule } from 'Src/nvps-libraries/design/nv-note/nv-note.module';

@NgModule({
  declarations: [ManualCoursePlanComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    NvFormFieldModule,
    NvButtonModule,
    NvDropdownModule,
    NvTextboxModule,
    NvTextareaModule,
    NvNoteModule,
  ],
  exports: [ManualCoursePlanComponent],
  providers: [],
})

export class ManualCoursePlanModule { }
