import { Injectable } from '@angular/core';

@Injectable()
export class GraphQlNetworkSideNavHelperService {
  getNetworkSideNavConfigQuery (clusterId: string): string {
    const validClusterId = clusterId ? `"${clusterId}"` : 'null';
    const query = `{
      NetworkSideNavConfig(
        clusterId: ${validClusterId}
      ) {
          featureSet
          clusterType
          schoolTypes
        }
      }`;
    return query;
  }
}
