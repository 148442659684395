import { IExplanationCard, IActionItem } from './mock-regents-splash.component';

export const mockRegentsIsActive = false;

export const mockRegentsSplashMessages = {
  welcome: 'Welcome',
  announcementTitle: 'Mock Regents Now Available',
  announcementParagraph: "Administer New Visions' mock exams, and submit your answer sheets below (Step 4) to see results! Sign up for a training session to learn more.",
  announcementButton: 'Sign Up For Training Session',
  announcementGuideLink: 'View How-To Guide',
  explanationTitle: "What's included in mock Regents results",
  actionTitle: 'Get started now!',
};

export const mockRegentsClosedSplashMessages = {
  announcementTitle: 'Mock Regents closed for Spring 2024',
  announcementParagraph: 'We are no longer accepting answer sheets for the Spring 2024 administration. Check back for details about our next mock administration.',
  announcementButton: 'Learn More',
  explanationTitle: "What's included in mock Regents results",
};

export const links = {
  trainingSessionLink: 'https://events.newvisions.org/portalhubpage',
  howToLink: 'https://docs.google.com/document/d/1XtHp8wAWSabs-zcS9iz2ZDEDS45BSOo2oEFqjm_wXHs/edit?usp=sharing',
  learnMoreLink: 'https://intercom.help/portal-by-new-visions/en/articles/9424799-prepare-students-for-exams-with-the-portal-s-mock-regents-feature',
};

export const explanationCards = [
  {
    icon: 'standard-default',
    title: 'Area/Standard Analysis',
    detail: 'Identify the areas/standards that presented challenges for students, and which students struggled the most.',
  } as IExplanationCard,
  {
    icon: 'student-default',
    title: 'Student Results',
    detail: 'View results for each student, along with key data like exam status, course details, grades, attendance, and more.',
  } as IExplanationCard,
  {
    icon: 'question-default',
    title: 'Question Analysis',
    detail: 'Assess which multiple choice questions were difficult, and which wrong answers were distractions.',
  } as IExplanationCard,
];

export const actionItems = [
  {
    step: 'Step 1:',
    title: 'Download exam booklets and bubble sheets',
    description: 'Select from up to 6 June 2023 Regents exams to administer. Download and print the exam booklets and provided answer sheets from the Google Drive folder linked here. You must use New Visions provided answer sheets.',
    hasButton: true,
    buttonText: 'View Exam Resources',
    buttonDisabled: false,
    link: 'https://drive.google.com/drive/folders/1ZiuweIrKww71lXB4O-umwiSKvMqgeE5F?usp=sharing',
    icon: 'download-default',
    hasPill: false,
  } as IActionItem,
  {
    step: 'Step 2:',
    title: 'Administer and Score',
    description: 'Administer the mock Regents exams to students then score the constructed response and essay questions and bubble in points. Please verify student OSIS numbers are bubbled in correctly on each answer sheet.',
    hasButton: true,
    buttonText: 'View Scoring Materials',
    buttonDisabled: false,
    link: 'https://drive.google.com/drive/folders/1QflFFkXp1hNfDAsMcVvmtrd518vSvy1x?usp=drive_link',
    icon: 'administer-default',
    hasPill: false,
  } as IActionItem,
  {
    step: 'Step 3:',
    title: 'Scan to PDF',
    description: 'Using a printer in your school scan answer sheets into a single PDF file for each exam. Most printers have a scan to PDF setting.',
    hasButton: false,
    buttonDisabled: true,
    icon: 'scan-default',
    hasPill: false,
  } as IActionItem,
  {
    step: 'Step 4:',
    title: 'Submit PDF files',
    description: 'Submit PDF files for each exam to New Visions using the Google Form linked below. A New Visions staff member will reach out to you when your results are available. You can expect a 2-3 day turn around time.',
    hasButton: true,
    buttonText: 'Submit Data',
    buttonDisabled: false,
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSd3uKLiWNF6-e36B6uBtsxDdy4OoHQsjN1kdG56XqDWpk0pdQ/viewform',
    icon: 'upload-default',
    hasPill: true,
    pillText: 'Now Available',
  } as IActionItem,
];

export const pageBannerMeta = {
  titleOrMessage: 'Mock Regents',
};
