import { EventFormatterService } from 'Src/ng2/shared/services/mixpanel/event-formatter.service';
import { HistoryAllModalMoreButtonComponent } from '../../components/server-side-grid/history-all-modal-more-button/history-all-modal-more-button.component';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { ColDef, GridOptions, ValueFormatterParams, IServerSideGetRowsParams, Module, AgGridEvent, GridApi } from '@ag-grid-community/core';
import { Component, Inject, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { PartnerTypes, TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import { ApiService } from './../../services/api-service/api-service';
import { HistoryAllHelperServiceShelter } from './history-all-helper-shelter.service';
import { IHistoryRecord, HistoryAllHelperService } from './history-all-helpers.service';
import { NoRowsComponent } from '../../components/server-side-grid/no-rows/no-rows.component';
import { INotesData } from '../notes/notes-modal-shell.component';
import { VALID_NOTE_MODES } from '../../typings/interfaces/note.interface';
import { ModalsService } from '../modals.service';
import { getCurrentUser, getSchool } from 'Src/ng2/store';
import { select, Store } from '@ngrx/store';
import { IUser } from '../../typings/interfaces/user.interface';
import { ImUser } from '../../services/im-models/im-user';
import { SnackBarService } from '../../services/snackbar/snackbar.service';
import { ISchoolUserIdentifier } from '../user-management/school-user/school-user-modals.config';
import { CategoryTagsComponent } from '../../components/server-side-grid/category-tags-renderer/category-tags-renderer.component';
import { IConfirmModalComponentData } from '../confirm/confirm-modal.component';
import { switchMap } from 'rxjs/operators';
import { IHistoryLogsFilterOptions } from '../../typings/interfaces/docLogs.interface';
import { of, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPickerOption } from 'projects/shared/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { isEqual, unionWith } from 'lodash';
import { ToggleService } from '../../services/toggle/toggle.service';
import { Toggles } from '../../constants/toggles.constant';
export type TShelterHistoryLogsComponent = 'SHELTER_ATT_LIST' | 'SHELTER_STUDENT' | 'SHELTER_DATA_GRID';
export type TSchoolHistoryLogsComponent = 'SCHOOL_DATA_GRID' | 'STUDENT' | 'SCHOOL_LIST';

export type THistoryLogsComponent = TShelterHistoryLogsComponent | TSchoolHistoryLogsComponent;

interface IBasicHistoryModalData {
  studentData: any;
  listType: THistoryLogsComponent;
}

export interface IShelterHistoryModalData extends IBasicHistoryModalData {
  shelterId?: string;
  schoolId?: string;
}

export interface ISchoolHistoryModalData extends IBasicHistoryModalData {
  schoolId: string;
}

export interface IHistoryModalData extends IBasicHistoryModalData {
  shelterId?: string;
  schoolId?: string;
}

export interface IHistoryAllModalMoreButtonEmitData {
  action: 'EDIT' | 'DELETE',
  noteData: {
    schoolId: string,
    noteId: string,
    studentId?: string,
    note: INotesData,
  },
  user: ISchoolUserIdentifier;
}

@Component({
  selector: 'history-all-modal',
  templateUrl: './history-all-modal.component.html',
  styleUrls: ['./history-all-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HistoryAllModalComponent implements OnInit {
  // Header
  public studentName: string;
  public options: any = ['All logs', 'Notes' ];
  public categoryOptions: IPickerOption[];
  public listIndex = 0;

  // Data
  public listType: THistoryLogsComponent;
  public caresId: string;
  public studentId: string;
  public partnerType: TValidPartnerTypes;
  public shelterId: string;
  public schoolId: string;
  public modalTitle: string;
  public currentPage = 0;
  public hasNextLogs: boolean = true; // OnInit assume student has more history logs
  public hasPrevLogs: boolean = false;
  public hasLogs: boolean = true;
  isHighSchool: boolean;
  public filterOptions$: Observable<any>;
  public hasAppliedFilters: boolean = false;
  public userSelectedFilters: IHistoryLogsFilterOptions = {
    categories: [],
    users: [],
    startDate: null,
    endDate: null,
  };

  public overlayNoLogsMessage: any = () => `
    <div class="no-logs-container">
      <span class="primary-message">There are no history items for this student.</span>
    </div>
  `;

  public currentSchoolSubscription: Subscription;
  public currentUserSubscription: Subscription;
  public currentSchool: ISchool;
  public currentUser: IUser;
  public userCanEdit: boolean;
  public v4ModeIsOn: boolean;

  // ag-grid
  private cacheBlockSize = 30;  // LIMIT that the Server allows
  private gridApi: GridApi;
  private notesData: INotesData;
  public modules: Module[] = [ServerSideRowModelModule];
  public gridOptions: GridOptions;
  public columnDefs: ColDef[];
  public columns0: ColDef[] = [
    {
      field: "log",
      headerName: 'All logs',
      width: 600,
      valueGetter: 'node._id',
      autoHeight: true,
      suppressMovable: true,
      wrapText: true,
      headerClass: 'header-first-column-custom-text',
      cellClass: 'cell-custom-text cell-first-column',
      cellRenderer: 'categoryTagsCellRenderer',
      cellRendererParams: {
        parentName: 'history-all-modal',
        displayCatsInBody: true,
      },
      filter: 'agTextColumnFilter',
    },
    {
      field: "actionTakenBy",
      headerName: 'Action taken by',
      width: 150,
      wrapText: true,
      suppressMovable: true,
      filter: 'agTextColumnFilter',
      headerClass: 'header-custom-text',
      cellClass: ['cell-custom-text-center'],
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.node.data) return;
        const createdBy = params.data.createdBy;
        const name = `${createdBy.firstName} ${createdBy.lastName}`;
        return name;
      },
    },
    {
      field: "actionTakenOn",
      headerName: 'Action taken on',
      width: 150,
      suppressMovable: true,
      headerClass: 'header-custom-text',
      cellClass: ['cell-custom-text-center'],
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.node.data) return;
        let historyRecord: IHistoryRecord;
        if (params.node.data.schoolId) {
          historyRecord = this.historyAllHelperService.constructHistoryRecord(params.node.data);
        } else {
          historyRecord = this.historyAllHelpersShelter.constructHistoryRecord(params.node.data);
        }
        const age = historyRecord?.age;
        return age;
      },
    },
    {
      field: 'historyAllModalMoreButton',
      headerName: '',
      width: 50,
      suppressMovable: true,
      cellClass: ['cell-custom-text', 'cell-custom-text-center'],
      cellRenderer: 'historyAllModalMoreButtonCellRenderer',
      cellRendererParams: {
        parentName: 'history-all-modal',
        parentComponent: this,
      },
    },
  ];

  public columns1: ColDef[] = [{ ...this.columns0[0], headerName: 'Notes' }, this.columns0[1], this.columns0[2], this.columns0[3]];

  constructor (
    public dialogRef: MatDialogRef<HistoryAllModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IHistoryModalData,
    public apiService: ApiService,
    private historyAllHelpersShelter: HistoryAllHelperServiceShelter,
    private historyAllHelperService: HistoryAllHelperService,
    private modalsService: ModalsService,
    private store: Store<any>,
    private imUser: ImUser,
    public snackBarService: SnackBarService,
    private toggleService: ToggleService,
    private eventFormatterService: EventFormatterService,
  ) {}

  public ngOnInit (): void {
    this.v4ModeIsOn = this.toggleService.getToggleState(Toggles.TOGGLE_V4_NEW_SKIN_MODE);
    const { studentData, listType } = this.data;
    const { shelterId } = this.data as IShelterHistoryModalData;
    const { schoolId } = this.data as ISchoolHistoryModalData;
    this.listType = listType;
    this.studentName = this._getStudentName(studentData, listType);
    this.modalTitle = this._getModalTitle(this.studentName);
    this.shelterId = shelterId;
    this.schoolId = schoolId;
    if (shelterId) {
      this.caresId = this._getCaresId(studentData, listType);
      this.partnerType = PartnerTypes.SHELTER;
    } else {
      this.studentId = this._getStudentId(studentData, listType, schoolId);
      this.partnerType = PartnerTypes.SCHOOL;
    }

    this.columnDefs = this.columns0;
    this.currentUserSubscription = this.store.pipe(select(getCurrentUser)).subscribe((user: IUser) => {
      const isEditingUser = this.imUser.isEditingUser(user, { partnerType: this.partnerType });
      this.userCanEdit = isEditingUser;
      this.currentUser = user;
    });

    this.currentSchoolSubscription = this.store.pipe(select(getSchool)).subscribe((school: ISchool) => {
      this.currentSchool = school;
    });

    this.userSelectedFilters = this.historyAllHelperService.getDefaultUserSelectedFilters();
    this.hasAppliedFilters = false;

    this.gridOptions = {
      rowModelType: 'serverSide',
      blockLoadDebounceMillis: 30, // allows to skip blocks without loading them
      getRowId: ({ data: { _id } }) => (_id),
      cacheBlockSize: this.cacheBlockSize,
      serverSideInfiniteScroll: true, // sets serverSideStoreType to partial
      components: {
        noRowsComponent: NoRowsComponent,
        categoryTagsCellRenderer: CategoryTagsComponent,
        historyAllModalMoreButtonCellRenderer: HistoryAllModalMoreButtonComponent,
      },
      noRowsOverlayComponent: 'noRowsComponent',
      noRowsOverlayComponentParams: {
        parentComponent: this,
        parentName: 'history-all-modal',
      },
    };

    this._setFilterOptions();
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public ngOnChanges (changes: SimpleChanges) {
    const { docLogs, currentStudent } = changes;
    if ((docLogs && !docLogs.firstChange) || (currentStudent && !currentStudent.firstChange)) {
      this.userSelectedFilters = this.historyAllHelperService.getDefaultUserSelectedFilters();
    }
    this.hasAppliedFilters = this.historyAllHelperService.checkForAppliedFilters(this.userSelectedFilters);
    this._setFilterOptions();
  }

  public onListToggle (event: number): void {
    if (this.hasLogs && this.listIndex !== event) {
      this.listIndex = event;
      this.columnDefs = event === 0 ? this.columns0 : this.columns1;
      this.gridApi.setServerSideDatasource({
        getRows: this.getRows.bind(this),
      });
      this.currentPage = 0;
      this.hasNextLogs = true;
    }
  }

  public onGridReady(params: AgGridEvent) {
    this.gridApi = params.api;
    this.gridApi.setServerSideDatasource({
      getRows: this.getRows.bind(this),
    });
  }

  public editNoteButton (listType: THistoryLogsComponent, docId: string, note: INotesData) {
    const sharedNoteFields = {
      currentUser: this.currentUser,
      studentName: this.studentName,
      partnerType: this.partnerType,
      mode: VALID_NOTE_MODES.EDIT_MODAL,
    };
    switch (listType) {
      case ('SHELTER_STUDENT'):
        this.notesData = {
          ...sharedNoteFields,
          docId,
          note,
          shelterId: this.shelterId,
          caresId: this.caresId,
        };
        break;
      case ('SHELTER_DATA_GRID'):
        this.notesData = {
          ...sharedNoteFields,
          docId,
          note,
          shelterId: this.shelterId,
          caresId: this.caresId,
        };
        break;
      case ('SCHOOL_DATA_GRID'):
        this.notesData = {
          ...sharedNoteFields,
          school: this.currentSchool,
          docId,
          note,
          studentId: this.studentId,
        };
        break;
      default:
        this.notesData = {
          ...sharedNoteFields,
          school: this.currentSchool,
          docId,
          note,
          studentId: this.studentId,
        };
    }
    this.modalsService
      .openNoteModal(this.notesData)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.snackBarService.showToast({ toastText: 'This note was updated.' });
          this.refreshModal();
        }
      });
  }

  public deleteNoteButton (docId: any) {
    const data: IConfirmModalComponentData = {
      title: 'Delete note',
      message: 'Are you sure you would like to delete this note? This note will be removed from the student\'s records. This action cannot be undone.',
      confirmText: 'Delete',
    };
    this.modalsService
      .openConfirmModal(data)
      .afterClosed()
      .pipe(
        switchMap((confirmed: boolean) => {
          if (confirmed) {
            const noteId = docId;
            const deleteNoteEvent = this.eventFormatterService.getDeleteNoteEvent({
              view: 'GRID-ROW',
              portal: 'SHELTER', // history-all-modal used to manage notes in shelter portal grid only
              categories: [],
            });
            return this.apiService.patchNote(noteId, { status: 'DELETED' }, this.partnerType, deleteNoteEvent);
          } else {
            return of(false);
          }
        },
        ),
      ).subscribe(res => {
        if (res) {
          this.snackBarService.showToast({ toastText: 'This note was deleted.' });
          this.refreshModal();
        }
      });
  };

  public onAddNote (): void {
    const data = this.data;
    const listType = this.data.listType;
    const sharedNoteFields = {
      partnerType: this.partnerType,
      currentUser: this.currentUser,
      studentName: this.studentName,
    };
    switch (listType) {
      case ('SHELTER_STUDENT'):
      case ('SHELTER_DATA_GRID'):
      case ('SHELTER_ATT_LIST'):
        this.notesData = {
          ...sharedNoteFields,
          shelterId: data.shelterId,
          caresId: this.caresId,
          mode: VALID_NOTE_MODES.CREATE_SHELTER,
        };
        break;
      case ('SCHOOL_DATA_GRID'):
        this.notesData = {
          ...sharedNoteFields,
          school: this.currentSchool,
          studentId: this.studentId,
          mode: VALID_NOTE_MODES.CREATE,
        };
        break;
      default:
        this.notesData = {
          ...sharedNoteFields,
          school: this.currentSchool,
          studentId: this.studentId,
          mode: VALID_NOTE_MODES.CREATE,
        };
    }

    this.modalsService
      .openNoteModal(this.notesData)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.snackBarService.showToast({ toastText: 'This note was added.' });
          this.refreshModal();
        }
      });

  };

  public refreshModal (): void {
    this.gridApi.refreshServerSide({ purge: true });
    this.currentPage = 0;
    this.hasNextLogs = true;
    this._setFilterOptions();
  }

  private getRows(params: IServerSideGetRowsParams): void {
    const whereOptions = this.historyAllHelperService.getFilterQueryOptions({
      userSelectedFilters: this.userSelectedFilters,
      partnerType: this.partnerType,
      isNotesView: this.listIndex === 1,
      studentId: this.studentId,
      caresId: this.caresId,
    });

    const queryOptions = {
      page: this.currentPage + 1,
      limit: this.cacheBlockSize,
      where: whereOptions,
    };

    const partnerId = this.shelterId ? this.shelterId : this.schoolId;

    this.apiService.getPaginatedDocLogs(this.partnerType, partnerId, queryOptions)
      .subscribe((data: any) => {
        const filteredLogs = this.historyAllHelperService._filterDuplicateNoteLogs(data.data);
        const filteredDeletedLogs = this.historyAllHelperService._filterDeletedNotes(filteredLogs);
        let totalLogs = data.items.total;
        const areAllNotesDeletedInNoteToggle = this.listIndex === 0 ? false : this.historyAllHelperService._checkIsAllNoteLogsDeleted(filteredLogs);

        if (totalLogs === 0 || areAllNotesDeletedInNoteToggle) {
          params.api.showNoRowsOverlay()
          if (this.listIndex === 0 && !this.hasAppliedFilters) this.hasLogs = false;
        } else {
          params.api.hideOverlay();
          this.hasLogs = true;
        }
        if(this.hasNextLogs){
          if (this.listIndex === 1) {
            totalLogs = totalLogs - (data.data.length - filteredDeletedLogs.length);
            params.success({ rowData: filteredDeletedLogs, rowCount: totalLogs });
            this.currentPage = data.pages.current;
            this.hasNextLogs = data.pages.hasNext;
          } else {
            totalLogs = totalLogs - (data.data.length - filteredLogs.length);
            params.success({ rowData: filteredLogs, rowCount: totalLogs });
            this.currentPage = data.pages.current;
            this.hasNextLogs = data.pages.hasNext;
          }
        }
      })
    this.gridApi.sizeColumnsToFit();
  }

  private _getFormattedStudentName(name: string): string {
    const lastFirstNameArr = name.split(',');
    return `${lastFirstNameArr[1].trim()} ${lastFirstNameArr[0]}`
  }

  private _getStudentName (data: any, listType: THistoryLogsComponent): string {
    let name: string;
    switch (listType) {
      case ('SHELTER_ATT_LIST'):
        name = data.data;
        break;
      case ('SHELTER_STUDENT'):
        name = data.data;
        break;
      case ('SHELTER_DATA_GRID'):
        name = data.SHELTER_STUDENT_NAME;
        break;
      case ('SCHOOL_DATA_GRID'):
        name = data.STUDENT_NAME;
        break;
      default:
        name = data.data;
    }
    
    return this._getFormattedStudentName(name);
  }

  private _getModalTitle (studentName: string): string {
    if (studentName) return `History log for ${studentName}`;
    else return 'History Log';
  }

  private _getCaresId (data: any, listType: THistoryLogsComponent): string {
    let caresId: string;
    if (listType === 'SHELTER_ATT_LIST') {
      caresId = JSON.parse(data.meta).caresId;
    } else if (listType === 'SHELTER_STUDENT') {
      const caresIdData = JSON.parse(data.meta);
      caresId = caresIdData.caresId;
    } else if (listType === 'SHELTER_DATA_GRID') {
      caresId = data.CARES_ID;
    } else {
      const caresIdData = JSON.parse(data.meta);
      caresId = caresIdData.caresId;
    }

    return caresId;
  }

  private _getStudentId (data: any, listType: THistoryLogsComponent, schoolId: string): string {
    let studentId: string;
    if (listType === 'SCHOOL_DATA_GRID') {
      studentId = data.OSIS_NUMBER + schoolId;
    }
    else {
      const parsedMeta = JSON.parse(data.meta);
      studentId = parsedMeta.data;
    }
    return studentId;
  }

  private _setFilterOptions (): void {
    const studentId = this.studentId;
    const schoolId = this.schoolId;
    const shelterId = this.shelterId;
    const caresId = this.caresId;
    const partnerType = this.partnerType;

    this.filterOptions$ = this.apiService.getDocLogsFilterOptionsQuery({
      studentId,
      schoolId,
      caresId,
      shelterId,
      partnerType,
    })
      .pipe(
        map((data: any) => this.historyAllHelperService.formatFiltersOptions(data.data.DocLogsFilterOptions)),
      );
  }

  public onMadlibFilter (userFilters: IHistoryLogsFilterOptions): void {
    this.userSelectedFilters = userFilters;
    this.gridApi.setServerSideDatasource({
      getRows: this.getRows.bind(this),
    });
    this.currentPage = 0;
    this.hasNextLogs = true;
    this.hasAppliedFilters = this.historyAllHelperService.checkForAppliedFilters(this.userSelectedFilters);
  }

  onClearFilters (): void {
    this.userSelectedFilters = this.historyAllHelperService.getDefaultUserSelectedFilters();
    this.onMadlibFilter(this.userSelectedFilters);
  }
}
