import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { PageHeaderModule } from 'Src/ng2/shared/components/header/page-header/page-header.module';
import { ListContainerModule } from 'Src/ng2/shared/components/list/list-container/list-container.module';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';
import { BatchEditService } from 'Src/ng2/shared/services/batch-edit-service/batch-edit-service';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvDropdownItemModule } from 'Src/nvps-libraries/design/nv-dropdown-item/nv-dropdown-item.module';
import { NvDropdownModule } from 'Src/nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvFormFieldModule } from 'Src/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvIconButtonModule } from 'Src/nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvNoteModule } from 'Src/nvps-libraries/design/nv-note/nv-note.module';
import { NvPillButtonModule } from 'Src/nvps-libraries/design/nv-pill-button/nv-pill-button.module';
import { NvTextboxModule } from 'Src/nvps-libraries/design/nv-textbox/nv-textbox.module';
import { BaseModalModule } from '../../base-modal.module';
import { SchoolUserModalShellComponent } from './school-user-modal-shell/school-user-modal-shell.component';
import { SchoolUserModalsDataService } from './school-user-modals.data.service';

@NgModule({
  declarations: [SchoolUserModalShellComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    BaseModalModule,
    MatMenuModule,
    NvDropdownModule,
    NvDropdownItemModule,
    NvButtonModule,
    NvPillButtonModule,
    NvFormFieldModule,
    NvIconButtonModule,
    NvIconModule,
    NvTextboxModule,
    NvNoteModule,
    NvTooltipModule,
    PageHeaderModule,
    ListContainerModule,
  ],
  exports: [SchoolUserModalShellComponent],
  providers: [BatchEditService, SchoolUserModalsDataService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SchoolUserManagementModalModule {}
