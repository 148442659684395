import { Injectable } from '@angular/core';
import { IDropdownOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { TValidMockRegentsReports } from '../../constants/pdf-reports.constant';

@Injectable()
export class MockRegentsService {
  constructor () {};

  public getDefaultReport (examName) : TValidMockRegentsReports {
    let defaultReport : TValidMockRegentsReports = 'mockRegents';
    switch (examName) {
      case ('CC Algebra'):
        defaultReport = 'mockRegents_alg1June2023';
        break;
      case ('Living Environment'):
        defaultReport = 'mockRegents_livingEnvJune2023';
        break;
      case ('Global History II'):
        defaultReport = 'mockRegents_globalJune2023';
        break;
      case ('US History (Framework)'):
        defaultReport = 'mockRegents_usFrameworkJune2023';
        break;
      case ('CC ELA'):
        defaultReport = 'mockRegents_elaJune2023';
        break;
      case ('Earth Science'):
        defaultReport = 'mockRegents_earthScienceJune2023';
        break;
      default:
        defaultReport = 'mockRegents';
        break;
    }
    return defaultReport;
  };

  public removeOtherMockRegents (reportOptions: Array<IDropdownOption>, currentExam) : Array<IDropdownOption> {
    return reportOptions.map((option) => {
      if (option.key === 'mockRegents') {
        option.options = option.options.filter((option) => option.key === currentExam);
      }
      return option;
    });
  };
}
