import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { ErrorModalComponent } from './error-modal.component';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';

@NgModule({
  declarations: [ErrorModalComponent],
  imports: [
    MatDialogModule,
    NvButtonModule,
  ],
  exports: [ErrorModalComponent],
})
export class ErrorModalModule {}
