import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { TASK_NOTES_ACTIVITY_TOGGLE_INDEX } from '../../tasks-notes-activity-table/tasks-notes-activity-table.component';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { THistoryLogsComponent } from 'Src/ng2/shared/modals/history-all/history-all-modal.component';

@Component({
  selector: 'status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  styleUrls: ['./status-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class StatusCellRenderer {
  protected params: ICellRendererParams;
  public displayValue: string;
  public cellClass: string;

  constructor (
    private modalsService: ModalsService,
  ) {}

  agInit (params: any): void {
    this.params = params;
    if (this.params) {
      this._valueFormatter(params.value);
    }
  }

  public refresh (params: ICellRendererParams): boolean {
    return false;
  }

  public openModal (): void {
    // DATA-GRID data
    const { contextPartnerType, school, currentUser } = this.params.context.parentComponent;
    const listType: THistoryLogsComponent = 'SCHOOL_DATA_GRID';
    // Row Data for the student: OSIS_NUMBER, STUDENT_NAME
    const studentData = this.params.data;
    const tasksNotesActivityModalData = {
      partnerType: contextPartnerType,
      listType,
      currentSchool: school,
      studentData,
      currentUser,
      segmentedControlIndex: TASK_NOTES_ACTIVITY_TOGGLE_INDEX.TASKS,
    };

    this.modalsService
      .openTasksNotesActivityModal(tasksNotesActivityModalData)
      .afterClosed();
  }

  private _valueFormatter (value: number): void {
    if (value === 0) {
      this.displayValue = '0';
      this.cellClass = 'resolved';
    } else if (value > 99) {
      this.displayValue = '99+';
      this.cellClass = 'unresolved';
    } else {
      this.displayValue = (value)?.toString();
      this.cellClass = 'unresolved';
    }
  }
}
