<div class="filter-container">
  <ng-container class="filter-row" *ngFor="let data of filterData;">
    <span class="header-name"> {{ data.headerName }} </span>
    <ng-template [ngIf]="data.type === 'inRange'" [ngIfElse]="notInRange">
      <span> between {{ data.filter }} and {{ data.filterTo }} </span>
    </ng-template>
    <ng-template #notInRange>
      <span> {{ data.type }} {{ data.filter }} {{ data.filterTo }} </span>
    </ng-template>
  </ng-container>
</div>
<div class="action-button">
  <nv-button type="tertiary" size="small" (clickButton)="onClearAll()">Clear all</nv-button>
</div>
