<div class="error-dialog">
  <div class="dialog-title">
    <h1>{{ title }}</h1>
  </div>

  <div class="dialog-body">
    <mat-dialog-content>
      <p>{{ message }}</p>
    </mat-dialog-content>
  </div>

  <mat-dialog-actions class="dialog-actions">
    <nv-button (click)="onConfirm()">{{ confirmText }}</nv-button>
  </mat-dialog-actions>
</div>
