import { Toggles, TToggles } from 'Src/ng2/shared/constants/toggles.constant';
import { TValidSchoolTypeCats } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { PORTAL_TYPES, TPortalLocation } from 'Src/ng2/shared/typings/interfaces/portal.interface';
import { District, TDistricts } from 'Src/ng2/shared/typings/interfaces/district.interface';
import { TFeatureSets, TNetworkEntity } from '../../shared/typings/interfaces/network-sidenav.interface';
import { PartnerTypes, TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

/**
 * The INavItem contains more information than what the nv-sidebar-list component needs
 * During runtime, these configs will be formatted to ISidebarItem objects
 *
 * To enable a dropdown for a side nav item, the children field must exist.
 *
 * Toggles must use the Toggle key present in toggles.constant.ts. At the moment,
 * the behavior is: if the toggle in the toggle array is on, then the item will not display.
 * The code will loop through all toggles in the array and display accordingly.
 *
 * @prop {boolean} isActive -
 * The isActive key can be used to hide a nav item on the UI. If set to anything but null
 * If the nav item is allowed to be shown on the Portal, then this side-nav component determines
 * if the user should be allowed to see the item. (isRestricted function)
 *
 * @property {string} url - link to navigate to.
 * Top level items with children do not have urls and external links.
 * Make sure the url given here can be found in USER_ROLE_PERMISSIONS_FOR_GUARDS
 * as it is being checked to ensure user authorization.
 * the string here will be parsed relative to /school/
 * @exmaple url: 'data-grid' will navigate to '/school/data-grid'
 *
 * @property {boolean} isExternalUrl - if true, then the url and behavior of the url
 * needs to be handled by the component
 */
export interface INavItem {
  key?: string;
  humanName: string;
  order: number;
  isActive: boolean;
  schoolTypes: TValidSchoolTypeCats[];
  districts: TDistricts[];
  entityType?: TNetworkEntity[];
  featureSet?: TFeatureSets[];
  toggleKeys?: TToggles[] | null;
  portalLocations?: TPortalLocation[];
  url?: string | ((contextPartnerType: TValidPartnerTypes, shelterSSGToggleIsOn?: boolean) => string);
  queryParams?: any | ((contextPartnerType: TValidPartnerTypes, queryParamsToMerge?, shelterSSGToggleIsOn?: boolean) => any);
  queryParamsToHash?: string[]; // name the params to be hashed, otherwise no hash will be created for any params
  isExternalUrl?: boolean;
  children?: INavItem[];
  hasBetaFlag?: boolean;
  leftDefaultIcon?: string;
  leftSelectedIcon?: string;
  rightDefaultIcon?: string;
  rightSelectedIcon?: string;
  skipUnwrap?: boolean;
}

export const ContentAreaNavItems: INavItem[] = [
  {
    key: 'ACADEMIC',
    humanName: 'Academics',
    order: 0,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.UNIONDALE, District.SCHENECTADY],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    isExternalUrl: false,
    children: [
      {
        key: 'ACADEMIC_DASHBOARD',
        humanName: 'Dashboard',
        order: 0,
        isActive: true,
        schoolTypes: ['all'],
        districts: [District.NYC, District.LANSING, District.UNIONDALE, District.SCHENECTADY],
        portalLocations: [PORTAL_TYPES.SCHOOL],
        toggleKeys: [Toggles.TOGGLE_CONTENT_AREA_DASHBOARD],
        url: 'academic',
        isExternalUrl: false,
      },
      {
        key: 'ACADEMIC_LIST',
        humanName: 'Student List',
        order: 1,
        isActive: true,
        schoolTypes: ['all'],
        districts: [District.NYC, District.UNIONDALE, District.SCHENECTADY],
        portalLocations: [PORTAL_TYPES.SCHOOL],
        url: 'lists/academic-list',
        isExternalUrl: false,
      },
    ],
  },
  {
    key: 'ATTENDANCE',
    humanName: 'Attendance',
    order: 1,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL, PORTAL_TYPES.SHELTER],
    isExternalUrl: false,
    children: [
      {
        key: 'ATTENDANCE_DASHBOARD',
        humanName: 'Dashboard',
        order: 0,
        isActive: true,
        schoolTypes: ['all'],
        districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
        portalLocations: [PORTAL_TYPES.SCHOOL],
        toggleKeys: [Toggles.TOGGLE_ATTENDANCE_CONTENT_AREA_DASHBOARD],
        url: 'attendance',
        isExternalUrl: false,
      },
      {
        key: 'ATTENDANCE_LIST',
        humanName: 'Student List',
        order: 1,
        isActive: true,
        schoolTypes: ['all'],
        districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
        url: 'lists/attendance-list',
        isExternalUrl: false,
        portalLocations: [PORTAL_TYPES.SCHOOL, PORTAL_TYPES.SHELTER],
        queryParams: (contextPartnerType: TValidPartnerTypes, queryParamsToMerge: any = {}) => {
          const { subLocationId } = queryParamsToMerge;
          return subLocationId ? { subLocationId } : {};
        },
      },
    ],
  },
  {
    key: 'CREDITS',
    humanName: 'Credits',
    order: 2,
    isActive: true,
    schoolTypes: ['hs'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    toggleKeys: [Toggles.TOGGLE_CREDIT_GAPS],
    url: 'credits',
    isExternalUrl: false,
  },
  {
    key: 'GRADUATION',
    humanName: 'Graduation',
    order: 4,
    isActive: true,
    schoolTypes: ['hs'],
    districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    toggleKeys: [Toggles.TOGGLE_GRADUATION],
    url: 'graduation',
    isExternalUrl: false,
  },
  {
    key: 'REGENTS',
    humanName: 'Regents',
    order: 6,
    isActive: true,
    schoolTypes: ['hs', 'ms', 'transfer'],
    districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    isExternalUrl: false,
    skipUnwrap: true,
    children: [
      {
        key: 'REGENTS_DASHBOARD',
        humanName: 'Dashboard',
        order: 0,
        isActive: true,
        schoolTypes: ['hs'],
        districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE],
        portalLocations: [PORTAL_TYPES.SCHOOL],
        toggleKeys: [Toggles.TOGGLE_SDC_REGENTS],
        url: 'regents',
        isExternalUrl: false,
      },
      {
        key: 'REGENTS_RESULTS',
        humanName: 'Regents Results',
        order: 1,
        isActive: true,
        schoolTypes: ['hs', 'ms'],
        districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE],
        portalLocations: [PORTAL_TYPES.SCHOOL],
        url: 'regents/regents-results',
        isExternalUrl: false,
      },
      {
        key: 'MOCK_REGENTS',
        humanName: 'Mock Regents',
        order: 2,
        isActive: true,
        url: 'regents/mock-regents',
        districts: [District.NYC],
        schoolTypes: ['hs', 'ms', 'transfer'],
        portalLocations: [PORTAL_TYPES.SCHOOL],
        isExternalUrl: false,
      },
    ],
  },
  {
    key: 'POSTSECONDARY',
    humanName: 'Postsecondary',
    isActive: true,
    order: 5,
    schoolTypes: ['hs'],
    districts: [District.NYC],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    isExternalUrl: false,
    children: [
      {
        key: 'POSTSECONDARY',
        humanName: 'Dashboard',
        order: 0,
        isActive: true,
        schoolTypes: ['hs'],
        districts: [District.NYC],
        portalLocations: [PORTAL_TYPES.SCHOOL],
        toggleKeys: [Toggles.TOGGLE_CONTENT_AREA_DASHBOARD],
        url: 'postsecondary',
        isExternalUrl: false,
      },
      {
        key: 'COLLEGE_NOW',
        humanName: 'CUNY College Now',
        order: 1,
        isActive: true,
        schoolTypes: ['hs'],
        districts: [District.NYC],
        portalLocations: [PORTAL_TYPES.SCHOOL],
        url: 'postsecondary/college-now',
        isExternalUrl: false,
      },
      {
        key: 'SUPPLEMENTAL_ADVISING',
        humanName: 'Supplemental Advising',
        order: 2,
        isActive: true,
        schoolTypes: ['hs'],
        districts: [District.NYC],
        portalLocations: [PORTAL_TYPES.SCHOOL],
        url: 'doe-postsec-advising',
        isExternalUrl: false,
      },
    ],
  },
  {
    key: 'SCREENERS_LIST',
    humanName: 'Screeners',
    order: 7,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    url: 'lists/screeners-list',
    isExternalUrl: false,
  },
  {
    key: 'STUDENTS',
    humanName: 'Students',
    order: 8,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    toggleKeys: [Toggles.TOGGLE_CONTENT_AREA_DASHBOARD],
    url: 'students',
    isExternalUrl: false,
  },
];

export const MoreToolsNavItems: INavItem[] = [
  {
    key: 'HOME_DASHBOARD',
    humanName: 'Home',
    order: 0,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    url: 'lists/tiles',
    leftDefaultIcon: 'home-default',
    leftSelectedIcon: 'home-selected',
    isExternalUrl: false,
  },
  {
    key: 'DEFAULT_SDC_GRID',
    humanName: 'Data Grid',
    order: 1,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL, PORTAL_TYPES.SHELTER],
    url: 'data-grid',
    leftDefaultIcon: 'data-grid-default',
    leftSelectedIcon: 'data-grid-selected',
    isExternalUrl: false,
  },
  {
    key: 'MY_TASKS',
    humanName: 'My Tasks',
    order: 2,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    url: 'my-tasks',
    leftDefaultIcon: 'clipboard-list-large-grey',
    leftSelectedIcon: 'clipboard-list-large-blue',
    isExternalUrl: false,
    hasBetaFlag: true,
  },
  {
    key: 'STUDENT_PROFILE',
    humanName: 'Student Profile',
    order: 3,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL, PORTAL_TYPES.SHELTER],
    url: 'student',
    queryParams: (contextPartnerType: TValidPartnerTypes, queryParamsToMerge: any = {}) => {
      // temporary switch statement to handle different profile page queryParams between school and shelter
      // To Nancy - remove the switch statement and just use the school pattern only
      const { subLocationId } = queryParamsToMerge;
      const _queryParamsToMerge = subLocationId ? { subLocationId } : {};
      switch (contextPartnerType) {
        case PartnerTypes.SHELTER:
          return {
            ..._queryParamsToMerge,
            filter: null,
            sort: [{ colId: 'SHELTER_STUDENT_NAME', sort: 'asc' }],
            state: null,
          };
        case PartnerTypes.SCHOOL:
        default:
          return {
            ..._queryParamsToMerge,
            focus: null,
            filter: null,
            grouping: null,
            sort: [{ colId: 'STUDENT_NAME', sort: 'asc' }],
            search: null,
          };
      }
    },
    queryParamsToHash: ['sort', 'filter', 'students'],
    leftDefaultIcon: 'student-profile-default',
    leftSelectedIcon: 'student-profile-selected',
    isExternalUrl: false,
  },
  {
    key: 'MANAGE_FAMILIES',
    humanName: 'ECFIK',
    order: 4,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    url: 'manage-families',
    leftDefaultIcon: 'people-default',
    leftSelectedIcon: 'people-large-blue',
    isExternalUrl: false,
    children: [
      {
        key: 'MY_FAMILIES',
        humanName: 'My Families',
        order: 0,
        isActive: true,
        schoolTypes: ['all'],
        districts: [District.NYC],
        portalLocations: [PORTAL_TYPES.SCHOOL],
        url: 'manage-families',
        isExternalUrl: false,
      },
      {
        key: 'MANAGE_PROGRAM',
        humanName: 'Manage Program',
        order: 1,
        isActive: true,
        schoolTypes: ['all'],
        districts: [District.NYC],
        portalLocations: [PORTAL_TYPES.SCHOOL],
        url: 'manage-program',
        isExternalUrl: false,
      },
    ],
  },
  {
    key: 'SUPPORT_ATTENDANCE_LIST',
    humanName: 'Supports',
    order: 5,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    url: 'lists/supports',
    leftDefaultIcon: 'supports-default',
    leftSelectedIcon: 'supports-selected',
    isExternalUrl: false,
  },
  {
    key: 'SUMMER_SCHOOL_LIST',
    humanName: 'Summer School',
    order: 6,
    isActive: true,
    districts: [District.NYC],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    schoolTypes: ['all'],
    hasBetaFlag: false,
    url: 'lists/summer-school',
    leftDefaultIcon: 'summer-school-large-gray',
    leftSelectedIcon: 'summer-school-large-blue',
    isExternalUrl: false,
  },
];

export const smallNavItems: INavItem[] = [
  {
    key: 'OTHER_TOOLS',
    humanName: 'Other Tools',
    order: 0,
    isActive: true,
    isExternalUrl: false,
    url: 'other-tools',
    districts: [District.NYC, District.LANSING],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    schoolTypes: ['all'],
  },
  {
    key: 'SETTINGS',
    humanName: 'Settings',
    order: 1,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    url: '[DYNAMIC_SETTINGS_ROUTE]',
    isExternalUrl: false,
  },
  {
    key: 'USER_MANAGEMENT',
    humanName: 'User Management',
    order: 1,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    url: 'settings/user-management',
    isExternalUrl: false,
  },
  {
    key: 'DATA_SOURCES',
    humanName: 'Data Uploads',
    order: 2,
    isActive: true,
    schoolTypes: ['hs'],
    districts: [District.NYC],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    url: 'data-uploads/college-list',
    isExternalUrl: false,
  },
  {
    key: 'FP_DATA_ENTRY',
    humanName: 'F&P Data Entry',
    order: 3,
    isActive: true,
    schoolTypes: ['ems'],
    districts: [District.NYC],
    toggleKeys: [Toggles.TOGGLE_NYCT_MVP],
    portalLocations: [PORTAL_TYPES.SCHOOL],
    url: 'data-entry/assessments',
    isExternalUrl: false,
  },
  {
    key: 'GET_HELP',
    humanName: 'Get Help',
    order: 4,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL, PORTAL_TYPES.SHELTER],
    isExternalUrl: true,
  },
  {
    key: 'PRIVACY_AND_TERMS',
    humanName: 'Privacy & Terms',
    order: 5,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocations: [PORTAL_TYPES.SCHOOL, PORTAL_TYPES.SHELTER],
    isExternalUrl: true,
  },
];

export function getSmallSideNavConfig (settingsRoute: string): typeof smallNavItems {
  return JSON.parse(JSON.stringify(smallNavItems).replace('[DYNAMIC_SETTINGS_ROUTE]', settingsRoute));
}
