import { ModalsService } from './../../../shared/modals/modals.service';
import { identity } from 'lodash';
import { getCurrentUser } from 'Src/ng2/store/selectors/current-user-selectors';
import { LoadCurrentUser } from './../../../store/actions/current-user-actions';
import { Auth } from 'Src/ng2/shared/auth/auth.service';
import { getCurrentUserLoadedStatus } from './../../../store/selectors/current-user-selectors';
import { ImUser } from './../../../shared/services/im-models/im-user';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { LoadToggles } from './../../../store/actions/toggle-actions';
import { tap, filter, switchMap, take } from 'rxjs/operators';
import { getToggleLoadedStatus, getToggleEntities } from './../../../store/selectors/toggles-selectors';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
import { PartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

@Injectable()
export class HomeGuard implements CanActivate {
  constructor (
    private imUser: ImUser,
    private router: Router,
    private store: Store<any>,
    private auth: Auth,
    private modalsService: ModalsService,
    private urlPathService: UrlPathService,
  ) {}

  canActivate (snapshot: ActivatedRouteSnapshot): Observable<UrlTree> {
    const { noAccessUrl } = snapshot.queryParams;
    if (noAccessUrl) this.showRedirectModal(noAccessUrl);

    const currentUser$ = this.getCurrentUserFromStoreOrAPI();
    const toggles$ = this.getTogglesFromStoreOrAPI();

    return forkJoin([currentUser$, toggles$]).pipe(
      switchMap(([currentUser, toggles]) => of(this.redirect(currentUser, toggles))),
    );
  }

  private redirect (currentUser: IUser, toggles): UrlTree {
    const isSuperAdmin = this.imUser.isSuperAdmin(currentUser) && this.imUser.isActive(currentUser);
    const isHybridClusterUser = this.imUser.isHybridClusterUser(currentUser) && this.imUser.isActive(currentUser, PartnerTypes.HYBRID);
    const isSingleSchoolUser = this.imUser.isSchoolUser(currentUser) && this.imUser.isActive(currentUser, PartnerTypes.SCHOOL);
    const isSingleMultiSchoolUser = this.imUser.isMultiSchoolUser(currentUser) && this.imUser.isSingleMultiSchoolUser(currentUser) && this.imUser.isActive(currentUser, PartnerTypes.SCHOOL);
    const isMultiSchoolUser = this.imUser.isMultiSchoolUser(currentUser) && !this.imUser.isSingleMultiSchoolUser(currentUser) && this.imUser.isActive(currentUser, PartnerTypes.SCHOOL);
    const isSchoolClusterUser = this.imUser.isClusterUser(currentUser, PartnerTypes.SCHOOL) && this.imUser.isActive(currentUser, PartnerTypes.SCHOOL);
    const isShelterClusterUser = this.imUser.isClusterUser(currentUser, PartnerTypes.SHELTER) && this.imUser.isActive(currentUser, PartnerTypes.SHELTER);
    const isSingleShelterUser = this.imUser.isShelterUser(currentUser) && this.imUser.isActive(currentUser, PartnerTypes.SHELTER);
    const isExpandEdUser = this.imUser.isExpandEd(currentUser);
    const isCUNYUser = this.imUser.isCUNY(currentUser);
    if (isExpandEdUser) {
      return this.goToExpandEdDataGrid();
    } else if (isCUNYUser) {
      return this.goToCUNYDataGrid();
    } else if (isSuperAdmin) {
      return this.navigateSuperAdminUser();
    } else if (isHybridClusterUser) {
      return this.navigateHybridClusterUser();
    } else if (isSingleSchoolUser || isSingleMultiSchoolUser) {
      const schoolId = currentUser.nvRole.schoolId || currentUser._role_clusterSchoolIds[0];
      return this.goToSchoolPortal(schoolId);
    } else if (isMultiSchoolUser) {
      return this.goToSchoolPicker();
    } else if (isSchoolClusterUser) {
      return this.navigateSchoolClusterUser(toggles, currentUser);
    } else if (isSingleShelterUser) {
      const shelterId = currentUser.nvRoleShelter.shelterId;
      return this.goToShelterPortal(shelterId);
    } else if (isShelterClusterUser) {
      return this.goToShelterPicker();
    }
  }

  private getTogglesFromStoreOrAPI (): Observable<any> {
    return this.store.pipe(
      select(getToggleLoadedStatus),
      tap(loaded => {
        if (!loaded) this.store.dispatch(new LoadToggles({}));
      }),
      filter(identity),
      switchMap(() => this.store.pipe(select(getToggleEntities))),
      take(1),
    );
  }

  private getCurrentUserFromStoreOrAPI (): Observable<IUser> {
    return this.store.pipe(
      select(getCurrentUserLoadedStatus),
      tap(async loaded => {
        if (!loaded) {
          const email = await this.auth.getCurrentUserEmail();
          this.store.dispatch(new LoadCurrentUser({ email }));
        }
      }),
      filter(loaded => loaded),
      switchMap(() => this.store.pipe(select(getCurrentUser))),
      take(1),
    );
  }

  private navigateSuperAdminUser (): UrlTree {
    return this.goToExplore();
  }

  private navigateHybridClusterUser (): UrlTree {
    return this.goToExplore();
  }

  private goToSchoolPortal (schoolId): UrlTree {
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/lists/tiles`);
    return this.router.createUrlTree([url]);
  }

  private navigateSchoolClusterUser (toggles, currentUser: IUser): UrlTree {
    const accessNetworkView = toggles.TOGGLE_NETWORK_DASHBOARD;
    const isNetworkOnly = this.imUser.isNetworkOnly(currentUser, PartnerTypes.SCHOOL_NETWORK);
    if (accessNetworkView && isNetworkOnly) {
      const url = this.urlPathService.computeDistrictUrlPath(`/network/school/${currentUser.nvRole.clusterId}`);
      return this.router.createUrlTree([url]);
    } else return this.goToSchoolPicker();
  }

  private goToShelterPortal (shelterId: string): UrlTree {
    const url = this.urlPathService.computeDistrictUrlPath(`/shelter/${shelterId}/lists/attendance-list`);
    return this.router.createUrlTree([url]);
  }

  private showRedirectModal (noAccessUrl: string): void {
    const title = 'Unauthorized Access';
    const message = `You cannot access "${
      noAccessUrl
    }" because your role does not allow it. Please contact your administrator to request a role change.`;
    this.modalsService.openErrorModal({ title, message });
  }

  private goToSchoolPicker (): UrlTree {
    const url = this.urlPathService.computeDistrictUrlPath('/school-picker');
    return this.router.createUrlTree([url]);
  }

  private goToShelterPicker (): UrlTree {
    const url = this.urlPathService.computeDistrictUrlPath('shelter-picker');
    return this.router.createUrlTree([url]);
  }

  private goToExplore (): UrlTree {
    const url = this.urlPathService.computeDistrictUrlPath('/explore');
    return this.router.createUrlTree([url]);
  }

  private goToExpandEdDataGrid (): UrlTree {
    const url = this.urlPathService.computeDistrictUrlPath('/expand-ed-grid');
    return this.router.createUrlTree([url]);
  }

  private goToCUNYDataGrid (): UrlTree {
    const url = this.urlPathService.computeDistrictUrlPath('/cuny-grid');
    return this.router.createUrlTree([url]);
  }
}
