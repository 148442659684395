import { PORTAL_TYPES } from 'Src/ng2/shared/typings/interfaces/portal.interface';
import { TMixpanelEvent } from '../mixpanel.service';
import { TEventType, TEventName } from 'Src/ng2/shared/typings/interfaces/mixpanel.interface';

// type TPortal = 'School' | 'Network';

export interface IServerSideGridViewEventMetadata {
    eventName: TEventName;
    eventType: TEventType;
  };

export interface IGridViewMetadata extends IServerSideGridViewEventMetadata {
    gridView: {
        gridViewId: string;
        gridViewName: string;
        gridViewType: string;
        columns: {
            added: any[],
            deleted: any[],
        };
    };
    auditInfo: {
        userRole: string;
        gridOrSchoolType: string;
    };
    portal: PORTAL_TYPES;
  };

export const getServerSideGridViewEvent =
  <TMetadata extends IGridViewMetadata>(metaData: TMetadata): TMixpanelEvent<TMetadata> => ({
    event: metaData.eventName,
    metaData,
  });
