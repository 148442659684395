import { ContactInfoService } from './../../services/contact-info/contact-info.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { debounceTime, map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export interface IGuardian {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  isPrimary: boolean;
  source: string;
  guardianId: string;
}

export interface IEcfikModalData {
  guardians: IGuardian[];
  state: 'EDIT' | 'ADD';
}

@Component({
  selector: 'edit-ecfik-guardian-modal',
  templateUrl: 'edit-ecfik-guardian-modal.component.html',
  styleUrls: ['edit-ecfik-guardian-modal.component.scss'],
})

// Can support both add new and edit existing guardian cases
export class EditECFIKGuardianModalComponent extends BaseModalComponent implements OnInit {
  public guardiansFormList: FormArray;
  public title: string;
  public modalState: 'ADD' | 'EDIT';
  public restrictedContactNameLookup: boolean[] = [];
  public restrictedContactMobileLookup: boolean[] = [];

  constructor (
    dialogRef: MatDialogRef<EditECFIKGuardianModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEcfikModalData,
    private fb: FormBuilder,
    private utils: UtilitiesService,
    private contactInfoService: ContactInfoService,
  ) {
    super(dialogRef);
  }

  ngOnInit () {
    this.guardiansFormList = this.fb.array([]);
    this.modalState = this.data.state;
    const modalStates = {
      EDIT: { title: 'Edit guardian information', initForm: this.initEditGuardiansForm.bind(this) },
      ADD: { title: 'Add guardian information', initForm: this.initAddGuardianForm.bind(this) },
    };

    const modalState = modalStates[this.data.state];
    this.title = modalState.title;
    modalState.initForm();
  }

  private initAddGuardianForm (): void {
    const formIndex = 0;
    const [guardian] = this.data.guardians;
    const fg = this.getGuardianFormGroup(guardian);
    this.guardiansFormList.push(fg);
    this.restrictedContactNameLookup[formIndex] = false;
    this.restrictedContactMobileLookup[formIndex] = false;
  }

  private initEditGuardiansForm (): void {
    this.data.guardians.forEach(
      (guardian, index) => {
        const fg = this.getGuardianFormGroup(guardian);
        this.guardiansFormList.push(fg);
        this.restrictedContactNameLookup[index] = false;
        this.restrictedContactMobileLookup[index] = false;
      },
    );
  }

  private getGuardianFormGroup ({ firstName, lastName, email, mobile, isPrimary, source, guardianId }: IGuardian = {} as IGuardian): FormGroup {
    return new FormGroup({
      firstName: new FormControl(firstName || '', [Validators.required]),
      lastName: new FormControl(lastName || '', [Validators.required]),
      email: new FormControl(email || '', []),
      mobile: new FormControl(mobile || '', []),
      isPrimary: new FormControl(isPrimary),
      source: new FormControl(source || 'userEntered'),
      guardianId: new FormControl(guardianId || this.utils.createV4Uuid()),
    }, [], this.validateGuardianForm.bind(this));
  }

  private validateGuardianForm (fg: FormGroup): Observable<any> {
    if (!fg.value.mobile || fg.pristine) return of(null);

    const fgIndex = this.guardiansFormList.controls.indexOf(fg);
    const payload = {
      firstName: fg.value.firstName,
      lastName: fg.value.lastName,
      phoneNumber: fg.value.mobile,
    };

    return fg.valueChanges.pipe(
      take(1),
      debounceTime(150), // debounce api requests if user is still typing during validation
      switchMap(() => this.contactInfoService.findIsContactRestricted(payload)),
      tap(res => {
        this.restrictedContactNameLookup[fgIndex] = res.fullName;
        this.restrictedContactMobileLookup[fgIndex] = res.phoneNumber;
      }),
      map(res => (!res.phoneNumber && !res.fullName) ? null : res),
    );
  }

  public onClearMobile (index): void {
    const targetFg = this.guardiansFormList.at(index);
    (targetFg as FormGroup).controls.mobile.setValue('');
    this.restrictedContactMobileLookup[index] = false;
  }

  public onClearName (index: number, field: 'firstName' | 'lastName'): void {
    const targetFg = this.guardiansFormList.at(index);
    (targetFg as FormGroup).controls[field].setValue('');
    this.restrictedContactNameLookup[index] = false;
    this.restrictedContactMobileLookup[index] = false;
  }

  public deleteGuardian (index: number): void {
    this.guardiansFormList.removeAt(index);
    const restrictedContactNameCopy = [...this.restrictedContactNameLookup];
    const restrictedContactMobileCopy = [...this.restrictedContactMobileLookup];
    this.restrictedContactNameLookup = this.getRefreshedLookup(restrictedContactNameCopy, index);
    this.restrictedContactMobileLookup = this.getRefreshedLookup(restrictedContactMobileCopy, index);
  }

  private getRefreshedLookup (lookup: boolean[], index: number): boolean[] {
    lookup.splice(index, 1);
    return lookup;
  }

  public onCancel () {
    super.close();
  }

  public onSave () {
    super.close(this.guardiansFormList.value);
  }
}
