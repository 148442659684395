import { Component, Input, ViewEncapsulation } from '@angular/core';

/**
 *
 * A simple component that renders the New Visions logo as an `svg`.
 *
 * Use this wherever you need to use the logo.
 *
 * Resize this as you need by modifying the `height` property.
 */
@Component({
  selector: 'nv-logo',
  templateUrl: './nv-logo.component.html',
  styleUrls: ['./nv-logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvLogoComponent {
  @Input() type: 'symbol' | 'symbol-invert' | 'wordmark' | 'portal' | 'family-portal-logo-symbol';
  @Input() pointerOnHover: boolean = false;
}
