import { GridService } from 'Src/ng2/school/grid/services/grid.service';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { ECFIKFormOptionsMap } from './../check-in-logs/add-check-in-log/add-check-in-log.component';
import { DateHelpers } from '../../../../../projects/shared/services/date-helpers/date-helpers.service';
import { EventFormatterService } from './../../services/mixpanel/event-formatter.service';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { cloneDeep, pick } from 'lodash';

import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { IUser } from '../../../shared/typings/interfaces/user.interface';
import { ImNote } from 'Src/ng2/shared/services/im-models/im-note.service';
import { ImSchool } from './../../services/im-models/im-school';
import { ISchool } from '../../typings/interfaces/school.interface';
import { IECFIKNoteFields, ISchoolNote, IShelterNote, IValidNoteModes, VALID_NOTE_MODES } from '../../typings/interfaces/note.interface';
import { IStudent } from '../../typings/interfaces/student.interface';
import { IPickerOption } from 'projects/shared/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { NOTES_CATEGORIES_OPTIONS, NOTE_TEMPLATE, NOTE_SHELTER_TEMPLATE } from './notes-modal.config';
import { PartnerTypes, TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import { BackgroundJobNotificationService } from '../../services/background-job-notification-service/background-job-notification-service';
import { ECFIKFormOptionsFields } from '../check-in-logs/check-in-log-modal.component';
import { THistoryLogsComponent } from '../history-all/history-all-modal.component';
import { TFormatNoteEventArgs } from '../../services/mixpanel/event-interfaces/note-action';
import { IFormatBatchActionEventArgs } from '../../services/mixpanel/event-interfaces/batch-action';
import { TBatchActionsOrigin } from '../../components/nv-actions/nv-actions.interface';

export interface INotesData extends IBaseModalData {
  partnerType: TValidPartnerTypes;
  school?: ISchool;
  shelterId?: string;
  student?: any; // update typing to ISingleStudent
  studentName?: string;
  isStudentECFIKEligible?: boolean;
  currentUser: IUser;
  note?: any;
  mode: IValidNoteModes;
  origin?: TBatchActionsOrigin;
  studentId?: string; // School Portal
  caresId?: string; // Shelter Portal
  studentIds?: string[]; // School Portal
  caresIds?: string[]; // Shelter Portal
  docId?: any;
  listType?: THistoryLogsComponent;
  currentExam?: string;
}

@Component({
  selector: 'notes-shell-modal',
  templateUrl: './notes-modal-shell.component.html',
  styleUrls: ['./notes-modal-shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotesModalShellComponent extends BaseModalComponent implements OnInit {
  noteData: ISchoolNote | IShelterNote;
  partnerType: TValidPartnerTypes;
  schoolId: string;
  studentId: string;
  currentUser: IUser;
  mode: string;
  student: IStudent;
  caresIds: string[]; // Bulk create shelter notes
  editIsRestricted: boolean;
  isHighSchool: boolean;
  listType: THistoryLogsComponent;
  public title: 'Add note' | 'Edit note';
  public primaryBtnName: 'Add' | 'Save';
  public noteForm: FormGroup;
  public notesCategoriesOptions: IPickerOption[];
  noteToEdit: any;
  public itemCount: number;
  public itemType: string = 'student';
  public isProfileMode: boolean;
  public students: string[];
  public studentName: any;
  public caresId: any;
  public docId: any;
  public isFormDirty: boolean = false;

  // SHELTER (Available only on shelter portal)
  public shelterId: string;
  public shelterStudent: any;

  // ECFIK
  public isAssignedCaringAdult: boolean = false;
  public isProgramPointForSchool: boolean = false;
  public ecfikFormOptionsMap: ECFIKFormOptionsMap;
  readonly DATE_FORMAT: string = 'YYYY-MM-DD';
  public ecfikFormFieldsSet: boolean = false;
  public today: string;

  private origin: TBatchActionsOrigin;

  constructor (
    dialogRef: MatDialogRef<NotesModalShellComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INotesData,
    private apiService: ApiService,
    private imNoteService: ImNote,
    private imSchoolService: ImSchool,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
    private eventFormatterService: EventFormatterService,
    private dateHelpers: DateHelpers,
    private imUser: ImUser,
    private gridService: GridService,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    // clone data to avoid mutating the passed in data
    const { partnerType, school, student, currentUser, mode, shelterId, caresIds, studentIds, studentName, isStudentECFIKEligible, studentId, caresId, docId, origin, note, listType } = cloneDeep(this.data);
    this.partnerType = partnerType;
    this.currentUser = currentUser;
    this.mode = mode;
    this.docId = docId || note?._id;
    this.origin = origin;
    this.today = this.dateHelpers.getFormattedNow('YYYY-MM-DD');
    this.listType = listType;

    if (this.partnerType === PartnerTypes.SCHOOL) {
      this.schoolId = school?._id;
      this.isHighSchool = this.imSchoolService.isHighSchool(school);
      // ES and MS have the same categories
      this.notesCategoriesOptions = this.isHighSchool ? NOTES_CATEGORIES_OPTIONS.HS : NOTES_CATEGORIES_OPTIONS.ES;
      if (this.mode === VALID_NOTE_MODES.EDIT_MODAL || this.mode === VALID_NOTE_MODES.EDIT_PROFILE) {
        this.student = student;
        this.studentId = studentId || this.student._id;
        this.studentName = studentName;
        this.isProfileMode = true;
      }
      if (this.mode === VALID_NOTE_MODES.CREATE_BULK_SCHOOL) {
        this.students = studentIds;
        this.itemCount = this.students.length;
        this.isProfileMode = false;
      }
      else {
        this.student = student;
        this.studentId = studentId;
        this.studentName = studentName;
        this.isProfileMode = true;
      }
    }

    // SHELTER
    if (this.partnerType === PartnerTypes.SHELTER) {
      this.shelterId = shelterId;
      this.notesCategoriesOptions = NOTES_CATEGORIES_OPTIONS.SHELTER;
      if (this.mode === VALID_NOTE_MODES.EDIT_MODAL || this.mode === VALID_NOTE_MODES.EDIT_PROFILE) {
        this.shelterStudent = student;
        this.caresId = caresId;
        this.studentName = studentName;
        this.isProfileMode = true;
      }
      else if (this.mode === VALID_NOTE_MODES.CREATE_BULK_SHELTER) {
        this.caresIds = caresIds;
        this.itemCount = this.caresIds.length;
        this.isProfileMode = false;
      } else {
        this.shelterStudent = student;
        this.caresId = caresId;
        this.studentName = studentName;
        this.isProfileMode = true;
      }
    }

    // Add or remove ECFIK option based on User's ecfik permissions
    this.isAssignedCaringAdult = this.imUser.isInEcfikCaseload([studentId || this.student?._id], currentUser._ecfikPermissions?.studentCaseload) || this.imUser.isInEcfikCaseload(studentIds, currentUser._ecfikPermissions?.studentCaseload);
    this.isProgramPointForSchool = this.imUser.isProgramPoint(currentUser, school?._id);
    this.imUser.areAllStudentsEcfikEligible(this.gridService, studentIds, this.schoolId, (this.isAssignedCaringAdult || this.isProgramPointForSchool))
      .subscribe((allEcfikStudentsAreEligible: boolean) => {
        const studentsAreEcfikEligible = student?.studentDetails?.isECFIKEligible || isStudentECFIKEligible || allEcfikStudentsAreEligible;
        this.notesCategoriesOptions = this.imUser.shapeOptionsByPermissions(this.notesCategoriesOptions, this.isAssignedCaringAdult || (this.isProgramPointForSchool && studentsAreEcfikEligible));
        if (this.isAssignedCaringAdult || (this.isProgramPointForSchool && studentsAreEcfikEligible)) {
          this.ecfikFormOptionsMap = this.imUser.getEcfikFormOptionsmap();
        }
      });

    switch (mode) {
      case VALID_NOTE_MODES.CREATE:
      case VALID_NOTE_MODES.CREATE_BULK_SCHOOL:
        this.title = 'Add note';
        this.primaryBtnName = 'Add';
        this.noteData = this._createInitialSchoolNoteData();
        this.editIsRestricted = !this.imNoteService.canCreate(this.currentUser, this.noteData);
        this.initFormControlsToCreateNote();
        break;
      case VALID_NOTE_MODES.EDIT_PROFILE:
        this.title = 'Edit note';
        this.primaryBtnName = 'Save';
        this.editIsRestricted = !this.imNoteService.canEdit(this.currentUser, this.data.note);
        this.initFormControlsToEditNote(this.docId);
        break;
      case VALID_NOTE_MODES.EDIT_MODAL:
        this.title = 'Edit note';
        this.primaryBtnName = 'Save';
        this.editIsRestricted = false;
        this.initFormControlsToEditNote(this.docId);
        break;
      case VALID_NOTE_MODES.CREATE_SHELTER:
      case VALID_NOTE_MODES.CREATE_BULK_SHELTER:
        this.title = 'Add note';
        this.primaryBtnName = 'Add';
        this.noteData = this._createInitialShelterNoteData();
        this.editIsRestricted = false; // All shelter user can create notes
        this.initFormControlsToCreateNote();
        break;
      default:
        break;
    }
  }

  ngAfterViewChecked (): void {
    this.cdr.detectChanges();
  }

  public initFormControlsToCreateNote (): void {
    const initialForm = {
      note: '',
      selectedCategories: [],
    };

    this.noteForm = this.formBuilder.group({
      note: [initialForm.note, Validators.required],
      selectedCategories: [initialForm.selectedCategories, Validators.required],
    });
  }

  private _createInitialSchoolNoteData (): ISchoolNote {
    let studentName = null; // undefined for bulk action
    let studentId = null; // undefined for bulk action
    if (this.student) {
      studentName = this.student.studentDetails.name.lastFirst;
      studentId = this.student._id;
    };
    if (!this.student && this.studentName) {
      studentName = this.studentName;
    };
    if (!this.student && this.studentId) {
      studentId = this.studentId;
    };
    const note = {
      ...NOTE_TEMPLATE,
      attachments: {
        student: { studentId, lastFirst: studentName },
      },
      schoolId: this.schoolId,
      contextPartnerType: this.partnerType,
    };

    return note;
  }

  private _createInitialShelterNoteData (): IShelterNote {
    let studentName = null; // undefined for bulk action
    let caresId = null; // undefined for bulk action
    if (this.shelterStudent) {
      studentName = this.shelterStudent?.SHELTER_STUDENT_NAME_FIRST_LAST;
      caresId = this.shelterStudent?.CARES_ID;
    }
    if (!this.shelterStudent && this.studentName) {
      studentName = this.studentName;
    }
    if (!this.shelterStudent && this.caresId) {
      caresId = this.caresId;
    }
    const note = {
      ...NOTE_SHELTER_TEMPLATE,
      attachments: {
        shelterStudent: {
          caresId,
          firstLast: studentName,
        },
      },
      shelterId: this.shelterId,
      contextPartnerType: this.partnerType,
    };

    return note;
  }

  public initFormControlsToEditNote (docId?): void {
    let noteId;
    if (this.mode === VALID_NOTE_MODES.EDIT_MODAL) {
      noteId = docId;
    } else {
      noteId = this.data.note.docId || this.docId;
    }
    this.apiService.getNote(noteId, this.partnerType).subscribe((data:any) => {
      this.noteData = data;

      const initialForm = {
        note: this.noteData.body,
        selectedCategories: this.noteData.categories,
      };

      this.noteForm = this.formBuilder.group({
        note: [initialForm.note, Validators.required],
        selectedCategories: [initialForm.selectedCategories, Validators.required],
      });
      if (this.imUser.categoriesIncludesEcfik(data.categories)) {
        this.setEcfikFormFields('EDIT', data);
      }
    });
  }

  public handleSelectedCategories (selectedCats): void {
    this.noteForm.controls.selectedCategories.setValue(selectedCats);
    this.noteForm.controls.selectedCategories.markAsDirty();
    if (this.imUser.categoriesIncludesEcfik(this.noteForm.controls.selectedCategories.value)) {
      this.setEcfikFormFields('ADD');
    } else {
      this.setEcfikFormFields('REMOVE');
    }
  }

  public handleSelectedNeeds (selectedNeeds): void {
    this.noteForm.controls.needs.setValue(selectedNeeds);
    this.noteForm.controls.needs.markAsDirty();
  }

  public onClickPrimaryBtn (): void {
    const payload = this._prepPayloadForApi();

    switch (this.primaryBtnName) {
      case 'Add':
        if (this.mode === VALID_NOTE_MODES.CREATE_BULK_SCHOOL) {
          const ids = this.students;
          const noteMeta: TFormatNoteEventArgs = {
            view: 'BATCH-ACTION',
            portal: 'SCHOOL',
            categories: this.noteForm.value.selectedCategories,
            currentExam: this.data.currentExam ?? null,
          };
          const batchActionMeta: IFormatBatchActionEventArgs = {
            item: 'Note',
            view: this.origin,
            portal: 'SCHOOL',
            currentExam: this.data.currentExam ?? null,
          };
          const noteEvent = this.eventFormatterService.getCreateNoteEvent(noteMeta);
          const batchNoteEvent = this.eventFormatterService.getCreateBatchActionEvent(null, batchActionMeta);
          const mixpanelEvents = [noteEvent, batchNoteEvent];
          this.apiService.bulkCreateNote(ids, payload, this.partnerType, mixpanelEvents).pipe(
            tap((createdNote) => {
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkStudentNoteCreate' });
              this.dialogRef.close(createdNote);
            }),
            catchError((err: any) => {
              return throwError(err);
            }),
          ).subscribe();
        }
        if (this.mode === VALID_NOTE_MODES.CREATE_BULK_SHELTER) {
          const noteEvent = this.eventFormatterService.getCreateNoteEvent({
            view: 'BATCH-ACTION',
            portal: 'SHELTER',
            categories: this.noteForm.value.selectedCategories,
          });
          const batchActionEvent = this.eventFormatterService.getCreateBatchActionEvent(null, {
            item: 'Note',
            view: this.origin,
            portal: 'SHELTER',
          });
          const mixpanelEvents = [noteEvent, batchActionEvent];
          this.apiService.bulkCreateNote(this.caresIds, payload, this.partnerType, mixpanelEvents).pipe(
            tap((createdNote) => {
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkShelterStudentNoteCreate' });
              this.dialogRef.close(createdNote);
            }),
            catchError((err: any) => {
              return throwError(err);
            }),
          ).subscribe();
        }
        if (this.mode === VALID_NOTE_MODES.CREATE_SHELTER || this.mode === VALID_NOTE_MODES.CREATE) {
          const noteEvent = this.eventFormatterService.getCreateNoteEvent({
            view: this.listType === 'STUDENT' ? 'STUDENT-PROFILE' : 'GRID-ROW',
            portal: this.partnerType === 'shelter' ? 'SHELTER' : 'SCHOOL',
            categories: this.noteForm.value.selectedCategories,
          });
          this.apiService.createNote(payload, noteEvent).pipe(
            tap((createdNote) => {
              this.dialogRef.close(createdNote);
            }),
            catchError((err: any) => {
              return throwError(err);
            }),
          ).subscribe();
        }
        break;
      case 'Save':
        this.apiService.patchNote(
          payload._id, pick(payload, this.imNoteService.getEditableFields()),
          this.partnerType,
          this.eventFormatterService.getUpdateNoteEvent({
            view: this.listType === 'STUDENT' ? 'STUDENT-PROFILE' : 'GRID-ROW',
            portal: this.partnerType === 'shelter' ? 'SHELTER' : 'SCHOOL',
            categories: this.noteForm.value.selectedCategories,
          })).pipe(
          tap((updatedNote) => {
            this.dialogRef.close(updatedNote);
          }),
          catchError((err: any) => {
            return throwError(err);
          }),
          ).subscribe();
        break;
      default:
        break;
    }
  }

  private _prepPayloadForApi (): ISchoolNote | IShelterNote {
    const noteBody = this.noteForm.controls.note.value;
    const categories = this.noteForm.controls.selectedCategories.value;
    const payload = {
      ...this.noteData,
      body: noteBody,
      categories,
    };

    if (this.imUser.categoriesIncludesEcfik(this.noteForm.controls.selectedCategories.value)) {
      const ecfikNoteFields: IECFIKNoteFields = {
        logDate: this.noteForm.controls.logDate.value,
        type: this.noteForm.controls.type.value,
        status: this.noteForm.controls.status.value,
        needs: this.noteForm.controls.needs.value,
        serviceReferral: this.noteForm.controls.serviceReferral.value,
      };
      payload.ecfik = ecfikNoteFields;
    } else {
      if (payload.ecfik) delete payload.ecfik;
    }
    return payload;
  }

  private setEcfikFormFields (action: 'ADD' | 'REMOVE' | 'EDIT', noteData?: ISchoolNote): void {
    if (action === 'ADD') {
      const todaysDate = this.dateHelpers.getFormattedNow(this.DATE_FORMAT);
      const initEcfikFormFieldValues = {
        logDate: todaysDate,
        type: null,
        status: null,
        needs: [],
        serviceReferral: false,
      };
      const formControlValidators = {
        logDate: [initEcfikFormFieldValues.logDate, Validators.required],
        type: [initEcfikFormFieldValues.type, Validators.required],
        status: [initEcfikFormFieldValues.status, Validators.required],
        needs: [initEcfikFormFieldValues.needs],
        serviceReferral: [initEcfikFormFieldValues.serviceReferral],
      };
      Object.values(ECFIKFormOptionsFields).forEach(field => {
        this.noteForm.addControl(field, new FormControl(formControlValidators[field][0], formControlValidators[field][1]));
      });
      this.ecfikFormFieldsSet = true;
    } else if (action === 'EDIT') {
      const ecfikNoteData = noteData.ecfik;
      const initEcfikFormFieldValues = {
        logDate: ecfikNoteData?.logDate || '',
        type: ecfikNoteData?.type || null,
        status: ecfikNoteData?.status || null,
        needs: ecfikNoteData?.needs?.length ? ecfikNoteData.needs : [],
        serviceReferral: ecfikNoteData?.serviceReferral || false,
      };

      this.noteForm.addControl('logDate', new FormControl(initEcfikFormFieldValues.logDate, Validators.required));
      this.noteForm.addControl('type', new FormControl(initEcfikFormFieldValues.type, Validators.required));
      this.noteForm.addControl('status', new FormControl(initEcfikFormFieldValues.status, Validators.required));
      this.noteForm.addControl('needs', new FormControl(initEcfikFormFieldValues.needs));
      this.noteForm.addControl('serviceReferral', new FormControl(initEcfikFormFieldValues.serviceReferral));

      this.ecfikFormFieldsSet = true;
    } else {
      Object.values(ECFIKFormOptionsFields).forEach(field => {
        this.noteForm.removeControl(field);
      });
      this.ecfikFormFieldsSet = false;
    }
  }

  public handleDropdownSelect ($event, field: ECFIKFormOptionsFields) {
    this.noteForm.controls[field].setValue($event);
    this.noteForm.controls[field].markAsDirty();
  }

  public onCancel (): void {
    this.dialogRef.close();
  }
}
