import { BaseModalModule } from './../base-modal.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvSegmentedControlModule } from './../../../../nvps-libraries/design/nv-segmented-control/nv-segmented-control.module';
import { NvNoteModule } from './../../../../nvps-libraries/design/nv-note/nv-note.module';
import { NgModule } from "@angular/core";
import { ListContainerModule } from "../../components/list/list-container/list-container.module";
import { PostsecDataSourcesModalComponent } from "./postsec-data-sources-modal.component";
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [PostsecDataSourcesModalComponent],
  imports: [
    BaseModalModule,
    CommonModule,
    ListContainerModule,
    NvNoteModule,
    NvSegmentedControlModule,
    NvButtonModule,
  ],
  exports: [PostsecDataSourcesModalComponent],
  providers: [],
})
export class PostsecDataSourcesModalModule {}
