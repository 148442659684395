import { RollbarService } from './../services/rollbar/rollbar.service';
import { Inject, Injectable, Injector } from '@angular/core';
import { Auth0DecodedHash, Auth0UserProfile, AuthOptions, AuthorizeOptions, LogoutOptions, WebAuth } from 'auth0-js';
import * as jwt_decode from 'jwt-decode'; // eslint-disable-line
import { includes } from 'lodash';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { LocalStorageService } from 'Src/ng2/shared/services/web-storage/local-storage/local-storage.service';
import { PortalConfig } from '../services/portal-config';
import * as Rollbar from 'rollbar';
import { SessionStorageService } from '../services/web-storage/session-storage/session-storage.service';

/**
 * Interface of decoded Auth0 token (CM).
 */
interface IIdTokenDecoded {
  email: string;
  email_verified: boolean; // eslint-disable-line
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
}

/**
 * Interface of user returned by Auth0 login success rule (CM).
 */
export interface IAuth0Profile extends Auth0UserProfile {
  dbUser: IDbUser; // eslint-disable-line
}

export interface IDistrict {
  _id: string;
  displayName: string;
  canAccess: boolean;
  permissions?: {
    canManageNotifications?: boolean;
  }
}

interface IDbUser {
  _id: string;
  districts: IDistrict[];
}

export type TAuthLocalStorageVals =
  | 'idToken'
  | 'accessToken'
  | 'lastKnownUrl'
  | 'prevUrl'
  | 'currentUserId'
  | 'errorMessage'
  | 'loginMethod'
  | 'loginEmail'
  | 'districts'
  | 'currentDistrict'
  | 'ObjectCache'
  | 'schoolSideNavOpenState';

@Injectable()
export class Auth {
  private auth0: WebAuth;
  private readonly AUTHORIZE_OPTIONS_GOOGLE: AuthorizeOptions = {
    connection: 'google-oauth2',
    prompt: 'select_account',
  };

  private readonly AUTHORIZE_OPTIONS_NYCDOE: AuthorizeOptions = {
    connection: 'NYCDOE',
  };

  private readonly AUTHORIZE_OPTIONS_CARES: AuthorizeOptions = {
    connection: 'NYC-DSS',
    prompt: 'login'
  };

  private readonly AUTHORIZE_OPTIONS_EXPANDED: AuthorizeOptions = {
    connection: 'ExpandEdID',
    prompt: 'login',
  };

  private readonly AUTHORIZE_OPTIONS_CUNY: AuthorizeOptions = {
    connection: 'CUNY',
    prompt: 'login',
  };

  private readonly AUTHORIZE_OPTIONS_BROOMESTREET: AuthorizeOptions = {
    connection: 'broomestreetacademy',
    prompt: 'login',
  };

  // These are error messages that auth0 can return when renewing a token.
  // No patterns have been identified as to when these errors are returned by auth0,
  // so we ignore them and attempt to renew the token again (CM).
  private readonly AUTH0_ERR_DESCRIPTIONS_TO_IGNORE: string[] = [
    'Algorithm HS256 is not supported. (Expected algs: [RS256])',
    'Timeout during executing web_message communication',
    'Login required',
  ];

  private tokenRenewalTimeout;
  private publicConfig: PortalConfig['publicConfig'];

  constructor (
    private injector: Injector,
    private utilitiesService: UtilitiesService,
    private localStorageService: LocalStorageService,
    private portalConfig: PortalConfig,
    private sessionStorageService: SessionStorageService,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {
    this.publicConfig = this.portalConfig.publicConfig;
    // We dymamically get the `callbackUrl` for the env;
    // We use `window` instead of `$window` because AngularJS services are not yet
    // available inside of config blocks (CM).
    const { hostname, port, protocol } = window.location;
    const callbackPath = '/callback';
    // window.location.origin would provide the same result, but it is not supported in IE (CM).
    const origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    const callbackUrl = `${origin}${callbackPath}`;

    // Initialization opts for auth0 (CM).
    const AUTH_OPTIONS: AuthOptions = {
      clientID: this.publicConfig.AUTH0_CLIENT_ID,
      domain: this.publicConfig.AUTH0_HOSTNAME,
      redirectUri: callbackUrl,
      responseType: 'token id_token',
      scope: 'openid profile email',
    };
    this.auth0 = new WebAuth(AUTH_OPTIONS);
  }

  /**
   * gets Auth0 `profile.dbUser._id`
   */
  public async getCurrentUserEmail (): Promise<string> {
    let email: string = this.getValInLocalStorage('loginEmail');

    if (!email) {
      let profile: IAuth0Profile;
      try {
        profile = await this.getProfile();
      } catch (err) {
        throw this.utilitiesService.handleAwaitErr(err);
      }
      email = profile.email;
      this.setValInLocalStorage('loginEmail', email);
    }
    return email;
  }

  public async getUserDistricts (): Promise<IDistrict[]> {
    let districts = this.getValInSessionStorage('districts');
    let profile: IAuth0Profile;
    if (!districts || (districts && !districts.length)) {
      try {
        profile = await this.getProfile();
      } catch (err) {
        throw this.utilitiesService.handleAwaitErr(err);
      }

      if (profile.dbUser && profile.dbUser.districts) {
        districts = profile.dbUser.districts;
      } else {
        const dbUser = this.getDbUserFromProfile(profile);
        districts = dbUser.districts;
      }

    }
    const filteredDistricts = this.filterUserDistricts(districts);
    this.setValInSessionStorage('districts', filteredDistricts);
    return filteredDistricts;
  }

  private filterUserDistricts (userDistricts: IDistrict[]) {
    return userDistricts.filter(d => d.canAccess);
  }

  private getDbUserFromProfile (profile) {
    // get auth0 custom claims, which meets auth0 current scope requirements (CM).
    // TODO: When connecting local frontend to local backend, we still make use of Auth0 dev
    // because there is not currently an Auth0 env for localhost. So we must use the dev API url
    // to find the user credentials. We should find a better way to do this. Either setup a
    // localhost Auth0 env or use a different URL (JC).
    const { dbUser } = profile[this.publicConfig.AUTH0_NAMESPACE || this.publicConfig.NV_API_ORIGIN];

    return dbUser;
  }

  /**
   * Gets `idToken` from local storage
   */
  public getIdToken () {
    const key = 'idToken';
    const idToken = this.getValInLocalStorage(key);

    return idToken;
  }

  // This needs to return a promise in order to avoid unexpected authentication behavior(JJ)
  public handleAuthentication (): Promise<void> {
    return new Promise(resolve => {
      this.auth0.parseHash(async (err, authResult) => {
        if (authResult) {
          this.setSession(authResult);
        } else if (this.isAuthenticated()) {
          await this.getUserDistricts();
          this.scheduleRenewal();
        } else if (err) {
          const { error, errorDescription } = err;
          const errorMessage = errorDescription || error;
          this.logout({ errorMessage, skipAuth0Logout: true });
        }
        resolve();
      });
    });
  }

  /**
   * Checks whether the user is authenticated (CM).
   */
  public isAuthenticated (): boolean {
    const idToken = this.getIdToken();

    if (!idToken) return false; // missing required local storage values (CM).
    const authenticated = !this.isTokenExpired(idToken);

    return authenticated;
  }

  /**
   * Logs in user using `google-oauth2` (CM).
   */
  public loginWithGoogle (): void {
    try {
      // initiates auth0 authentication (CM).
      this.auth0.authorize(this.AUTHORIZE_OPTIONS_GOOGLE);
      this.setValInLocalStorage('loginMethod', this.AUTHORIZE_OPTIONS_GOOGLE.connection);
    } catch (err) {
      this.rollbar.warn('Auth#login using google email: ', err);
    }
  }

  /**
   * Logs in user using SAML (CM).
   */
  public loginWithNycDoe (): void {
    try {
      // initiates auth0 authentication (CM).
      this.auth0.authorize(this.AUTHORIZE_OPTIONS_NYCDOE);
      this.setValInLocalStorage('loginMethod', this.AUTHORIZE_OPTIONS_NYCDOE.connection);
    } catch (err) {
      this.rollbar.warn('Auth#login using NYC DOE email: ', err);
    }
  }

  /**
   * Logs in user using auth0 on top of cares system
   */
  public loginWithCares(): void {
    try {
      // initiates auth0 authentication (CM).
      this.auth0.authorize(this.AUTHORIZE_OPTIONS_CARES);
      this.setValInLocalStorage('loginMethod', this.AUTHORIZE_OPTIONS_CARES.connection);
    } catch (err) {
      this.rollbar.debug('Auth#login using CARES email: ', err);
    }
  }

  /**
   * Logs in user using auth0 on top of expanded system
   */
  public loginWithExpandEd (): void {
    try {
      this.auth0.authorize(this.AUTHORIZE_OPTIONS_EXPANDED);
      this.setValInLocalStorage('loginMethod', this.AUTHORIZE_OPTIONS_EXPANDED.connection);
    } catch (err) {
      this.rollbar.debug('Auth#login using ExpandEd email: ', err);
    }
  }

  /**
   * Logs in user using auth0 on top of CUNY system
   */
  public loginWithCUNY (): void {
    try {
      this.auth0.authorize(this.AUTHORIZE_OPTIONS_CUNY);
      this.setValInLocalStorage('loginMethod', this.AUTHORIZE_OPTIONS_CUNY.connection);
    } catch (err) {
      this.rollbar.debug('Auth#login using CUNY email: ', err);
    }
  }

  /**
   * Logs in user using auth0 on top of BROOMESTREET system
   */
  public loginWithBroomeStreet (): void {
    try {
      this.auth0.authorize(this.AUTHORIZE_OPTIONS_BROOMESTREET);
      this.setValInLocalStorage('loginMethod', this.AUTHORIZE_OPTIONS_BROOMESTREET.connection);
    } catch (err) {
      this.rollbar.debug('Auth#login using BROOMESTREET email: ', err);
    }
  }

  /**
   * Logs in user using auth0 uviversal login
   */
  public loginWithRedirect (): void {
    try {
      // initiates auth0 authentication (CM).
      this.auth0.authorize({ prompt: 'login' });
    } catch (err) {
      this.rollbar.debug('Auth#login using universal login: ', err);
    }
  }

  /**
   * Logs out user by:
   * - clearing local storage of tokens.
   * - clearing scheduled token renewal.
   * - redirecting user to login (CM).
   *
   * If `errorMessage` is passed in, it will be displayed in login box when user is redirected to login page (CM).
   * If `lastKnowUrl` is passed in, the user will be redirected to that url next time they log in (CM).
   */
  public logout (opts?: { errorMessage?: string; lastKnownUrl?: string; prevUrl?: string; skipAuth0Logout?: boolean }): void {
    const loginMethod = this.getValInLocalStorage('loginMethod');
    const errorMessage = opts ? opts.errorMessage : null;
    const tokenPathPattern = /#access_token=/gi;
    const lastKnownUrlIsValid = opts && opts.lastKnownUrl && !opts.lastKnownUrl.match(tokenPathPattern);
    const lastKnownUrl = lastKnownUrlIsValid ? opts.lastKnownUrl : null;
    // skipAuth0Logout is used to avoid redirects in the case of an authentication error
    const skipAuth0Logout = opts ? opts.skipAuth0Logout : null;
    // set error message in local storage, which will be displayed in login page (CM).
    if (errorMessage) this.setValInLocalStorage('errorMessage', errorMessage);

    // Set last know url in local storate (CM).
    if (lastKnownUrl) this.setValInLocalStorage('lastKnownUrl', lastKnownUrl);
    else this.removeValInLocalStorage('lastKnownUrl');

    // Set the prev url in local storage (AK)
    const prevUrlIsValid = opts && opts.prevUrl && !opts.prevUrl.match(tokenPathPattern);
    const prevUrl = prevUrlIsValid ? opts.prevUrl : null;
    if (prevUrl) this.setValInLocalStorage('prevUrl', prevUrl);
    else this.removeValInLocalStorage('prevUrl');

    // Remove login time
    this.localStorageService.removeItem('lastLogin');

    // Cancel existing token renewal timeout (CM).
    clearTimeout(this.tokenRenewalTimeout);

    // Clear local storage (CM).
    this.clearLocalStorageOfAuthVals();

    // Clears all intercom cookies
    this.intercomShutdown();

    const auth0LogoutOpts: LogoutOptions = {
      returnTo: this.getReturnToUrl()
    };

    if (loginMethod === this.AUTHORIZE_OPTIONS_CARES.connection) {
      auth0LogoutOpts.federated = true;
    }

    if (!skipAuth0Logout) {
      // Ends auth0 session and refreshes browser (CM).
      this.auth0.logout(auth0LogoutOpts);
    }
    this.localStorageService.removeItem('chunkLoadError');
  }

  private intercomShutdown (): void {
    (window as any).Intercom('shutdown');
  }

  // clears local storage of values used for authentication (CM)
  public clearLocalStorageOfAuthVals () {
    // Remove login method
    this.removeValInLocalStorage('loginMethod');

    // Remove login email
    this.removeValInLocalStorage('loginEmail');

    // Remove tokens from localStorage (CM).
    this.removeValInLocalStorage('accessToken');
    this.removeValInLocalStorage('idToken');

    // Remove currentUserId
    this.removeValInLocalStorage('currentUserId');

    // Remove ObjectCache
    this.removeValInLocalStorage('ObjectCache');

    // Remove selected district
    this.removeValInSessionStorage('currentDistrict');

    // Remove user districts
    this.removeValInSessionStorage('districts');
    // Remove sideNavOpenState
    this.removeValInLocalStorage('schoolSideNavOpenState');
  }

  /**
   * Decodes a jwt token
   */
  private decodeToken (idToken: string) {
    let idTokenDecoded: IIdTokenDecoded;
    try {
      idTokenDecoded = jwt_decode<IIdTokenDecoded>(idToken);
    } catch (ex) {
      idTokenDecoded = null;
    }

    return idTokenDecoded;
  }

  /**
   * Fetches auth0 user
   */
  private getProfile (): Promise<IAuth0Profile> {
    const promise = new Promise<IAuth0Profile>((resolve, reject) => {
      const accessToken = this.getValInLocalStorage('accessToken');
      if (!accessToken) {
        return reject('Access token must exist to fetch profile'); // eslint-disable-line
      }
      this.auth0.client.userInfo(accessToken, (err, profile: IAuth0Profile) => {
        if (err) {
          this.rollbar.warn('Error in Auth#getProfile', err);
          return reject(err);
        }

        return resolve(profile);
      });
    });

    return promise;
  }

  /**
   * Dynamically returns url for env that auth0 will redirect to on logout.
   * KEEP THIS LOGIC IN SYNC WITH how the auth0 `redirectTo` in
   * `auth.config.constant.ts` is generated. - unfortunately, we are not able
   * to inject services in config blocks; thus, cannot share this - method with
   * the login found in `auth.config.constant.ts`.
   */
  private getReturnToUrl (): string {
    // We dymamically get the `returnToUrl` for the env;
    const { hostname, port, protocol } = window.location;
    const returnToPath = '/login';
    // window.location.origin would provide the same result, but it is not supported in IE (CM).
    const origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    const returnToUrl = `${origin}${returnToPath}`;

    return returnToUrl;
  }

  /**
   * Gets `exp` in seconds of a jwt token
   * If token does not have an `exp` property, it returns `null`. This can happen for tokens that do not expire (CM).
   */
  private getTokenExpInSeconds (idToken: string): number {
    const decodedToken = this.decodeToken(idToken);
    if (decodedToken) {
      const { exp } = decodedToken;

      if (exp) {
        const idTokenExpInSeconds = exp * 1000;

        return idTokenExpInSeconds;
      }
    }

    return null;
  }

  /**
   * Gets a value in local storage
   */
  private getValInLocalStorage (key: TAuthLocalStorageVals) {
    const val = this.localStorageService.getItem(key);

    return val;
  }

  /**
   * Checks whether a jwt token is expired
   */
  private isTokenExpired (idToken: string): boolean {
    const idTokenExpInSeconds = this.getTokenExpInSeconds(idToken);
    const now = new Date().getTime();
    // if `idTokenExpInSeconds` is `null`, then the token does not have expiration (CM).
    const expired = idTokenExpInSeconds !== null ? idTokenExpInSeconds < now : false;

    return expired;
  }

  /**
   * Removes a value in local storage
   */
  private removeValInLocalStorage (key: TAuthLocalStorageVals): void {
    this.localStorageService.removeItem(key);
  }

  /**
   * Renews auth0 token
   */
  private renewToken (): void {
    this.auth0.checkSession({}, (err, result) => {
      const _error = err || (result && result.error);
      if (_error) {
        // auth0 are inconsistent - some have an `errorDescription` property, other have `error_description` (CM).
        const errorDescription = _error.errorDescription || _error.error_description;
        const ignoreErrorDescription = includes(this.AUTH0_ERR_DESCRIPTIONS_TO_IGNORE, errorDescription);
        if (ignoreErrorDescription) {
          this.rollbar.warn('Auth#renewToken - renewing token', _error);

          // Attempt to renew token again.
          // setTimeout is to prevent infinite loop (CM).
          this.delay(() => {
            if (this.isAuthenticated()) {
              this.renewToken();
            } else {
              this.logout();
            }
          }, 5000);
        } else {
          this.rollbar.warn('Auth#renewToken - logging out', _error);
          this.logout();
        }
      } else {
        this.setSession(result);
      }
    });
  }

  private delay (cb: Function, ms: number) {
    return setTimeout(cb, ms);
  }

  /**
   * Schedules an auth0 token renewal
   */
  private scheduleRenewal (idToken?: string): void {
    const _idToken = idToken || this.getIdToken();
    const idTokenExpInSeconds = this.getTokenExpInSeconds(_idToken);

    // if `idTokenExpInSeconds` is `null`, then the token does not have expiration (CM).
    if (idTokenExpInSeconds !== null) {
      let delay = idTokenExpInSeconds - Date.now();

      if (delay > 0) {
        // This sets the token renewal to happen in the amount of time remaining for the token to
        // expire minus 120 seconds (CM).
        delay -= 120000; // if this number is negative, it will immediately trigger the renewal (CM).

        this.tokenRenewalTimeout = this.delay(() => {
          this.renewToken();
        }, delay);
      }
    }
  }

  /**
   * Sets `accessToken` and `idToken` in local storage.
   * - `accessToken`: token used to get user profile from auth0.
   * - `idToken`: token used to get data from API.
   * Schedules token renewal (CM).
   */
  private setSession (authResult: Auth0DecodedHash): void {
    const { accessToken, idToken, idTokenPayload: profile } = authResult;
    const dbUser = this.getDbUserFromProfile(profile);
    const userAccessibleDistricts = this.filterUserDistricts(dbUser.districts);

    this.setValInLocalStorage('accessToken', accessToken);
    this.setValInLocalStorage('idToken', idToken);
    this.setValInSessionStorage('districts', userAccessibleDistricts);
    this.setValInLocalStorage('loginEmail', profile.email);
    if (userAccessibleDistricts.length === 1) this.setValInSessionStorage('currentDistrict', userAccessibleDistricts[0]._id);
    this.localStorageService.setItem('lastLogin', new Date().toString());
    this.scheduleRenewal(idToken);
  }

  /**
   * Sets a value in local storage
   */
  private setValInLocalStorage (key: TAuthLocalStorageVals, val: string | IDistrict[]) {
    this.localStorageService.setItem(key, val);
  }

  /**
   * Sets a value in session storage
   */
  private setValInSessionStorage (key: string, val: string | IDistrict[]) {
    this.sessionStorageService.setItem(key, val);
  }

  /**
   * Gets a value in session storage
   */
  private getValInSessionStorage (key: string) {
    return this.sessionStorageService.getItem(key);
  }

  /**
   * Gets a value in session storage
   */
  private removeValInSessionStorage (key: string) {
    this.sessionStorageService.removeItem(key);
  }
}
