import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvNoteComponent } from './nv-note.component';

@NgModule({
  imports: [CommonModule, NvIconModule, NvButtonModule],
  declarations: [NvNoteComponent],
  exports: [NvNoteComponent],
})
export class NvNoteModule {}
