import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'Src/ng2/store';
import { IUser } from '../../../typings/interfaces/user.interface';
import { ImUser } from '../../../services/im-models/im-user';
import { Unsubscribable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MockRegentsViewType } from 'Src/ng2/school/content-area/mock-regents/data/mock-regents-grid-data.service';

@Component({
  selector: 'app-no-rows-overlay',
  templateUrl: './no-rows.component.html',
  styleUrls: ['./no-rows.component.scss'],
})

export class NoRowsComponent implements INoRowsOverlayAngularComp {
  public params: any;
  public showButton: boolean = true;
  public messageDivClass: string;
  public primaryEmptyStateHeader: string;
  public secondaryEmptyStateMessage: string;
  public secondaryMessageClass: string;
  public additionalTextMessage: string;
  public additionalMessageClass: string;
  public backgroundImage: string;
  public buttonText: string = 'Clear all filters';
  public disabledButton: boolean = false;
  public linkUrl: string;
  public linkLabel: string;
  public secondaryLinkUrl: string;
  public secondaryLinkLabel: string;
  public currentUserSubscription: Unsubscribable;
  private isCaseloadUser: boolean;
  private mockRegentsQuestionView: boolean = false;

  constructor (
    private store: Store<any>,
    private imUser: ImUser,
  ) { }

  agInit (params: any): void {
    this.params = params;
    let toggleState;
    let parentToggleOptions;
    let hasAppliedFilters;

    const currentUserSlice$ = this.store?.select(getCurrentUser);
    this.currentUserSubscription = currentUserSlice$?.pipe(take(1)).subscribe((user: IUser) => {
      this.isCaseloadUser = this.imUser.isCaseloadUser(user);
    });

    if (this.params.parentName === 'history-all-modal') {
      parentToggleOptions = this.params.parentComponent.options;
      toggleState = parentToggleOptions[this.params.parentComponent.listIndex];
      hasAppliedFilters = this.params.parentComponent.hasAppliedFilters;
      if (!hasAppliedFilters) this.showButton = false;
    }
    if (this.params.parentName === 'mock-regents-grid') {
      this.mockRegentsQuestionView = this.params.parentComponent.selectedViewOption === MockRegentsViewType.QUESTIONS;
    }
    this._getNoRowsMessage({ parentName: this.params.parentName, toggleState, hasAppliedFilters });
    this._getEmptyMessageClass(this.params.parentName);
    this.backgroundImage = this.params.parentName === 'scheduled-course-plan' ? 'no-rows-small' : (this.params.backgroundImage || 'no-rows-default');
  }

  private _getNoRowsMessage ({
    parentName,
    toggleState,
    hasAppliedFilters,
  }: {
    parentName: string;
    toggleState?: string;
    hasAppliedFilters?: boolean;
  }): void {
    if (parentName === 'history-all-modal') {
      const isAllLogsView = toggleState === 'All logs';
      this._setHistoryLogsMessage({ hasAppliedFilters, isAllLogsView });
    } else if (parentName === 'grid-groups') {
      const { grandparentComponent } = this.params;
      this.showButton = false;
      this.primaryEmptyStateHeader = grandparentComponent.primaryEmptyStateHeader;
      this.secondaryEmptyStateMessage = grandparentComponent.secondaryEmptyStateMessage;
      this.additionalTextMessage = grandparentComponent.additionalTextMessage;
    } else if (parentName === 'scheduled-course-plan') {
      this.showButton = false;
      this.primaryEmptyStateHeader = 'No course plans found';
      this.secondaryEmptyStateMessage = 'There are no results found matching this filter combination. Remove the last filter added or clear all filters to start your search again.';
    } else if (parentName === 'supports-table') {
      this.showButton = false;
      this.primaryEmptyStateHeader = `No active or upcoming supports for ${this.params.currentSchoolYear}.`;
      this.secondaryEmptyStateMessage = '';
    } else if (parentName === 'my-tasks' || parentName === 'student-tasks-notes-panel') {
      const {
        buttonText,
        primaryEmptyStateHeader,
        secondaryEmptyStateMessage,
        showButton,
        linkUrl,
        linkLabel,
        disabledButton,
        additionalTextMessage,
        secondaryLinkUrl,
        secondaryLinkLabel,
      } = this.params;
      this.primaryEmptyStateHeader = primaryEmptyStateHeader;
      this.secondaryEmptyStateMessage = secondaryEmptyStateMessage;
      this.additionalTextMessage = additionalTextMessage;
      this.secondaryLinkUrl = secondaryLinkUrl;
      this.secondaryLinkLabel = secondaryLinkLabel;
      this.showButton = showButton;
      if (showButton) this.buttonText = buttonText;
      if (parentName === 'student-tasks-notes-panel') {
        this.linkUrl = linkUrl;
        this.linkLabel = linkLabel;
        this.disabledButton = disabledButton;
      }
    } else if (parentName === 'student-behavioral-incidents-panel') {
      this.showButton = false;
      this.primaryEmptyStateHeader = 'No incidents found';
      this.secondaryEmptyStateMessage = '';
    } else if (parentName === 'mock-regents-grid') {
      this.showButton = false;
      this.primaryEmptyStateHeader = 'No Mock Regents found';
      this.secondaryEmptyStateMessage = this.isCaseloadUser ? 'There are no students in your caseload with Mock Regents' : 'There are no results found matching this filter combination. Remove the last filter added or clear all filters to start your search again.';
    } else if (parentName === 'regents-results-grid') {
      this.showButton = false;
      this.primaryEmptyStateHeader = 'No Regents results found';
      this.secondaryEmptyStateMessage = this.isCaseloadUser ? 'There are no students in your caseload with Regents results' : 'There are no results found matching this filter combination. Remove the last filter added or clear all filters to start your search again.';
    } else {
      this.primaryEmptyStateHeader = 'No results found';
      this.secondaryEmptyStateMessage =
        'There are no results found matching this filter combination. Remove the last filter added or clear all filters to start your search again.';
    }
  }

  private _setHistoryLogsMessage ({ hasAppliedFilters, isAllLogsView } : { hasAppliedFilters: boolean, isAllLogsView: boolean }) : void {
    let logName: string;
    if (isAllLogsView) logName = 'history logs';
    else logName = 'notes';

    // primary message
    if (hasAppliedFilters) this.primaryEmptyStateHeader = `No ${logName} available with selected filters`;
    else this.primaryEmptyStateHeader = `No ${logName} available`;

    // secondary message
    if (hasAppliedFilters) {
      this.secondaryEmptyStateMessage = isAllLogsView
        ? 'There are no history logs available for this student that match these filters. To see logs, remove some filters.'
        : 'There are no notes logged for this student that match these filters. To see notes, remove some filters.';
    } else {
      this.secondaryEmptyStateMessage = isAllLogsView
        ? 'There are no history logs available for this student. To see logs, create an action for this student, such as adding this student to a support. To see notes, add a note for this student.'
        : "There are no notes logged for this student yet. When a note is added, it will appear here. To keep track of important details for this student, add a note by clicking 'Add Note'.";
    }
  }

  private _getEmptyMessageClass (parentName): void {
    this.messageDivClass = 'text-message-main';
    this.secondaryMessageClass = 'short-secondary-message';
    if (parentName === 'history-all-modal') {
      this.messageDivClass = 'text-message-wide';
      this.secondaryMessageClass = 'short-secondary-message';
    }
    if (parentName === 'scheduled-course-plan' || parentName === 'student-behavioral-incidents-panel') {
      this.messageDivClass = 'text-message-small-window';
      this.secondaryMessageClass = 'short-secondary-message';
    }
    if (parentName === 'supports-table') {
      this.messageDivClass = 'supports-table-container';
      this.secondaryMessageClass = '';
    }

    if (parentName === 'student-tasks-notes-panel') {
      this.messageDivClass = 'student-panel-container';
      this.secondaryMessageClass = 'short-secondary-message';
    }

    if (parentName === 'my-tasks') {
      this.additionalMessageClass = 'additional-message-with-link';
    }
  }

  public onButtonClick () {
    if (this.params?.parentName === 'network-foci') {
      this.params?.parentComponent?.onClearFilters(this.params?.api?.gridOptionsWrapper?.gridOptions);
    } else if (this.params?.parentName === 'my-tasks') {
      this.params.parentComponent.onAddTask();
    } else if (this.params?.parentName === 'student-tasks-notes-panel') {
      if (this.buttonText === 'Add Note') this.params.parentComponent.onAddNote();
      if (this.buttonText === 'Create Task') this.params.parentComponent.onAddTask();
    } else {
      this.params?.parentComponent?.onClearFilters();
    }
  }
}
