import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseModalModule } from '../base-modal.module';
import { NvTextboxModule } from './../../../../nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvIconButtonModule } from './../../../../nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvNoteModule } from './../../../../nvps-libraries/design/nv-note/nv-note.module';
import { GradPanelModalComponent } from './grad-panel-modal.component';
import { NvDropdownModule } from 'Src/nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvTextareaModule } from '../../../../nvps-libraries/design/nv-textarea/nv-textarea.module';
import { NvFormFieldModule } from '../../../../nvps-libraries/design/nv-form-field/nv-form-field.module';

@NgModule({
  declarations: [GradPanelModalComponent],
  exports: [GradPanelModalComponent],
  providers: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BaseModalModule,
    MatDialogModule,
    NvTextboxModule,
    NvButtonModule,
    NvIconButtonModule,
    NvIconModule,
    NvNoteModule,
    NvDropdownModule,
    NvTextareaModule,
    NvFormFieldModule,
  ],
})

export class GradPanelModalModule {}
