import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ISidebarFlatNode, ISidebarItem } from './nv-sidebar.interface';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { NvSidebarItemComponent } from '../nv-sidebar-item/nv-sidebar-item.component';

// eslint-disable-next-line no-unused-vars
export enum ShowMoreLess {
  // eslint-disable-next-line no-unused-vars
  Less = 'visible-option-limit-less',
  // eslint-disable-next-line no-unused-vars
  More = 'visible-option-limit-more',
}

/**
 *
 * Displays a list of options. Typically used for routing/navigation or selecting from a set of actions.
 *
 * Omit the `customTitle` when the context of the sidebar makes a customTitle redundant (e.g. Settings).
 *
 */
@Component({
  selector: 'nv-sidebar-list',
  templateUrl: './nv-sidebar-list.component.html',
  styleUrls: ['./nv-sidebar-list.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvSidebarListComponent {
  /**
   *
   *
   * Tree of `ISidebarItems`
   *
   * interface ISidebarItem {
   *   human: string;
   *   key: string;
   *   leftDefaultIcon?: string;
   *   leftSelectedIcon?: string;
   *   rightDefaultIcon?: string;
   *   rightSelectedIcon?: string;
   *   expandAs?: 'accordion' | 'dropdown';
   *   children?: ISidebarItem[] | IDropdownOption[];
   *   disabled?: boolean;
   *   url?: string;
   *   queryParams?: {
   *     filter?: string;
   *     focus?: string;
   *     sort?: string;
   *     grouping?: string;
   *     search?: string;
   *   };
   *   hasBetaFlag?: boolean;
   *   lastChild?: boolean;
   * }
   * ```
   *
   * `expandAs` defines whether the children appear as a dropdown menu, or in an accordion submenu.
   * When set to `accordion`, any `icon` property will be ignored.
   *
   * Note: Nested accordions are not yet supported.
   *
   */
  @Input() listData: ISidebarItem[];

  /**
   *
   * The `key` of the selected option
   */
  @Input() selectedKey: string; // the selected key

  /**
   *
   * The `keys` of the listData that should be expanded on init
   */
  @Input() expandedNodes: string[];

  /**
   *
   * The customTitle of the list
   */
  @Input() customTitle: string;

  /**
   *
   * The label of the title button
   */
  @Input() titleButtonLabel?: string;

  /**
   *
   * The subtitle of the list
   */
  @Input() subtitle: string;

  /**
   *
   * Determines whether the subtitle pulses on change. This is particularly useful for batch actions when the subtitle changes based on the selection.
   */
  @Input() shouldAnimateSubtitle: boolean = false;

  /**
   *
   * Determines if used as a right side nav content tools
   */
  @Input() toolsMode: boolean = false;

  /**
   *
   * If provided adds a show more and show less button if the number of options is
   * higher than the limit
   */
  @Input() visibleOptionsLimit: number = 0;

  /**
   *
   * If provided adds an empty message if the count of the list of items is 0
   */
  @Input() emptyMessage: string;

  /**
   *
   * If provided adds an empty message if the count of the list of items is 0
   */
  @Input() titleIcon: string;

  /**
   *
   * If provided adds an empty message if the count of the list of items is 0
   */
  @Input() titleIconTooltip: string;

  /**
   *
   * Emits the `key` of the selected item (or child item)
   */
  @Output() sidebarListItemSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() titleButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() iconMenuSelect: EventEmitter<{ iconMenuKey: string; nodeKey: string }> = new EventEmitter<{
    iconMenuKey: string;
    nodeKey: string;
  }>();

  private _transformer = (node: ISidebarItem, level: number) => {
    return {
      expandable: node.expandAs === 'accordion' && !!node.children && node.children.length > 0,
      human: node.human,
      key: node.key,
      url: node.url,
      level,
      expandAs: node.expandAs,
      children: node.children,
      parent: node.parent,
      disabled: !!node.disabled,
      leftDefaultIcon: node.leftDefaultIcon,
      leftSelectedIcon: node.leftSelectedIcon,
      rightDefaultIcon: node.rightDefaultIcon,
      rightHoverIcon: node.rightHoverIcon,
      rightHoverIconOptions: node.rightHoverIconOptions,
      rightSelectedIcon: node.rightSelectedIcon,
      leftDefaultIconAriaLabel: node.leftDefaultIconAriaLabel,
      leftSelectedIconAriaLabel: node.leftSelectedIconAriaLabel,
      queryParams: node.queryParams,
      hasBetaFlag: node.hasBetaFlag,
      lastChild: node.lastChild,
      tooltipData: node.tooltipData,
    };
  };

  treeControl = new FlatTreeControl<ISidebarFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: ISidebarFlatNode) => node.expandable;

  public caretDownDefaultIcon = 'caret-down-default';
  public caretDownSelectedIcon = 'caret-down-selected';
  public caretRightDefaultIcon = 'caret-right-default';
  public caretRightSelectedIcon = 'caret-right-selected';

  @ViewChildren(NvSidebarItemComponent) sidebarItems: QueryList<NvSidebarItemComponent>;
  private focusKeyManager: FocusKeyManager<NvSidebarItemComponent>;

  constructor (public changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit (): void {
    if (this.emptyMessage && this.listData.length === 0) {
      const emptyMessage = [{ disabled: true, human: this.emptyMessage, key: 'empty-message' }];
      this.dataSource.data = emptyMessage;
    } else if (this.visibleOptionsLimit && this.listData.length > this.visibleOptionsLimit) {
      const selectedIndex = this.listData.findIndex(({ key }) => key === this.selectedKey);
      if (selectedIndex >= this.visibleOptionsLimit) {
        this.handleVisibleOptionClick(ShowMoreLess.More);
      } else {
        this.handleVisibleOptionClick(null);
      }
    } else {
      this.dataSource.data = this.listData;
    }
    this.addLastChildProperty();

    // Expands the tree nodes of a selected nested item by default
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      if (this.nodeIsSelected(this.treeControl.dataNodes[i])) {
        this.treeControl.expand(this.treeControl.dataNodes[i]);
      }
    }

    // Expands the tree nodes given by the parent
    for (const node of this.treeControl.dataNodes) {
      if (this.expandedNodes?.includes(node.key)) {
        this.treeControl.expand(node);
      }
    }
  }

  ngOnChanges ({ listData, selectedKey }) {
    if (listData && listData.currentValue !== this.dataSource.data) {
      if (this.emptyMessage && this.listData.length === 0) {
        const emptyMessage = [{ disabled: true, human: this.emptyMessage, key: 'empty-message' }];
        this.dataSource.data = emptyMessage;
      } else if (this.visibleOptionsLimit && this.listData.length > this.visibleOptionsLimit) {
        this.listData = listData.currentValue;
        this.handleVisibleOptionClick(null);
      } else {
        this.dataSource.data = listData.currentValue;
      }

      // Expands the tree nodes given by the parent
      for (const node of this.treeControl.dataNodes) {
        if (this.expandedNodes?.includes(node.key)) {
          this.treeControl.expand(node);
        }
      }
    }

    if (selectedKey && !selectedKey.firstChange) {
      // Blurs the previously selected button
      const buttonToBlur = document
        .getElementById(`${selectedKey.previousValue}-sidebar-item`)
        ?.getElementsByTagName('button')[0];
      buttonToBlur?.blur();
    }
  }

  public handleSelect (key: string): void {
    if (key.includes('visible-option-limit')) {
      this.handleVisibleOptionClick(key);
    } else {
      this.sidebarListItemSelect.emit(key);
    }
  }

  public onIconMenuSelect (iconMenuKey: string, nodeKey: string): void {
    this.iconMenuSelect.emit({ iconMenuKey, nodeKey });
  }

  public handleTitleButtonClick (): void {
    this.titleButtonClick.emit();
  }

  private handleVisibleOptionClick (key: string): void {
    let visibleOptions: ISidebarItem[];
    let toggleOption: ISidebarItem;
    if (key === ShowMoreLess.More) {
      visibleOptions = this.listData.slice();
      toggleOption = {
        human: 'Show less',
        key: ShowMoreLess.Less,
      };
    } else {
      visibleOptions = this.listData.slice(0, this.visibleOptionsLimit);
      toggleOption = {
        human: 'Show more',
        key: ShowMoreLess.More,
      };
    }
    visibleOptions.push(toggleOption);
    this.dataSource.data = visibleOptions;
  }

  nodeIsSelected (node: ISidebarFlatNode): boolean {
    return node.key === this.selectedKey || (node.children && node.children.map(n => n.key).includes(this.selectedKey));
  }

  /**
   * Helper method that determines if a node or its parent/children are selected.
   * The return value is used to display the selected background color for the node.
   * Currently only works for 1 level of nested children.
   * @param node {ISidebarFlatNode}
   * @returns {boolean}
   */
  public isNodeHighlighted (node: ISidebarFlatNode): boolean {
    if (!node.parent) return this.nodeIsSelected(node);
    const parentNode = this.treeControl.dataNodes.find(treeNode => treeNode.key === node.parent);
    return this.nodeIsSelected(parentNode);
  }

  nodeIsLastNode (node: ISidebarFlatNode): boolean {
    const nodeIndex = this.dataSource.data.map(n => n.key).indexOf(node.key);
    return nodeIndex === this.dataSource.data.length - 1;
  }

  /**
   * This method flags the last child of a parent node for styling purposes
   */

  addLastChildProperty () {
    this.dataSource.data = this.dataSource.data.map(node => {
      const children = node.children;
      if (children && children.length > 0) {
        const lastChildIndex = children.length - 1;
        let lastChildNode = children[lastChildIndex];
        lastChildNode = {
          lastChild: true,
          ...lastChildNode,
        };
        children.pop();
        children.push(lastChildNode);
      }
      return node;
    });
  }

  ngAfterContentInit () {
    // 1. Manually trigger change detection so that this.sidebarItems is populated
    this.changeDetectorRef.detectChanges();
    // 2. Instantiate FocusKeyManager - wrap sidebarItems inside a FocusKeyManager
    this.focusKeyManager = new FocusKeyManager(this.sidebarItems).withWrap();
  }

  @HostListener('keydown', ['$event'])
  onKeydown (event) {
    // trigger the key manager on keydown
    this.focusKeyManager.onKeydown(event);
  }
}
