<ng-container *ngIf="(dataPipeline$ | async) && (dataSource$ | async) as dataSource">
  <ng-container *ngIf="(hasSearchResult$ | async)">
    <list class="student-level">
      <section class="section-list-header">
        <listhead class="s1" [ngClass]="{ 
          'batch-edit-mode': hideSectionHeaderCheckbox ? (batchActionsMode$ | async) && hideSectionHeaderCheckbox : (batchActionsMode$ | async),
          'batch-edit-mode-no-section-checkbox': (batchActionsMode$ | async) && hideSectionHeaderCheckbox,
          hide: !!hideHeader }">
          <cell *ngIf="!hideSectionHeaderCheckbox">
            <div *ngIf="(batchActionsMode$ | async)">
              <mat-checkbox
                (click)="emitBatchActionSectionIds()"
                [checked]="sectionHeaderIsChecked"
                [indeterminate]="sectionHeaderIsInd"
                class="nv-mat-checkbox"
                [ngClass]="{ 'nv-circle-mat-checkbox': useCircleCheckbox }"
                disableRipple
                [disabled]="!dataSource.length"
              ></mat-checkbox>
            </div>
          </cell>
          <ng-container *ngFor="let header of displayedHeaders; first as isFirst">
            <ng-container *ngIf="isFirst && !showFirstColumnHeader">
              <cell class="first" (click)="emitUpdatedSortCol(header.key)">
                <span
                  nvTooltip
                  [tooltipData]="headerOverflow ? header.tooltip || header.human : header.tooltip"
                  class="{{ header.human }}"
                  (mouseleave)="resetHeaderOverflow()"
                  (mouseenter)="checkHeaderOverflow($event.target.className)"
                >
                  {{ header.human }}
                </span>
                <ng-container>
                  <pill *ngIf="!showRowsSelectedOfTotal" class="invert">{{ dataSource.length }}</pill>
                  <pill *ngIf="showRowsSelectedOfTotal" class="invert"
                    >{{ numRowsSelectedOfTotal }} of {{ dataSource.length }}</pill
                  >
                  <ng-container *ngIf="sortableColumns">
                    <span
                      class="sort-arrow"
                      [ngClass]="{
                        'fixed-up': sortKey$.value === header.key && sortDirection$.value === 'asc',
                        'fixed-down': sortKey$.value === header.key && sortDirection$.value === 'desc'
                      }"
                    ></span>
                  </ng-container>
                </ng-container>
              </cell>
            </ng-container>
            <ng-container *ngIf="!isFirst || showFirstColumnHeader">
              <cell class="center" (click)="emitUpdatedSortCol(header.key)">
                <div class="cell-sleeve">
                  <span></span> <span nvTooltip [tooltipData]="header.tooltip">{{ header.human }} </span>
                  <span
                    class="sort-arrow"
                    [ngClass]="{
                      'fixed-up': sortKey$.value === header.key && sortDirection$.value === 'asc',
                      'fixed-down': sortKey$.value === header.key && sortDirection$.value === 'desc'
                    }"
                  ></span>
                </div>
              </cell>
            </ng-container>
          </ng-container>
        </listhead>
      </section>
      <ng-container *ngIf="(visibleDataSource$ | async) as visibleDataSource">
        <ng-container *ngIf="visibleDataSource.length; else emptyTable">
          <section class="section-list-body">
            <ng-container *ngFor="let dataRow of visibleDataSource; let i = index;  trackBy: trackByRowFn.bind(this)">
              <row
                class="s1"
                [ngClass]="{
                  'batch-edit-mode': batchActionsMode$ | async,
                  'batch-edit-mode-checked': dataRow.isChecked
                }"
              >
                <!-- SINGLE COLUMN - FIRST COL IS BATCH ACTION CHECKBOX CELLS (WHERE APPLICABLE) -->
                <cell>
                  <div *ngIf="(batchActionsMode$ | async) && !dataRow.isStub">
                    <mat-checkbox
                      (click)="emitBatchActionRowId(rowDataToFormat[i], $event)"
                      [checked]="dataRow.isChecked"
                      class="nv-mat-checkbox"
                      [ngClass]="{ 'nv-circle-mat-checkbox': useCircleCheckbox }"
                      disableRipple
                    ></mat-checkbox>
                  </div>
                </cell>
                <!-- ALL OTHER COLUMNS     
                  Every column on a row is a cell
                  The variants are the child span elements
                  In some cases the child span will be given some specific class and populated with some raw value
                  In other cases, a span may actually be replaced entirely with a component, a tooltip, or both, depending on the
                  directives attached.     
                -->
                <cell
                  nvTooltip [tooltipData]="(isSummerSchoolList && !dataRow[0].click) ? 'Student profile not available for students temporarily attending for summer school' : ''"
                  *ngFor="let dataColumn of dataRow; let i = index; first as isFirst; trackBy: trackByColumnFn.bind(this)"
                  class="{{ dataColumn.backgroundStyle }}"
                  [ngClass]="{
                    initial: isFirst,
                    center: !isFirst,
                    pending: dataRow[1]?.sync,
                    'no-click': (isSummerSchoolList && !dataRow[0].click),
                    'batch-edit-mode-disabled-fields': (batchActionsMode$ | async) && !dataColumn.dynamic
                  }"
                  (click)="emitRowClick(dataRow, dataColumn)"
                >
                  <!-- dynamic cols are those where a tooltip or some other component might be injected -->
                  <ng-container *ngIf="dataColumn.dynamic; else nonDynamic">
                    <ng-container *ngIf="displayedHeaders[i].cellTooltip">
                      <span
                        [ngClass]="dataColumn.style"
                        class="truncate"
                        dynamicComponent
                        [dynamicComponentTrigger]="dynamicComponentTrigger"
                        [dynamicComponentInput]="{
                          row: dataRow,
                          column: dataColumn,
                          dynamicComponentInputData: dynamicComponentInputData
                        }"
                        (dynamicComponentOutput)="emitDynamicComponent($event)"
                        nvTooltip
                        [tooltipData]="dataRow[i].tooltip?.simpleContent || dataRow[i].tooltip?.tableContent || 
                        {
                          schoolId: schoolId,
                          contextPartnerId: contextPartnerId,
                          contextPartnerType: contextPartnerType,
                          calc: displayedHeaders[i].cellTooltip,
                          docId: dataRow[0].meta,
                          meta: dataRow[i].meta
                        }"
                      >
                        <span>{{ dataColumn.data }}</span>
                      </span>
                    </ng-container>
                    <!--
                      TODO: Note that it is entirely likely there will be cases
                      where a given column will have keys defined in Airtable that cause them 
                      to fall into multiple buckets, which needs to be addressed. 
                      There are multiple cases where a column on Airtable meets both conditions
                      here:
                      
                      !displayedHeaders[i].cellTooltip 
                      displayedHeaders[i].cellConfig
                      
                      We currently use additional conditions like `dataColumn.style` to 
                      prevent a cell from possibly having multiple cell spans appear, but it is
                      not ideal. 

                    -->
                    <ng-container *ngIf="!displayedHeaders[i].cellTooltip">
                      <!-- TODO: confirm if dynamicComponent is still needed -->
                      <span
                        *ngIf="dataColumn.style !== 'em-dash' && dataColumn.style !== 'row-action'"
                        dynamicComponent
                        (dynamicComponentOutput)="emitDynamicComponent($event)"
                        [dynamicComponentTrigger]="dynamicComponentTrigger"
                        [dynamicComponentInput]="{
                          row: dataRow,
                          column: dataColumn,
                          dynamicComponentInputData: dynamicComponentInputData
                        }"
                        [ngClass]="dataColumn.style"
                        class="truncate"
                      >
                        <span>{{ dataColumn.data }}</span>
                      </span>
                      <span *ngIf="dataColumn.style === 'em-dash'" [ngClass]="dataColumn.style">
                        {{ dataColumn.data }}
                      </span>
                    </ng-container>

                    <ng-container *ngIf="displayedHeaders[i].cellConfig">
                      <span
                        *ngIf="dataColumn.style !== 'em-dash' && dataColumn.style !== 'row-action'"
                        dynamicComponent
                        [dynamicComponentTrigger]="dynamicComponentTrigger"
                        [dynamicComponentInput]="{
                          row: dataRow,
                          column: dataColumn,
                          dynamicComponentInputData: dynamicComponentInputData
                        }"
                        (dynamicComponentOutput)="emitDynamicComponent($event)"
                        [ngClass]="dataColumn.style"
                      >
                        <span>{{ dataColumn.data }}</span>
                      </span>
                      <span *ngIf="dataColumn.style === 'em-dash'" [ngClass]="dataColumn.style">
                        {{ dataColumn.data }}
                      </span>
                      <!-- no span value is necessary as the span is replaced by the directive -->
                      <span
                        *ngIf="dataColumn.style === 'row-action'"
                        [ngClass]="dataColumn.style"
                        dynamicComponent
                        (dynamicComponentOutput)="emitDynamicComponent($event)"
                        [dynamicComponentTrigger]="dynamicComponentTrigger"
                        [dynamicComponentInput]="{
                          row: dataRow,
                          column: dataColumn,
                          dynamicComponentInputData: dynamicComponentInputData
                        }"
                      >
                      </span>
                    </ng-container>
                  </ng-container>

                  <ng-template #nonDynamic>
                    <span *ngIf="!displayedHeaders[i].cellTooltip" [ngClass]="dataColumn.style">
                      <span class="column-data-wrapper">
                        <nv-icon (click)="openModal(dataRow);" class="nv-icon-wrapper nv-icon-left" 
                        *ngIf="!(batchActionsMode$ | async) && dataColumn.hasCellRenderer"
                        [name]="parseItem(dataColumn.meta).cellRendererParams?.iconName"
                      ></nv-icon> 
                      {{ dataColumn.data }}
                        <!-- 
                          To use subdata, add subdata string to dataColumn.meta.subdata.
                          Format the subdata in the respective list's .scss file
                        -->
                        <span *ngIf="dataColumn?.meta?.subData" class="column-subdata">
                          {{ dataColumn.meta.subData.data }}
                        </span>
                        <nv-icon
                          *ngIf="dataColumn.meta?.subData?.icon"
                          [name]="dataColumn.meta.subData.icon.iconName"
                          [color]="dataColumn.meta.subData.icon.iconColor"
                        ></nv-icon>
                      </span> 
                    </span>
                    <ng-container *ngIf="displayedHeaders[i].cellTooltip">
                      <ng-container *ngIf="dataColumn.style !== 'em-dash'">
                        <span
                          *ngIf="displayedHeaders[i].cellTooltip !== 'SIMPLE_OR_TABLE_CONTENT'"
                          nvTooltip
                          [tooltipData]="{
                            schoolId: schoolId,
                            contextPartnerId: contextPartnerId,
                            contextPartnerType: contextPartnerType,
                            calc: displayedHeaders[i].cellTooltip,
                            docId: dataRow[0].meta,
                            meta: dataRow[i].meta
                          }"
                          [ngClass]="dataColumn.style"
                          class="truncate column-data-wrapper"
                        >
                        <nv-icon (click)="openModal(dataRow);" class="nv-icon-wrapper nv-icon-left" 
                        *ngIf="!(batchActionsMode$ | async) && dataColumn.hasCellRenderer"
                        [name]="parseItem(dataColumn.meta).cellRendererParams?.iconName"
                      ></nv-icon> 
                          {{ dataColumn.data }}
                          <span *ngIf="dataColumn?.meta?.subData" class="column-subdata">
                            {{ dataColumn.meta.subData.data }} 
                          </span>
                          <nv-icon
                            *ngIf="dataColumn.meta?.subData?.icon"
                            [name]="dataColumn.meta.subData.icon.iconName"
                            [color]="dataColumn.meta.subData.icon.iconColor"
                          ></nv-icon>
                        </span>
                        <span
                          *ngIf="displayedHeaders[i].cellTooltip === 'SIMPLE_OR_TABLE_CONTENT'"
                          nvTooltip
                          [tooltipData]="dataRow[i].tooltip?.simpleContent || dataRow[i].tooltip?.tableContent"
                          [ngClass]="dataColumn.style"
                          class="truncate column-data-wrapper"
                        >
                          {{ dataColumn.data }}
                          <nv-icon
                            *ngIf="dataColumn.meta?.subData?.icon"
                            [name]="dataColumn.meta.subData.icon.iconName"
                            [color]="dataColumn.meta.subData.icon.iconColor"
                          ></nv-icon>
                        </span>
                        <div *ngIf="dataColumn?.meta?.subData" class="column-subdata">
                          {{ dataColumn.meta.subData.data }} 
                        </div>
                      </ng-container>
                      <span *ngIf="dataColumn.style === 'em-dash'" [ngClass]="dataColumn.style">
                        {{ dataColumn.data }}
                      </span>
                    </ng-container>
                  </ng-template>
                </cell>
              </row>
            </ng-container>
            <ng-container *ngIf="displayGroupFooter && (dataSource.length > maximumVisibleRowsPerGroup && !showAll)">
              <listfoot class="list-foot-fixed-table" (click)="emitFocusedGroup()">
                <div class="nv-icon-wrapper"><nv-icon name="expand-small-blue"></nv-icon></div>
                <span>Show {{ dataSource.length - maximumVisibleRowsPerGroup }} more...</span>
              </listfoot>
            </ng-container>
          </section>
        </ng-container>
        <ng-template #emptyTable>
          <div class="empty-table-message">{{ emptyTableMessage }}</div>
        </ng-template>
      </ng-container>
    </list>
  </ng-container>
</ng-container>
