export enum CONSENT_STATUS {
  // eslint-disable-next-line no-unused-vars
  NOT_REQUESTED = 'Not requested',
  // eslint-disable-next-line no-unused-vars
  PENDING = 'Pending',
  // eslint-disable-next-line no-unused-vars
  YES = 'Yes',
  // eslint-disable-next-line no-unused-vars
  NO = 'No',
  // eslint-disable-next-line no-unused-vars
  REVOKED = 'Revoked',
  // eslint-disable-next-line no-unused-vars
  NEEDS_UPDATE = 'Needs update',
  // eslint-disable-next-line no-unused-vars
}

export type TConsentStatus = CONSENT_STATUS.NOT_REQUESTED
  | CONSENT_STATUS.PENDING
  | CONSENT_STATUS.REVOKED
  | CONSENT_STATUS.NEEDS_UPDATE
  | CONSENT_STATUS.YES
  | CONSENT_STATUS.NO;

export type TConsentEvent = 'completed' | 'declined'| 'emailSent' | 'childAddedToFamily' | 'consentRevoked' | 'consentNotRequested';

export type TConsentStatusData = {
  lastUpdated: string;
  lastEditor: string;
};
